import axios from "axios";
import { CloseOutlined, EditOutlined, LoadingOutlined, MediumCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, message, Modal, Upload ,Select} from 'antd';
import React, { useState } from 'react';
import { Input } from 'antd';
import Nav from "./navbar";
import Footer from "./footer";
import { useEffect } from "react";
import { Option } from "antd/lib/mentions";
import { useParams } from "react-router-dom";
import { Image } from 'antd';
import { Tooltip } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import DragAndDropUploader from "./Drag_And_Drop_Uploader";
import { API_URL, uploads } from '../config/config';
import Footer_menu from "./footer_menu";





const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


const Put_ad = () => {
  const [visible, setVisible] = useState(false);
  const local = localStorage.getItem('token');
  const params = useParams();
  const id = params.id;
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const { TextArea } = Input;

  const ad_id = params.id;
  // const userid = localStorage.getItem('token');
  const [location, setLocation] = useState();
  const [descriptions, setDescriptions] = useState();
  const [categories, setCategories] = useState();
  const [podcategories, setPodategories] = useState();
  const [images, setImages] = useState();
  const [price, setPrice] = useState();
  const [numbers, setNumbers] = useState();
  const [currency, setCurrency] = useState();
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();
  const [image4, setImage4] = useState();
  const [image5, setImage5] = useState();
  const [image6, setImage6] = useState();
  const [recommend, setRecommend] = useState();
  const [metro, setMetro] = useState();
  const [city, setCity] = useState();
  let [imagess,setImagess] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [files, setFiles] = useState([]);
  const [product, setProduct] = useState(null);
  const [subSubkvartiry, setSubkvartiry] = useState();
  const [subCategories, setSubcategories] = useState();
  const [subCategories2, setSubcategories2] = useState(null);
  const [subCategories3, setSubcategories3] = useState();
  const [subCategories4, setSubcategories4] = useState();
  const [subCategories5, setSubcategories5] = useState();
  const [subCategories6, setSubcategories6] = useState();
  const [subSrok, setSubSrok] = useState();
  const [subKomnat, setSubKomnat] = useState();
  const [subRoom, setSubroom] = useState();
  const [subFloor, setSubFloor] = useState();
  const [subPeriod, setSubPeriod] = useState();
  const [mark, setMark] = useState();
  const [model, setModel] = useState();
  const [generation, setGeneration] = useState();
  const [serie, setSerie] = useState();
  const [modification, setModification] = useState();
  const [year, setYear] = useState();
  const [year_value, setYear_value] = useState();
  const [buy_sell, setBuy_sell] = useState();
  const [komnat, setKomnat] = useState();
  const [floor, setFloor] = useState();
  const [period, setPeriod] = useState();
  const [srok, setSrok] = useState();
  const [room, setRoom] = useState();
  
  

  const handleChange10 = (value) => {
    setBuy_sell(value);
    // fetchModel(value);
    
  };
  const handleChange11 = (value) => {
    setKomnat(value);
    // fetchModel(value);
    
  };
  const handleChange12 = (value) => {
    setFloor(value);
    // fetchModel(value);
    
  };
  const handleChange13 = (value) => {
    setPeriod(value);
    fetchSrok(value);
    
  };
  const handleChange14 = (value) => {
    setSrok(value);
    // fetchModel(value);
    
  };
  const handleChange15 = (value) => {
    setRoom(value);
    // fetchModel(value);
    
  };
  const handleChange = (value) => {
    setFiles[0](value);
    
  };
  const handleChange2 = (value) => {
    setMetro(value);
    
  };
  const handleChange3 = (value) => {
    setCategories(value);
    fetchCategory(value);
    // setSubcategories(null);
    setSubcategories2(null);
    setSubcategories3(null);
    setSubcategories4(null);
    setSubcategories5(null);
    setSubcategories6(null);

    
  };
  const handleChange1 = (value) => {
   
    if(value >= 25 || value >= 30 ){
      fetchkvartiry(value);
      fetchKomnat(2500)
    }else{
      setPodategories(value);
      fetchCategorymark(value);
    }
    
  };
  const handleChange4 = (value) => {
    setMark(value);
    fetchModel(value);
    
  };
 
  const handleChange5 = (value) => {
    setModel(value);
    fetchGeneration(value);
    
  };
  const handleChange6 = (value) => {
    setGeneration(value);
    fetchSerie(value);
    fetchYear(value);
    
  };
  const handleChange7 = (value) => {
    setSerie(value);
    fetchModification(value);
    
  };
  const handleChange8 = (value) => {
    setModification(value);
    // fetchGeneration(value);
    
  };
  const handleChange9 = (value) => {
    setYear_value(value);
    // fetchGeneration(value);
    
  };
  const [usertoken, setUsertoken] = useState(null);
  const useridtoken = async () => {
    if(localStorage.getItem('token') != null){
        const post = {
            id: localStorage.getItem('token'),
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users5'
        });
        // 
        if (data.data.status == 200) {
            
            setUsertoken(data.data.users5[0].id);
            fetchProducts();
            fetchProduct();
        }if(localStorage.getItem('g') != null){
            const post = {
                id: localStorage.getItem('g'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            // 
            if (data.data.status == 200) {
                // 
                setUsertoken(data.data.users5[0].id);
                fetchProducts();
                fetchProduct();
            }
    }
        } 
  }
  
  
  
  const [products, setProducts] = useState(null);
  const fetchProducts = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getmetro'
    });
    if (data.data.status == 200) {
      setProducts(data.data.getmetro);
      
    } else {
      
    }
  }
  
  const fetchProduct = async () => {
    const post = {
      id: id,
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL+'/post'
    });
    
    if (data.data.status == 200) {
      if(data.data.post[0].mark != null){
        fetchCategorymark(data.data.post[0].categories);
        fetchYear(data.data.post[0].generation);
      }

      fetchSrok(data.data.post[0].period);
      fetchFloor(data.data.post[0].buy_sell);
      fetchkvartiry(data.data.post[0].podcategories);
      fetchCategory(data.data.post[0].categories);
      fetchModel(data.data.post[0].mark);
      fetchGeneration(data.data.post[0].models);
      fetchSerie(data.data.post[0].generation);
      fetchModification(data.data.post[0].serie);
      setFloor(data.data.post[0].floor);
      setBuy_sell(data.data.post[0].buy_sell);
      setPeriod(data.data.post[0].period);
      setCategories(data.data.post[0].categories);
      setModification(data.data.post[0].modification);
      setMark(data.data.post[0].mark);
      setModel(data.data.post[0].models);
      setGeneration(data.data.post[0].generation);
      setSerie(data.data.post[0].serie);
      setYear_value(data.data.post[0].year);
      setProduct(data.data.post);
      setLocation(data.data.post[0].location);
      setCity(data.data.post[0].city);
      setDescriptions(data.data.post[0].descriptions);
      setCategories(data.data.post[0].categories);
      setPodategories(data.data.post[0].podcategories);
      setNumbers(data.data.post[0].numbers);
      setCurrency(data.data.post[0].currency);
      setPrice(data.data.post[0].price);
      setImages(data.data.post[0].images);
      setImage1( data.data.post[0].image1);
      setImage2(data.data.post[0].image2);
      setImage3(data.data.post[0].image3);
      setImage4(data.data.post[0].image4);
      setImage5(data.data.post[0].image5);
      setImage6(data.data.post[0].image6);
      setFiles([...files,uploads + data.data.post[0].images,uploads +data.data.post[0].image1,uploads +data.data.post[0].image2,uploads +data.data.post[0].image3,uploads +data.data.post[0].image4,data.data.post[0].image5,uploads +data.data.post[0].image6,])
      setFileList(...fileList,
      [
        {
          uid: 's',
          id: data.data.post[0].id,
          name: data.data.post[0].images,
          status: 'done',
          url: data.data.post[0].images != null || data.data.post[0].images != undefined ? uploads + data.data.post[0].images : uploads + 'no_image3.png',
        },
      {
        uid: '2',
        id: data.data.post[0].id,
        name: data.data.post[0].image1,
        status: 'done',
        url: data.data.post[0].image1 != null ? uploads + data.data.post[0].image1 : uploads + 'no_image3.png',
      },
      {
        uid: '3',
        id: data.data.post[0].id,
        name: data.data.post[0].image2,
        status: 'done',
        url: data.data.post[0].image2 != null ? uploads + data.data.post[0].image2 : uploads + 'no_image3.png',
      }
      ,
      {
        uid: '4',
        id: data.data.post[0].id,
        name: data.data.post[0].image3,
        status: 'done',
        url: data.data.post[0].image3 != null ? uploads + data.data.post[0].image3 : uploads + 'no_image3.png',
      }
      ,
      {
        uid: '5',
        id: data.data.post[0].id,
        name: data.data.post[0].image4,
        status: 'done',
        url: data.data.post[0].image4 != null ? uploads + data.data.post[0].image4 : uploads + 'no_image3.png',
      }
      ,
      {
        uid: '6',
        id: data.data.post[0].id,
        name: data.data.post[0].image5,
        status: 'done',
        url: data.data.post[0].image5 != null ? uploads + data.data.post[0].image5 : uploads + 'no_image3.png',
      }
      ,
      {
        uid: '7',
        id: data.data.post[0].id,
        name: data.data.post[0].image6,
        status: 'done',
        url: data.data.post[0].image6 != null ? uploads + data.data.post[0].image6 : uploads + 'no_image3.png',
      }
      ]
      )
      
      setRecommend(data.data.post[0].recommend);
      setMetro(data.data.post[0].metro);
      
    } else {
      
      
    }
  }
  
 
  const addUser2 = async () => {
    const params = {
      podcategories: podcategories,
      descriptions: descriptions,
      categories: categories,
      price: price,
      numbers: numbers,
      recommend: recommend,
      currency: currency,
      userid: usertoken,
      city: city,
      metro: metro,
      ad_id: ad_id,
      mark: mark,
      model: model,
      generation: generation,
      serie: serie,
      modification: modification,
      year: year_value
     
    }
    const data = await axios({
      method: 'post',
      params: params,
      url: API_URL+'/update1'
    });
    
    if (data != null) {
      if (data.status == 200) {
        
      } else {
        alert('Error');
      }
    }
  }

const onRemove = async (e)=>{
  const post = {
    id: id,
    image_name: e.name,
    uid: e.uid
  };
  const data = await axios({
    method: 'delete',
    params: post,
    url: API_URL+'/update1',
  });
  
}
const [products2, setProducts2] = useState(null);
  const fetchProducts2 = async () => {
    const post = {
      id: '0',
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL + '/getcategory'
    });
    if (data.data.status == 200) {
      setProducts2(data.data.getcategory);
      
    } else{
      
      
    }
  }
  const fetchCategory = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    })
    
    if (data.data.status == 200) {
      setSubcategories(data.data.getcategory);
    } else {
    }
  }
  const fetchkvartiry = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    })
    
    if (data.data.status == 200) {
      setSubkvartiry(data.data.getcategory);
      fetchPeriod(31);
    } else {
    }
  }
  const fetchKomnat = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    })
    
    if (data.data.status == 200) {
      setSubKomnat(data.data.getcategory);
    } else {
    }
  }
  const fetchFloor = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    });
    
    if (data.data.status == 200) {
      setSubFloor(data.data.getcategory);
    } else {

    }
  }
  const fetchPeriod = async (id) => {

    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    });
    
    if (data.data.status == 200) {
      setSubPeriod(data.data.getcategory);

    } else {
      // setCustom3(false);
    }
  }
  
  const fetchSrok = async (id) => {

    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    });
    
    if (data.data.status == 200) {
      setSubSrok(data.data.getcategory);

    } else {
      // setCustom3(false);
    }
  }
  const fetchCategorymark = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory4',
      params: params
    })
    
    if (data.data.status == 200) {
      setSubcategories2(data.data.getcategory4);
    } else {
    }
  }
  const fetchModel = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory5',
      params: params
    })
    
    if (data.data.status == 200) {
      setSubcategories3(data.data.getcategory5);
    } else {
    }
  }
  // generation 
  const fetchGeneration = async (id) => {
    const params = {
      id: id
    }
      const data = await axios({
        method: 'get',
        url: API_URL + '/getcategory6',
        params: params
      });
      
      if (data.data.status == 200) {
        setSubcategories4(data.data.getcategory6);
      } else {
      }
  }
   // serie 
   const fetchSerie = async (id) => {
    const params = {
      id: id
    }
    
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory7',
      params: params
    })
    
    if (data.data.status == 200) {
      setSubcategories5(data.data.getcategory7);
    } else {
    }
  }
  const fetchModification = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory8',
      params: params
    });
    
    if (data.data.status == 200) {
      setSubcategories6(data.data.getcategory8);
    } else {
    }
  }
  const fetchYear = async (id) => {
    const params = {
      id: id
    }
      const data = await axios({
        method: 'get',
        url: API_URL + '/year3',
        params: params
      });
      
      if (data.data.status == 200) {
        setYear(data.data.year3);
      } else {
      }
  }
  

  useEffect(() => {
    useridtoken();
    fetchProducts2();
  }, []);
  return (
    <>
     {product != null || product != undefined || product?.length > 0 ?
                    <>
      <Nav />
      <div className="container bgbg">
        <div class="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 card">
            <div class="row">
              <div className='col-md-12'>
                <h5>Редактировать</h5>
              </div>
              <div className='row'>
              <div className="col-md-12">
                
                <DragAndDropUploader
                  className="mb-3 p-2"
                  fileList={fileList.length > 0 ? fileList : []}
                  defaultFileList={fileList.length > 0 ? fileList: []}
                  style={{width:'100%',height:'100px'}}
                  onChange={(file) => {
                    setFiles([...files, file]);
                  }}
                  onRemove={(f) => {
                    const index = files.indexOf(f);
                    if (index !== -1) {
                      const f = files.splice(index, 1);
                      setFiles(f);
                    }
                  }}
                />
                  </div>  
              <label style={{ fontSize: '18px', fontFamily: 'unset' }}>ID: {product[0].id}</label>
                  <div>
                  <Upload
                      className="upload-list-inline"
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture"
                      fileList={fileList.length > 0 ? fileList : []}
                      onRemove={onRemove}
                    >
                      <Button icon={<UploadOutlined />}></Button>
                    </Upload>
                  </div> 
           
               
                <div className="col-md-12">
                  <h5>Описание</h5>
                
                      <TextArea id="descriptions" defaultValue={product[0].descriptions} name="descriptions" onChange={(e) => { setDescriptions(e.target.value) }} rows={4} placeholder="Описание" maxLength={300} />
                
                </div>



              </div>

             
             
              {products != null || products != undefined || products ?.length > 0 ?
              <> <div className="col-md-4 mt-2"><Select onChange={handleChange2} defaultValue={product[0].metro}  style={{width:'100%'}}>
                <Option value={null}>Все</Option>
                {products.map((products)=>        
                  <Option value={products.id_sql}>{products.Station} 
                  {products.color != 1 ? <> <MediumCircleFilled style={{color : '#' + products.color, fontSize:'22px'}} /> </> :
                                     <>
                                     <img src={products.image} width='15px' height='15px' style={{borderRadius:'50%'}}></img>
                                    </>}
                  </Option>
                )
                }
              </Select></div>
              </>
              :<>Loading</>
          }
              {products2 != null || products2 != undefined || products2 ?.length > 0 ?
              <>
                    {/* Главная */} <div className='col-md-4 mt-2'>
                    <Select onChange={handleChange3}  defaultValue={product[0].categories} style={{ width: '100%' }}>
                      {products2.map((products) =>
                        <Option value={products.id}>{products.name}</Option>
                      )
                      }
                    </Select>
              </div>
              </>:<>Loading</>
          }
            {subCategories?.length > 0 ?
              <>
                {/* Подкатегории */}<div className="col-md-4 mt-2">
                <Select onChange={handleChange1} defaultValue={product[0].podcategories} style={{ width: '100%' }}>
                  {
                    subCategories.map((products) =>
                      <Option  value={products.id}>{products.name}</Option>
                    )
                  } </Select></div>
              </> : <></>
            }
            {subSubkvartiry?.length > 0 ?
                          <>
                            {/* купить продать снимать */}<div className="col-md-3 col-6 mt-3">
                            <Select onChange={handleChange10} defaultValue={product[0].buy_sell} style={{ width: '100%' }}>
                              {
                                subSubkvartiry.map((products) =>
                                  <Option value={products.id }>{products.name}</Option>
                                )
                              }
                            </Select></div>
                          </> : <></>
                        }
            {subKomnat?.length > 0 ?
        <>
          {/* komnat */}<div className="col-md-3 col-6 mt-3">
          <Select onChange={handleChange11} defaultValue={product[0].rooms} style={{ width: '100%' }}>
            {
              subKomnat.map((products) =>
                <Option value={products.id}>{products.name}</Option>

              )
            }

          </Select></div>
        </> : <></>
      }
      {subFloor?.length > 0 ?
        <>
          {/* etaj */}<div className="col-md-3 col-6 mt-3">
          <Select onChange={handleChange12} defaultValue={product[0].floor} style={{ width: '100%' }}>
            {
              subFloor.map((products) =>
                <Option value={products.id}>{products.name}</Option>
              )
            }
          </Select></div>
        </>:<>
        
        </>
      }
      {subPeriod?.length > 0 ?
        <>
          {/* etaj */}<div className="col-md-3 col-6 mt-3">
          <Select onChange={handleChange13} defaultValue={product[0].period} style={{ width: '100%' }}>
            {
              subPeriod.map((products) =>
                <Option value={products.id}>{products.name}</Option>
              )
            }
          </Select></div>
        </>:<>
        
        </>
      }
      {subSrok?.length > 0 ?
                          <>
                            {/* srok  */}<div className="col-md-3 col-6 mt-3">
                            <Select onChange={handleChange14} defaultValue={subSrok[0].name} style={{ width: '100%' }}>
                              {
                                subSrok.map((products) =>
                                  <Option value={products.id}>{products.name}</Option>
                                )
                              }
                            </Select></div>
                          </> : <></>
                        }
{subRoom?.length > 0 ?
        <>
          {/* komnat */}<div className="col-md-3 col-6 mt-3">
          <Select onChange={handleChange15} defaultValue={product[0].room} style={{ width: '100%' }}>
            {
              subRoom.map((products) =>
                <Option value={products.id}>{products.name}</Option>

              )
            }

          </Select></div>
        </> : <></>
      }
                        {subCategories2 != null ?
                          <>
                            {/* mark */}<div className="col-md-3 col-6 mt-3">
                            <Select onChange={handleChange4} defaultValue={product[0].mark} style={{ width: '100%' }}>
                            <Option value={null}>Все марки</Option>
                              {
                                subCategories2.map((products) =>
                                  <Option value={products.id_car_mark}>{products.name}</Option>
                                )
                              } </Select></div>
                          </> : <></>
                        }
                   
                      

                  {subCategories3?.length > 0 ?
                  <>
                  {/* model */}<div className="col-md-3 col-6 mt-3">
                  <Select onChange={handleChange5} defaultValue={product[0].models} style={{ width: '100%' }}>
                  <Option value={null}>Все модели</Option>
                  {
                    subCategories3.map((products) =>
                      <Option value={products.id_car_model}>{products.name}</Option>
                    )
                  }
                  </Select>
                  </div>
                  </> : <></>
                  }
                      {subCategories4?.length > 0 ?
                        <>
                          {/* generation */}<div className="col-md-3 col-6 mt-3">
                          <Select onChange={handleChange6} defaultValue={product[0].generation} style={{ width: '100%' }}>
                            {
                              subCategories4.map((products) =>
                                <Option value={products.id_car_generation}>{products.name} {products.year_begin}-{products.year_end}</Option>

                              )
                            }

                          </Select>
                        </div>
                        </> : <></>
                      }
                        {subCategories5?.length > 0 ?
                          <>
                            {/* serie */}<div className="col-md-3 col-6 mt-3">
                            <Select onChange={handleChange7} defaultValue={product[0].serie} style={{ width: '100%' }}>
                              {
                                subCategories5.map((products) =>
                                  <Option value={products.id_car_serie}>{products.name}</Option>
                                )
                              }

                            </Select></div>
                          </> : <></>
                        }
                      
                   {/* modification */}
                   
                        {subCategories6?.length > 0 ?
                          <><div className="col-md-3 col-6 mt-3">
                            <Select onChange={handleChange8} defaultValue={product[0].modification} style={{ width: '100%' }}>
                              {
                                subCategories6.map((products) =>
                                  <Option value={products.id_car_modification}>{products.name} {products.start_production_year}-{products.end_production_year}</Option>
                                )
                              }
                            </Select> </div>
                          </> : <>
                          </>
                        }
                     
            
                          
                            {/* year  */}
                            {year != null ?
                          <>
                          <div className="col-md-3 col-6 mt-3">
                            <Select onChange={handleChange9} defaultValue={product[0].year} style={{ width: '100%' }}>
                              {
                                year.map((products) =>
                                  <Option value={products.year}>{products.year}</Option>

                                )
                              }

                            </Select>
                            </div>
                            </> : <>
                          </>
                        }
                            
                   
              <div className='col-md-3 mt-3'>
               
                    <Input id="price" defaultValue={product[0].price} name="price" onChange={(e) => { setPrice(e.target.value) }} rows={100} placeholder="Цена" />
              
              </div>
              <div className='col-md-4 col-3 mt-2'>
               
                    <Select
                      onChange={setCurrency}
                      defaultValue={product[0].currency}
                      name="currency"
                      id="currency"
                      style={{
                        width: 120,
                      }}
                      options={[
                        {
                          value: 'RUB',
                          label: 'RUB',
                        },
                        {
                          value: 'KGZ',
                          label: 'KGZ',
                        },
                        {
                          value: 'USD',
                          label: 'USD',
                        },
                      ]}
                    />
                  
              </div>
              <div className='col-md-4 mt-2'>
             
                    <Input id="numbers" defaultValue={product[0].numbers} name="numbers" onChange={(e) => { setNumbers(e.target.value) }} placeholder="Номер телефона" />
                
              </div>

              <div className='col-md-12 mt-2'>
                <Button onClick={addUser2}>
                  Сохранить
                </Button>

              </div>

            </div>
          </div>

        </div>
      </div>
      <Footer />
      <div className='d-sm-block d-lg-none d-xl-none'>
                <Footer_menu />
            </div>
            </>
                    : <>Loading</>
                  }
    </>
  )
}
export default Put_ad;