import axios from 'axios';
import { useEffect, useState } from 'react';
import Footer from './footer';
import Nav from './navbar';
import React from 'react';
import { Button, Carousel, Image, Modal, Radio, Select, Spin, Table, Tabs, message, } from 'antd';
import { Checkbox, Form, Input } from 'antd';
import Post2 from './post2';
import { tab } from '@testing-library/user-event/dist/tab';
import Post_lk from './post_lk';
import { useParams } from 'react-router-dom';
import Post_lk_ak from './post_lk_ak';
import { AppstoreOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, EyeOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { API_URL, uploads } from '../config/config';
import { Option } from "antd/lib/mentions";
import moment from 'moment-timezone';
import DragAndDropUploader from './Drag_And_Drop_Uploader';

const Admin = ()=>{
  const [messageApi, contextHolder] = message.useMessage();
    const params = useParams();
    const user_id = localStorage.getItem('a');
    const [deaktiv, setDeaktiv] = useState(null);
    const [status_admin, setStatus_admin] = useState('none');
    const [otkl, setOtkl] = useState(null);
    const [aktiv, setAktiv] = useState(null);
    const [user, setUser] = useState(null);
    const [products, setProducts] = useState(null);
    const [products2, setProducts2] = useState(null);
    const [product, setProduct] = useState(null);
    const [sum, setSum] = useState(null);
    const [recommend, setRecommend] = useState();
    const [ad_id, setAd_id] = useState();
    const [currency, setCurrency] = useState(null);
    const [products_category,setProducts_category] = useState(null);
    const [subCategories, setSubcategories] = useState();
    const [category,setCategory] = useState();
    const [name_cat,setName_cat] = useState();
    const [product_metro, setProduct_metro] = useState(null);
    const [limit, setLimit] = useState(16);
    const [Product_user, setProduct_user] = useState(null);
    const [files, setFiles] = useState([]);
    const [prioritet, setPrioritet] = useState(null);
    const [image_name, setImage_name] = useState(null);
    const [selectedImage, setSelectedImage] = useState();
    const [selectedImage1, setSelectedImage1] = useState();
    const [selectedImage2, setSelectedImage2] = useState();
    const [selectedImage3, setSelectedImage3] = useState();
    const [selectedImage4, setSelectedImage4] = useState();
    const [selectedImage5, setSelectedImage5] = useState();
    const [selectedImage6, setSelectedImage6] = useState();
    const [selectedImage7, setSelectedImage7] = useState();
    const [selectedImage8, setSelectedImage8] = useState();
    const [selectedImage9, setSelectedImage9] = useState();
    const [selectedImage10, setSelectedImage10] = useState();
    const [selectedImage11, setSelectedImage11] = useState();
    const [selectedImage12, setSelectedImage12] = useState();
    const [selectedImage13, setSelectedImage13] = useState();
    const [selectedImage14, setSelectedImage14] = useState();
    const [admin, setAdmin] = useState(null);
    
    const [logged,setLogged]=useState(false);
    const [local,setLocal]=useState(localStorage.getItem('a'));
    const Logged = ()=>{
      if(local != null){
        setLogged(true);
      }
     }

     
    
    const imageChange = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage(e.target.files[0]);
      }
    };
    const imageChange1 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage1(e.target.files[0]);
      }
    };
    const imageChange2 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage2(e.target.files[0]);
      }
    };
    const imageChange3 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage3(e.target.files[0]);
      }
    };
    const imageChange4 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage4(e.target.files[0]);
      }
    };
    const imageChange5 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage5(e.target.files[0]);
      }
    };
    const imageChange6 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage6(e.target.files[0]);
      }
    };
    const imageChange7 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage7(e.target.files[0]);
      }
    };
    const imageChange8 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage8(e.target.files[0]);
      }
    };
    const imageChange9 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage9(e.target.files[0]);
      }
    };
    const imageChange10 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage10(e.target.files[0]);
      }
    };
    const imageChange11 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage11(e.target.files[0]);
      }
    };
    const imageChange12 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage12(e.target.files[0]);
      }
    };
    const imageChange13 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage13(e.target.files[0]);
      }
    };
    const imageChange14 = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage14(e.target.files[0]);
      }
    };
    const [ad_get_value, setAd_get_value] = useState(null);
    const [del_ad_id, setDel_ad_id] = useState(null);
    const [images, setImages] = useState(null);
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const [image4, setImage4] = useState(null);
    const [image5, setImage5] = useState(null);
    const [image6, setImage6] = useState(null);
    const ad_get = async (ad_id)=>{
      const params = {
        id: ad_id,
      };
      const data = await axios({
        method: 'get',
        params: params,
        url: API_URL+'/ad_get',
      });
      if (data.data.status == 200) {
          setAd_get_value(data.data.ad_get);
          setDel_ad_id(data.data.ad_get[0].id);
          setImages(data.data.ad_get[0].images);
          setImage1(data.data.ad_get[0].image1);
          setImage2(data.data.ad_get[0].image2);
          setImage3(data.data.ad_get[0].image3);
          setImage4(data.data.ad_get[0].image4);
          setImage5(data.data.ad_get[0].image5);
          setImage6(data.data.ad_get[0].image6);
          
          del_image(data.data.ad_get[0].id,data.data.ad_get[0].images,data.data.ad_get[0].image1,data.data.ad_get[0].image2,data.data.ad_get[0].image3,data.data.ad_get[0].image4,data.data.ad_get[0].image5,data.data.ad_get[0].image6);
      } else {
          warning();
          
      }
    }
    const del_image = async (del_ad_id,images,image1,image2,image3,image4,image5,image6)=>{
      if(del_ad_id != null){
          const params = {
              ad_id: del_ad_id,
              images: images,
              image1: image1,
              image2: image2,
              image3: image3,
              image4: image4,
              image5: image5,
              image6: image6,
            };
        const data = await axios({
            method:'post',
            params:params,
            url:API_URL+'/del_image',
        });
        
        if(data != null){
            if(data.data.status  == 200){
          del_ad2(del_ad_id);
          success2();
            }else{
              del_ad2(del_ad_id);
              success2();
            }
        }
      }else{
  
      }
        
    }
    const del_ad2 = async (del_ad_id)=>{
      if(del_ad_id != null){
          const params = {
              ad_id: del_ad_id,
            };
        const data = await axios({
            method:'post',
            params:params,
            url:API_URL+'/del_ad',
        });
        
        if(data != null){
            if(data.data.status  == 200){
             
            window.location.href='panel/admin'
            }
        }
      }else{
      //   error();
      }
        
    }

    const onChange = async (value) => {
     
    };
    
    const fetchProducts_category = async () => {
        const data = await axios({
            method:'get',
            url:API_URL+'/category'
        });
        if(data.data.status == 200){
            setProducts_category(data.data.post);
          
        }else{
           
        }
    }

    const fetchProduct = async () => {
        const params = {
          id: limit,
        };
        const data = await axios({
            params : params,
            method:'get',
            url:API_URL+'/client1_ad_vse'
        });
        if(data.data.status == 200){
            setProduct(data.data.post_ad);
            setLimit( limit + 16 );
        }else{
           
        }
    }
    const fetchProducts = async () => {
      const post = {
          limit: limit
      };
      const data = await axios({
          method: 'get',
          params: post,
          url: API_URL+'/moderation'
      });
      if (data.data.status == 200) {
          setLimit( limit + 16 );
          setProducts(data.data.moderation);
     
      } else {
     
      }
  }
    const fetchProduct_metro = async () => {
      const data = await axios({
          method:'get',
          url:API_URL+'/metro'
      });
      if(data.data.status == 200){
          setProduct_metro(data.data.getmetro);
        
      }else{
      
      }
  }
  const Metro = (metro) => {

    if (product_metro.length > 0 || product_metro != null) {
        const ad = product_metro.filter(i => i.id_sql == metro);
  
        if (ad.length > 0 || ad[0].Station != null) {
     
            return <p>{ad[0].Station}</p>;
        } else {
            return <p>eror</p>;
        }
    }
}

    

 const fetchProducts2 = async () => {
      const params = {
          limit: limit
      };
      const data = await axios({
          method: 'get',
          params: params,
          url: API_URL+'/moderation'
      });
      if (data.data.status == 200) {
          setLimit( limit + 16 );
          setProducts2(data.data.moderation);
          
      } else {
         
      }
  }

const fetchDeaktiv = async () => {
      const params = {
        limit: limit
    };
    const data = await axios({
        params: params,
        method: 'get',
        url: API_URL+'/deaktiv'
    });
    if (data.data.status == 200) {
        setLimit( limit + 16 );
        setDeaktiv(data.data.deaktiv);
        
    } else {
        
    }
}

const fetchOtkl = async () => {
      const params = {
        limit: limit
    };
    const data = await axios({
        params: params,
        method: 'get',
        url: API_URL+'/otkl'
    });
    if (data.data.status == 200) {
        setLimit( limit + 16 );
        setOtkl(data.data.otkl);
    } else {

    }
}

const fetchAktiv = async () => {
  const params = {
    limit: limit
};
    const data = await axios({
        params: params,
        method: 'get',
        url: API_URL+'/aktiv_admin'
    });
    if (data.data.status == 200) {
        setLimit( limit + 16 );
        setAktiv(data.data.aktiv);
       
    } else {
       
    }
}

const delfavorites = async (ad_id)=>{
    const params = {
        ad_id:ad_id,
        user_id:user_id,
        
    };


    const data = await axios({
        method:'delete',
        params:params,
        url:API_URL+'/delete_favorites'
    });

    if(data != null){
        if(data.status  == 200){
 
        window.location.href="userinfo";
        }else{
            alert('Error')
        }
    }
}

const del_ad = async (ad_id)=>{

    const params = {
        ad_id:ad_id,
        user_id:user_id,
    };


    const data = await axios({
        method:'delete',
        params:params,
        url:API_URL+'/delete_ad'
    });


    if(data != null){
        if(data.status  == 200){

        window.location.href='#';
        }else{
            alert('Error')
        }
    }
}
const put_status2 = async (ad_id) => {
    const params = {
        'ad_id' : ad_id,
        'status' : 2
      }
    const data = await axios({
      method: 'post',
      params: params,
      url: API_URL+'/update3',
    });

    if (data != null) {
      if (data.status == 200) {
        success2();
        fetchProducts2();
      } else {
        error2();
      }
    }
  }

  const put_status1 = async (ad_id) => {
    const params = {
        'ad_id' : ad_id,
        'status' : 1
      }
    const data = await axios({
      method: 'post',
      params: params,
      url: API_URL+'/update3',
    });

    if (data != null) {
      if (data.status == 200) {
        success2();
        fetchProducts2();
      } else {

      }
    }
  }
  
    const error = () => {
      messageApi.open({
        content:  <Spin /> ,
      });
    };
    const success = () => {
      messageApi.open({
        type: 'success',
        content: 'Успешно!',
      });
    };
    const success3 = () => {
      messageApi.open({
        type: 'success',
        content: 'Фото удалено!',
      });
    };
    const success4 = () => {
      messageApi.open({
        type: 'success',
        content: 'Обьявление удалено!',
      });
    };
    const success2 = () => {
      messageApi.open({
        content:  <Spin /> ,
      });
      setTimeout(success,1000)
    };
    const error2 = () => {
      messageApi.open({
        content:  <Spin /> ,
      });
      setTimeout(warning,1000)
    };
    const warning = () => {
      messageApi.open({
        type: 'warning',
        content: 'Не удалось исправьте ошибки!',
      });
  }


const href = (id)=>{
    window.location.href="/post/" + id;
}

function getItem(label, key, icon, children, type,) {
  return {
    key,
    icon,
    children,
    label,
    type,
    
  };
}
const items = [
  getItem('Обьявления', 'sub1', <MailOutlined />, [
    getItem(<a >balance</a>, '1'),
    getItem('Optionon 2', '2'),
    getItem('Option 3', '3'),
    getItem('Option 4', '4'),
  ]),
  getItem('Navigation Two', 'sub2', <AppstoreOutlined />, [
    getItem('Option 5', '5'),
    getItem('Option 6', '6'),
    getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')]),
  ]),
  getItem('Navigation Three', 'sub4', <SettingOutlined />, [
    getItem('Option 9', '9'),
    getItem('Option 10', '10'),
    getItem('Option 11', '11'),
    getItem('Option 12', '12'),
  ]),
];

// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

const [openKeys, setOpenKeys] = useState(['sub1']);
const onOpenChange = (keys) => {
  const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
  if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    setOpenKeys(keys);
  } else {
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  }
};
const fetchCategory = async () => {
  const data = await axios({
    method: 'get',
    url: API_URL+'/getcategory3',
  })

  if (data.data.status == 200) {
    setSubcategories(data.data.getcategory3);
  } else {
  }
}


  const fetchProducts4 = async () => {
    const params = {
      id: category,
      name_cat: name_cat
    };

    const data = await axios({
      method: 'post',
      params: params,
      url: API_URL+ '/postcategory'
    });
    if (data.data.status == 200) {

    } else {

    }
  }
  const limit_add = ()=>{
    fetchProduct();
  }
  const limit_add2 = ()=>{
    fetchProducts2();
  }
  const limit_add3 = ()=>{
    fetchOtkl();
  }
  const limit_add4 = ()=>{
    fetchAktiv();
  }
  const limit_add5 = ()=>{
    fetchDeaktiv();
  }

  const [banner,setBanner] = useState(null);
  const fetchBanner = async () => {
      const data = await axios({
          method:'get',
          url:API_URL+'/reklama'
      });
      if(data.data.status == 200){
          setBanner(data.data.reklama);

      }else{
   
      }
  }

  const fetchProduct_user = async () => {
    const data = await axios({
        method: 'get',
        url: API_URL+'/users_get'
    });

    if (data.data.status == 200) {
        setProduct_user(data.data.users_get);
    } else {
     
    }  
}
const add_img= async ()=>{
      if(selectedImage != null){
        const form = new FormData();
        form.append('image',selectedImage);
        form.append('prioritet','L1');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
    
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }if(selectedImage1 != null){
        const form = new FormData();
        form.append('image',selectedImage1);
        form.append('prioritet','L2');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
   
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }if(selectedImage2 != null){
        const form = new FormData();
        form.append('image',selectedImage2);
        form.append('prioritet','L3');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
      
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }if(selectedImage3 != null){
        const form = new FormData();
        form.append('image',selectedImage3);
        form.append('prioritet','L4');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
  
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }if(selectedImage4 != null){
        const form = new FormData();
        form.append('image',selectedImage4);
        form.append('prioritet','L5');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
     
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }if(selectedImage5 != null){
        const form = new FormData();
        form.append('image',selectedImage5);
        form.append('prioritet','C1');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
 
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }if(selectedImage6 != null){
        const form = new FormData();
        form.append('image',selectedImage6);
        form.append('prioritet','C2');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
   
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }if(selectedImage7 != null){
        const form = new FormData();
        form.append('image',selectedImage7);
        form.append('prioritet','C3');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
   
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }if(selectedImage8 != null){
        const form = new FormData();
        form.append('image',selectedImage8);
        form.append('prioritet','C4');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
     
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }
      if(selectedImage9 != null){
        const form = new FormData();
        form.append('image',selectedImage9);
        form.append('prioritet','R1');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
   
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }if(selectedImage10 != null){
        const form = new FormData();
        form.append('image',selectedImage10);
        form.append('prioritet','R2');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
    
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }
      if(selectedImage11 != null){
        const form = new FormData();
        form.append('image',selectedImage11);
        form.append('prioritet','R3');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
     
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }if(selectedImage12 != null){
        const form = new FormData();
        form.append('image',selectedImage12);
        form.append('prioritet','R4');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
    
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }if(selectedImage13 != null){
        const form = new FormData();
        form.append('image',selectedImage13);
        form.append('prioritet','R5');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
       
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }if(selectedImage14 != null){
        const form = new FormData();
        form.append('image',selectedImage14);
        form.append('prioritet','Background');
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_reklama',
            headers: { "Content-Type": "multipart/form-data" } 
        });
       
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }
      else{
        error();
      }
        
    }
    const spin_success = () => {
      messageApi.open({
        content:  <Spin /> ,
      });
      setTimeout(() => {
        success();
        window.location.href='/panel/admin'
      }, 500);
    };
    


const payment = async () => {
  const params = {
      'user_id' : user,
      'balance' : sum,
      'currency' : currency
    }
  const data = await axios({
    method: 'post',
    params: params,
    url: API_URL+'/payment',
  });

  if (data != null) {
    if (data.status == 200) {

      alert(data.status)
    } else {
      alert('Error')
    }
  }
}


 const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 50,
  },
  preview: {
    marginTop: 50,
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%", maxHeight: 320 },
  delete: {
    cursor: "pointer",
    padding: 15,
    background: "red",
    color: "white",
    border: "none",
  },
};

    useEffect(() => {
      Logged();
      fetchProduct_user();
        fetchProducts2();
        fetchProducts();
        fetchProduct();
        fetchDeaktiv();
        fetchOtkl();
        fetchAktiv();
        fetchProducts_category();
        fetchCategory();
        fetchProduct_metro();
        fetchBanner();
  
    }, [])
    const tab1 = <Button  type="dark ml-3" >   <a style={{color:'black'}} >Все</a> </Button>;
    const tab2 = <Button onClick={fetchProducts2 }  type="dark " >   <a style={{color:'black'}} >На модерации</a> </Button>;  
    const tab3 = <Button onClick={fetchProducts}  type="dark " ><a style={{color:'black'}}>Мои обьявлении</a> </Button>;
    const tab4 = <Button onClick={fetchOtkl}  type="dark " >   <a style={{color:'black'}}>Отключенные</a> </Button>;
    const tab5 = <Button onClick={fetchAktiv}  type="dark " >   <a style={{color:'black'}} >Активные</a> </Button>;
    const tab6 = <Button onClick={fetchDeaktiv}  type="dark " >   <a style={{color:'black'}} >Деактивированные</a> </Button>;
    const tab7 = <Button  type="dark " >   <a style={{color:'black'}} >Баланс</a> </Button>; 
    return(
        <>
         {contextHolder}
        {logged ? <>
         
         <Nav/>
        {product != null || product != undefined || product?.length > 0 ?
    <>
   
        <div  class="container card usercard"> 
        
                <div class="row mt-2">
  <div class="col-md-2 col-12">
  <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
      <button class="nav-link active" id="v-pills-home-tab" data-toggle="pill" data-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Обьявления</button>
      <button class="nav-link" id="v-pills-profile-tab" data-toggle="pill" data-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Внешний вид</button>
      <button class="nav-link" id="v-pills-messages-tab" data-toggle="pill" data-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Категории</button>
      <button class="nav-link" id="v-pills-settings-tab" data-toggle="pill" data-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Settings</button>
    </div>
    {/* <div class="list-group" id="list-tab" role="tablist">
      <a class="list-group-item list-group-item-action active" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home">Обьявление</a>
      <a class="list-group-item list-group-item-action" id="list-profile-list" data-bs-toggle="list" href="#list-profile" role="tab" aria-controls="list-profile">Внешний вид</a>
      <a class="list-group-item list-group-item-action" id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab" aria-controls="list-messages">Добавить категорию</a>
      <a class="list-group-item list-group-item-action" id="list-settings-list" data-bs-toggle="list" href="#list-settings" role="tab" aria-controls="list-settings">Settings</a>
    </div> */}
  </div>
  <div class="col-md-10 col-12">
  <div class="tab-content" id="v-pills-tabContent">
      <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"><Tabs defaultActiveKey="1">
                
                <Tabs.TabPane  className='row' tab={tab1} key="1">
                <h5>Все обьявлении</h5>
                
                <table class="table">
                
                <thead >
                    <tr>
                        <th>ID обв-я</th>
                        <th className='text-center d-block d-md-block'>Фото</th>
                        <th>Номер тел</th>
                        <th className='d-none d-md-block'>Категория</th>
                        <th style={{textAlign:'center'}}>Дата</th>
                        <th>Цена</th>
                        <th>Описание</th>
                        <th>Статус</th>
                        <th className='d-none d-md-block'>Управлять</th>
                        
                    </tr>
                </thead>
                <tbody >
                  {product != null || product != undefined || product?.length > 0 ?
                <>
                {product.map((item)=>
                    <tr className='hh'  >
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)} >{item.id}</td>
                        <div className='row'>
                        <div className='col-md-4 12'>
                          <Image className='' style={{width:'60px',height:'60px',marginLeft:'25%'}} src={item.images != null ? (uploads + item.images) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image1 != null ? (uploads + item.image1) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image2 != null ? (uploads + item.image2) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image3 != null ? (uploads + item.image3) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image4 != null ? (uploads + item.image4) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image5 != null ? (uploads + item.image5) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image6 != null ? (uploads + item.image6) : (uploads + 'no_image.jpg')} />	
                          </div>
                        </div>
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}>
                        {Product_user != null ? <>
                                    {Product_user.filter(user => user.id == item.userid).map(filteredUser => (
                                <a>
                                    {filteredUser.numbers != null ? <><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{filteredUser.numbers}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}
                         </td>
                        <td style={{textAlign:'center'}} className='d-none d-md-block' onClick={()=>href(item.id)}>
                        {subCategories != null ? <>
                                    {subCategories.filter(category => category.id == item.categories).map(filteredCategory => (
                                <a>
                                    {filteredCategory.name != null ? <><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{filteredCategory.name}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}</td>
                        <td style={{textAlign:'center'}}  onClick={()=>href(item.id)}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{moment.tz(item.date1, "Europe/Moscow").fromNow()}</button></td>
                        <td style={{textAlign:'center'}}  onClick={()=>href(item.id)}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{item.price}</button></td>
                        <td onClick={()=>href(item.id)} style={{width:'200px'}}><label className='' style={{overflow:'auto',width:'200px',height:'80px'}}>
                            {item.descriptions}
                            </label></td>
                            <td style={{textAlign:'center'}} onClick={()=>href(item.id)}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{item.status}</button></td>
                            <td>
                            <div  className='mt-1 d-none d-md-block'><a ><button onClick={()=>ad_get(item.id)} className='btn'><DeleteOutlined style={{fontSize:'26px'}}  /></button></a></div>
                            <div className='mt-1 d-none d-md-block'><a ><button onClick={()=>href(item.id)} className='btn'><EyeOutlined style={{fontSize:'26px'}} /></button></a></div>
                          <div className='mt-1 d-none d-md-block'><a href={"/put_ad/"+ item.id} ><button  className='btn'><EditOutlined style={{fontSize:'26px'}}  /></button></a></div>
                          <div className='mt-1 d-none d-md-block'><a ><button onClick={()=>put_status2(item.id)} className='btn'><CloseOutlined style={{fontSize:'26px'}} /></button></a></div>
                        </td>
                    </tr>
                    
                     )
                    }
                    </>
                    :<>Loading</>
                }
                {/* {product != null || product != undefined || product?.length > 0 ?
                <>
                {product.map((item)=>
                    <tr className='hh' >
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}>{item.id}</td>
                        <div className='row d-none d-md-block'>
                        <div className='col-md-4 d-none '>
                          <Image className='none-max-600' style={{width:'60px',height:'60px',marginLeft:'25%'}} src={item.images != null ? (uploads + item.images) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 '>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image1 != null ? (uploads + item.image1) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image2 != null ? (uploads + item.image2) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image3 != null ? (uploads + item.image3) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image4 != null ? (uploads + item.image4) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image5 != null ? (uploads + item.image5) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image6 != null ? (uploads + item.image6) : (uploads + 'no_image.jpg')} />	
                          </div>
                        </div>
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}>{item.userid}</td>
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}>{item.metro}</td>
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}>{item.date1}</td>
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}>{item.price}</td>
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}>{item.currency}</td>
                        <td onClick={()=>href(item.id)} style={{width:'200px'}}>
                            <label className='' style={{overflow:'auto',width:'200px',height:'80px'}}>
                            {item.descriptions}
                            </label>
                        </td>
                        <td>
                          <div className='mt-1'><a ><button onClick={()=>href(item.id)} className='btn'><EyeOutlined style={{fontSize:'26px'}} /></button></a></div>
                          <div className='mt-1'><a href={"/put_ad/"+ item.id} ><button  className='btn'><EditOutlined style={{fontSize:'26px'}}  /></button></a></div>
                          <div className='mt-1'><a ><button onClick={()=>put_status2(item.id)} className='btn'><CloseOutlined style={{fontSize:'26px'}} /></button></a></div>
                        </td>

                    </tr>
                    
                    
                     )
                     
                    }
                    
                    </>
                    :<>Loading</>
                } */}
                
                
                </tbody>
                
            </table>
            
            <div className='row'>
                          <div className='col-md-4'></div>
                              <div  className='col-md-12 '>
                              <Button onClick={limit_add} type="primary" block>
                                Показать Ещё
                              </Button>
                              </div>
                              <div className='col-md-4'></div>
                          </div>
                         
                </Tabs.TabPane>
                
                <Tabs.TabPane className='row' tab={tab2} key="2">
                <h3>На модерации</h3>
                  {/* <h1>{favorites.ad_id}</h1> */}
                  <table class="table">
                
                <thead>
                    <tr>
                        <th>ID обв-я</th>
                        <th className='text-center'>Фото</th>
                        <th>Номер тел</th>
                        <th className='d-none d-md-block'>Категория</th>
                        <th style={{textAlign:'center'}}>Дата</th>
                        <th>Цена</th>
                        <th>Валюта</th>
                        <th>Описание</th>
                        <th className='d-none d-md-block'>Управлять</th>
                    </tr>
                </thead>
                <tbody >
                {products2 != null || products2 != undefined || products2?.length > 0 ?
                <>
                {products2.map((item)=>
                    <tr className='hh'  >
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)} >{item.id}</td>
                        <div className='row'>
                        <div className='col-md-4 col-12'>
                          <Image className='' style={{width:'60px',height:'60px',marginLeft:'25%'}} src={item.images != null ? (uploads + item.images) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image1 != null ? (uploads + item.image1) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image2 != null ? (uploads + item.image2) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image3 != null ? (uploads + item.image3) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image4 != null ? (uploads + item.image4) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image5 != null ? (uploads + item.image5) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image6 != null ? (uploads + item.image6) : (uploads + 'no_image.jpg')} />	
                          </div>
                        </div>
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}>{Product_user != null ? <>
                                    {Product_user.filter(user => user.id == item.userid).map(filteredUser => (
                                <a>
                                    {filteredUser.numbers != null ? <><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{filteredUser.numbers}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}</td>
                        <td className='d-none d-md-block' style={{textAlign:'center'}} onClick={()=>href(item.id)}>{subCategories != null ? <>
                                    {subCategories.filter(category => category.id == item.categories).map(filteredCategory => (
                                <a>
                                    {filteredCategory.name != null ? <><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{filteredCategory.name}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}</td>
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{moment.tz(item.date1, "Europe/Moscow").fromNow()}</button></td>
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{item.price}</button></td>
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{item.currency}</button></td>
                        <td onClick={()=>href(item.id)} style={{width:'200px'}}><label className='' style={{overflow:'auto',width:'200px',height:'80px'}}>
                            {item.descriptions}
                            </label></td>
                            <td className='d-none d-md-block'>
                            <div  className='mt-1 d-none d-md-block'><a ><button onClick={()=>ad_get(item.id)} className='btn'><DeleteOutlined style={{fontSize:'26px'}}  /></button></a></div>
                          <div className='mt-1'><a href={"/put_ad/"+ item.id} ><button  className='btn'><EditOutlined style={{fontSize:'26px'}}  /></button></a></div>
                          <div className='mt-1'><a ><button onClick={()=>put_status1(item.id)} className='btn'><CheckOutlined style={{fontSize:'26px'}}  /></button></a></div>
                          <div className='mt-1'><a ><button onClick={()=>put_status2(item.id)} className='btn'><CloseOutlined style={{fontSize:'26px'}} /></button></a></div>
                        </td>
                    </tr>
                    
                     )
                    }
                    </>
                    :<>Loading</>
                }
                </tbody>
                
            </table>
            <div className='row'>
                          <div className='col-md-4'></div>
                              <div  className='col-md-12 '>
                              <Button onClick={limit_add2} type="primary" block>
                                Показать Ещё
                              </Button>
                              </div>
                              <div className='col-md-4'></div>
                          </div>
                </Tabs.TabPane>
            
            
                <Tabs.TabPane className='row' tab={tab4} key="4">
                <h3>Отключенные</h3>
                <table class="table">
                
                <thead >
                    <tr>
                        <th>ID обв-я</th>
                        <th className='text-center'>Фото</th>
                        <th>Номер тел</th>
                        <th className='d-none d-md-block'>Категория</th>
                        <th style={{textAlign:'center'}}>Дата</th>
                        <th>Цена</th>
                        <th>Валюта</th>
                        <th>Описание</th>
                        <th className='d-none d-md-block'>Управлять</th>
                    </tr>
                </thead>
                <tbody >
                {otkl != null || otkl != undefined || otkl ?.length > 0 ?
                <>
                {otkl.map((item)=>
                    <tr  className='hh'>
                        <td style={{textAlign:'center'}} >{item.id}</td>
                        <div className='row'>
                        <div className='col-md-4 col-12'>
                          <Image className='' style={{width:'60px',height:'60px',marginLeft:'25%'}} src={item.images != null ? (uploads + item.images) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image1 != null ? (uploads + item.image1) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image2 != null ? (uploads + item.image2) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image3 != null ? (uploads + item.image3) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image4 != null ? (uploads + item.image4) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image5 != null ? (uploads + item.image5) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image6 != null ? (uploads + item.image6) : (uploads + 'no_image.jpg')} />	
                          </div>
                        </div>	
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}>{Product_user != null ? <>
                                    {Product_user.filter(user => user.id == item.userid).map(filteredUser => (
                                <a>
                                    {filteredUser.numbers != null ? <><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{filteredUser.numbers}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}</td>
                        <td className='d-none d-md-block' style={{textAlign:'center'}}>{subCategories != null ? <>
                                    {subCategories.filter(category => category.id == item.categories).map(filteredCategory => (
                                <a>
                                    {filteredCategory.name != null ? <><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{filteredCategory.name}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}</td>
                        <td style={{textAlign:'center'}}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{moment.tz(item.date1, "Europe/Moscow").fromNow()}</button></td>
                        <td style={{textAlign:'center'}}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{item.price}</button></td>
                        <td style={{textAlign:'center'}}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{item.currency}</button></td>
                        <td style={{width:'200px'}}><label className='' style={{overflow:'auto',width:'200px',height:'80px'}}>
                        <button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{item.descriptions}</button>
                            </label></td>
                            <td className='d-none d-md-block'>
                            <div  className='mt-1 d-none d-md-block'><a ><button onClick={()=>ad_get(item.id)} className='btn'><DeleteOutlined style={{fontSize:'26px'}}  /></button></a></div>
                              <div className='mt-1'><a href=''><button onClick={()=>href(item.id)} className='btn'><EyeOutlined style={{fontSize:'26px'}} /></button></a></div>
                              <div className='mt-1'><a href=''><button onClick={()=>put_status1(item.id)} className='btn' ><CheckOutlined style={{fontSize:'26px'}} /></button></a></div>
                              <div className='mt-1'><a href={"/put_ad/"+ item.id} ><button  className='btn'><EditOutlined style={{fontSize:'26px'}}  /></button></a></div>
                            </td>
                    </tr>
                     )
                    }
                    </>
                    :<>Loading</>
                }
                    </tbody>
                
                </table>
                <div className='row'>
                          <div className='col-md-4'></div>
                              <div  className='col-md-12 '>
                              <Button onClick={limit_add3} type="primary" block>
                                Показать Ещё
                              </Button>
                              </div>
                              <div className='col-md-4'></div>
                          </div>
                </Tabs.TabPane>
            
                <Tabs.TabPane className='row' tab={tab5} key="5">
                <h3>Активные</h3>
                <table class="table">
                
                <thead >
                    <tr>
                        <th>ID обв-я</th>
                        <th className='text-center'>Фото</th>
                        <th>Номер тел</th>
                        <th className='d-none d-md-block'>Категория</th>
                        <th style={{textAlign:'center'}}>Дата</th>
                        <th>Цена</th>
                        <th>Валюта</th>
                        <th>Описание</th>
                        <th className='d-none d-md-block'>Управлять</th>
                    </tr>
                </thead>
                <tbody >
                {aktiv != null || aktiv != undefined || aktiv?.length > 0 ?
                <>
                {aktiv.map((item)=>
                    <tr  className='hh' >
                        <td style={{textAlign:'center'}}>{item.id}</td>
                        <div className='row'>
                        <div className='col-md-4 col-12'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px',marginLeft:'25%'}} src={item.images != null ? (uploads + item.images) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image1 != null ? (uploads + item.image1) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image2 != null ? (uploads + item.image2) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image3 != null ? (uploads + item.image3) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image4 != null ? (uploads + item.image4) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image5 != null ? (uploads + item.image5) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image6 != null ? (uploads + item.image6) : (uploads + 'no_image.jpg')} />	
                          </div>
                        </div>
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}>{Product_user != null ? <>
                                    {Product_user.filter(user => user.id == item.userid).map(filteredUser => (
                                <a>
                                    {filteredUser.numbers != null ? <><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{filteredUser.numbers}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}</td>
                        <td className='d-none d-md-block' style={{textAlign:'center'}}>{subCategories != null ? <>
                                    {subCategories.filter(category => category.id == item.categories).map(filteredCategory => (
                                <a>
                                    {filteredCategory.name != null ? <><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{filteredCategory.name}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}</td>
                        <td style={{textAlign:'center'}}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{moment.tz(item.date1, "Europe/Moscow").fromNow()}</button></td>
                        <td style={{textAlign:'center'}}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{item.price}</button></td>
                        <td style={{textAlign:'center'}}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{item.currency}</button></td>
                        <td style={{width:'200px'}}><label className='' style={{overflow:'auto',width:'200px',height:'80px'}}>
                        <button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{item.descriptions}</button>
                            </label></td>
                            <td className='d-none d-md-block'>
                            <div  className='mt-1 d-none d-md-block'><a ><button onClick={()=>ad_get(item.id)} className='btn'><DeleteOutlined style={{fontSize:'26px'}}  /></button></a></div>
                              <div className='mt-1'><a ><button onClick={()=>href(item.id)} className='btn'><EyeOutlined style={{fontSize:'26px'}} /></button></a></div>
                              <div className='mt-1'><a href={"/put_ad/"+ item.id} ><button  className='btn'><EditOutlined style={{fontSize:'26px'}}  /></button></a></div>
                              <div className='mt-1'><a ><button onClick={()=>put_status2(item.id)} className='btn'> <CloseOutlined style={{fontSize:'26px'}}  /></button></a></div>
                            </td>
                    </tr>
                     )
                    }
                    </>
                    :<>Loading</>
                }
                </tbody>
                
            </table>
            <div className='row'>
                          <div className='col-md-4'></div>
                              <div  className='col-md-12 '>
                              <Button onClick={limit_add4} type="primary" block>
                                Показать Ещё
                              </Button>
                              </div>
                              <div className='col-md-4'></div>
                          </div>
                </Tabs.TabPane>
            
                <Tabs.TabPane className='row' tab={tab6} key="6">
                <h3>Деактивированные</h3>
                <table class="table">
                
                <thead >
                    <tr>
                        <th>ID обв-я</th>
                        <th className='text-center'>Фото</th>
                        <th>Номер тел</th>
                        <th className='d-none d-md-block'>Категория</th>
                        <th style={{textAlign:'center'}}>Дата</th>
                        <th>Цена</th>
                        <th>Валюта</th>
                        <th>Описание</th>
                        <th className='d-none d-md-block'>Управлять</th>
                    </tr>
                </thead>
                <tbody >
                {deaktiv != null || deaktiv != undefined || deaktiv?.length > 0 ?
                <>
                {deaktiv.map((item)=>
                    <tr className='hh' >
                        <td style={{textAlign:'center'}}>{item.id}</td>
                        <div className='row'>
                        <div className='col-md-4 col-12'>
                          <Image className='' style={{width:'60px',height:'60px',marginLeft:'25%'}} src={item.images != null ? (uploads + item.images) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image1 != null ? (uploads + item.image1) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image2 != null ? (uploads + item.image2) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image3 != null ? (uploads + item.image3) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image4 != null ? (uploads + item.image4) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image5 != null ? (uploads + item.image5) : (uploads + 'no_image.jpg')} />	
                          </div>
                          <div className='col-md-3 d-none d-md-block'>
                          <Image className='none-max-600' style={{width:'60px',height:'60px'}} src={item.image6 != null ? (uploads + item.image6) : (uploads + 'no_image.jpg')} />	
                          </div>
                        </div>
                        <td style={{textAlign:'center'}} onClick={()=>href(item.id)}>{Product_user != null ? <>
                                    {Product_user.filter(user => user.id == item.userid).map(filteredUser => (
                                <a>
                                    {filteredUser.numbers != null ? <><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{filteredUser.numbers}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}</td>
                        <td className='d-none d-md-block' style={{textAlign:'center'}}>{subCategories != null ? <>
                                    {subCategories.filter(category => category.id == item.categories).map(filteredCategory => (
                                <a>
                                    {filteredCategory.name != null ? <><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{filteredCategory.name}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}</td>
                                <td style={{textAlign:'center'}}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{moment.tz(item.date1, "Europe/Moscow").fromNow()}</button></td>
                        <td style={{textAlign:'center'}}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{item.price}</button></td>
                        <td style={{textAlign:'center'}}><button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{item.currency}</button></td>
                        <td style={{width:'200px'}}><label className='' style={{overflow:'auto',width:'200px',height:'80px'}}>
                        <button  style={{opacity:'0.7',fontSize:'16px',padding:'1px'}} type="button" class="btn btn-dark">{item.descriptions}</button>
                            </label></td>
                            <td className='d-none d-md-block'>
                            <div  className='mt-1 d-none d-md-block'><a ><button onClick={()=>ad_get(item.id)} className='btn'><DeleteOutlined style={{fontSize:'26px'}}  /></button></a></div>
                              <div className='mt-1'><a href=''><button onClick={()=>href(item.id)} className='btn'><EyeOutlined style={{fontSize:'26px'}} /></button></a></div>
                              <div className='mt-1'><a href=''><button onClick={()=>put_status1(item.id)} className='btn' ><CheckOutlined style={{fontSize:'26px'}} /></button></a></div>
                              <div className='mt-1'><a href={"/put_ad/"+ item.id} ><button  className='btn'><EditOutlined style={{fontSize:'26px'}}  /></button></a></div>
                            
                            
                            
                            </td>
                    </tr>
                     )
                    }
                    </>
                    :<>Loading</>
                }
                </tbody>
                
            </table>
            <div className='row'>
                          <div className='col-md-4'></div>
                              <div  className='col-md-12 '>
                              <Button onClick={limit_add5} type="primary" block>
                                Показать Ещё
                              </Button>
                              </div>
                              <div className='col-md-4'></div>
                          </div>
                </Tabs.TabPane>
            
                <Tabs.TabPane className='row' tab={tab7} key="7">
                  <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2>Кошелек</h2>
                        </div>
                        <div className='col-md-6'>
                            <h5>Кошелек - ваш персональный баланс на Birimdik.ru Пополняйте ваш Кошелек любым удобным способом и платите за услуги, не выходя из дома!</h5>
                        </div>
                      {Product_user != null ? <>
                      <div className='col-md-4'>
                        <select  onChange={(e) => { setUser(e.target.value) }} className='form-controll' defaultValue={'Выбрать пользователя'}>
                        {Product_user.map((products) =>
                         <option value={products.id}>id_{products.id} --- {products.name} --- {products.numbers}</option>
                          )
                          }
                        </select>
                      </div>
                        
                      </> : <></>}
                      <div className='col-md-2'>
                        <Select defaultValue={'Валюта'} style={{width:'100%'}} onChange={setCurrency}>
                          <Option value={'RUB'}>RUB</Option>
                          <Option value={'KGZ'}>KGZ</Option>
                          <Option value={'USD'}>USD</Option>
                        </Select>
                        {/* <select  onChange={(e) => { setCurrency(e.target.value) }} >
                          <option value={'RUB'}>RUB</option>
                          <option value={'KGZ'}>KGZ</option>
                          <option value={'USD'}>USD</option>
                        </select> */}
                      </div>
                       
            
                        <div className='col-md-12'>
                            <label style={{fontSize:'18px'}}>Сумма пополнения:</label>
                        </div>
                        <div className='col-md-12'>
                          <input onChange={(e) => { setSum(e.target.value) }} type='number'></input> <label style={{fontSize:'12px'}}></label>
                        </div>
                       
                        <div className='col-md-12 mt-2'>
                            <button onClick={payment} className='btn btn-primary'>Пополнить</button>
                        </div>
                    </div>
                  </div>
                </Tabs.TabPane>
            
              </Tabs></div>
      <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"><div className='row'>
            <div className='col-md-2  col-4'>  
            {banner != null ? <>
              <div style={{marginTop:'20px',textAlign:'center'}}>  
              <h5>L1</h5>
              <div className='col-md-12 '>
              {/* {selectedImage && (
          <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
            <img
              style={{width:'90px',height:'80px'}}
              src={URL.createObjectURL(selectedImage)}
              // style={styles.image}
              alt="Thumb"
            />
            <button onClick={add_img} style={styles.delete}>
              Заменить
            </button>
          </div>
        )} */}
              {selectedImage ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'L1').map(filteredBanner => (
                                  <a>{filteredBanner.image != null ? <> 
                                  <img style={{marginTop:'0px'}} width='100%' height='280px' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                              ))}
                         <input  accept="image/*"
                                onChange={imageChange} type="file"/>
                                <button onClick={add_img}> Заменить L1</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage ? <>
                          {selectedImage && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage)}
              style={{width:'100%',height:'280px',}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
                        </div>
                 
              </div>
              
              <div style={{marginTop:'20px',textAlign:'center'}}>
                <h5>L2</h5>
                {selectedImage1 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'L2').map(filteredBanner => (
                                  <a>{filteredBanner.image != null ? <> 
                                  <img style={{marginTop:'0px'}} width='100%' height='280px' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                              ))}
                         <input  accept="image/*"
                                onChange={imageChange1} type="file"/>
                                <button onClick={add_img}> Заменить L2</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage1 ? <>
                          {selectedImage1 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage1)}
              style={{width:'100%',height:'280px',}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
              </div>

              <div style={{marginTop:'20px',textAlign:'center'}}>
                <h5>L3</h5>
                {selectedImage2 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'L3').map(filteredBanner => (
                                  <a>{filteredBanner.image != null ? <> 
                                  <img style={{marginTop:'0px'}} width='100%' height='280px' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                              ))}
                         <input  accept="image/*"
                                onChange={imageChange2} type="file"/>
                                <button onClick={add_img}> Заменить L3</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage2 ? <>
                          {selectedImage2 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage2)}
              style={{width:'100%',height:'280px',}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
               </div>

              <div style={{marginTop:'20px',textAlign:'center'}}>
                <h5>L4</h5>
                {selectedImage3 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'L4').map(filteredBanner => (
                                  <a>{filteredBanner.image != null ? <> 
                                  <img style={{marginTop:'0px'}} width='100%' height='280px' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                              ))}
                         <input  accept="image/*"
                                onChange={imageChange3} type="file"/>
                                <button onClick={add_img}> Заменить L4</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage3 ? <>
                          {selectedImage3 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage3)}
              style={{width:'100%',height:'280px',}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
              </div>

              <div style={{marginTop:'20px',textAlign:'center'}}>
                <h5>L5</h5>
                {selectedImage4 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'L5').map(filteredBanner => (
                                  <a>{filteredBanner.image != null ? <> 
                                  <img style={{marginTop:'0px'}} width='100%' height='280px' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                              ))}
                         <input  accept="image/*"
                                onChange={imageChange4} type="file"/>
                                <button onClick={add_img}> Заменить L5</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage4 ? <>
                          {selectedImage4 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage4)}
              style={{width:'100%',height:'280px',}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
              </div>
              
              </> : <></>
            }
            </div>

            <div className='col-md-8 col-4'>
            {banner != null ? <>
            <div style={{height:'200px'}} className='carousel mt-3'>
            <Carousel autoplay  >
      <div>
        <h3 className='carousel2'  >
        {banner.filter(banner => banner.prioritet == 'C1').map(filteredBanner => ( 
                      <a >{filteredBanner.image != null ? <><img  width='100%' className='carimg' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}
                  </h3>
      </div>
      <div>
      <h3 className='carousel2'  >
        {banner.filter(banner => banner.prioritet == 'C2').map(filteredBanner => ( 
                      <a >{filteredBanner.image != null ? <><img  width='100%' className='carimg' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}</h3>
      </div>
      <div>
      <h3 className='carousel2'  >
        {banner.filter(banner => banner.prioritet == 'C3').map(filteredBanner => ( 
                      <a >{filteredBanner.image != null ? <><img  width='100%' className='carimg' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}</h3>
      </div>
      <div>
      <h3 className='carousel2'  >
        {banner.filter(banner => banner.prioritet == 'C4').map(filteredBanner => ( 
                      <a >{filteredBanner.image != null ? <><img  width='100%' className='carimg' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}</h3>
      </div>
    </Carousel>
            </div>
              <div style={{marginTop:'50px'}} className='row'>
              
              <div  className='col-md-3 text-center'>
                <h5>c1</h5>
                {selectedImage5 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'C1').map(filteredBanner => ( 
                      <a >{filteredBanner.image != null ? <><img  width='100%' style={{height:'120px'}} className='carimg' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}
                         <input  accept="image/*"
                                onChange={imageChange5} type="file"/>
                                <button onClick={add_img}> Заменить C1</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage5 ? <>
                          {selectedImage5 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage5)}
              style={{width:'100%',height:'280px',}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
              </div>

              <div  className='col-md-3 text-center'>
              <h5>c2</h5>
              {selectedImage6 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'C2').map(filteredBanner => ( 
                      <a >{filteredBanner.image != null ? <><img  width='100%' style={{height:'120px'}} className='carimg' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}
                         <input  accept="image/*"
                                onChange={imageChange6} type="file"/>
                                <button onClick={add_img}> Заменить C2</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage6 ? <>
                          {selectedImage6 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage6)}
              style={{width:'100%',height:'280px',}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
              </div>

              <div  className='col-md-3 text-center'>
              <h5>c3</h5>
              {selectedImage7 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'C3').map(filteredBanner => ( 
                      <a >{filteredBanner.image != null ? <><img  width='100%' style={{height:'120px'}} className='carimg' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}
                         <input  accept="image/*"
                                onChange={imageChange7} type="file"/>
                                <button onClick={add_img}> Заменить C3</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage7 ? <>
                          {selectedImage7 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage7)}
              style={{width:'100%',height:'280px',}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
               </div>

              <div  className='col-md-3 text-center'>
              <h5>c4</h5>
              {selectedImage8 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'C4').map(filteredBanner => ( 
                      <a >{filteredBanner.image != null ? <><img  width='100%' style={{height:'120px'}} className='carimg' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}
                         <input  accept="image/*"
                                onChange={imageChange8} type="file"/>
                                <button onClick={add_img}> Заменить C4</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage8 ? <>
                          {selectedImage8 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage8)}
              style={{width:'100%',height:'280px',}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
              </div>

             
              <div style={{marginTop:'50px',textAlign:'center'}} className='col-md-12 d-none d-md-block'>
                <h5>Background </h5>
                {selectedImage14 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'background').map(filteredBanner => ( 
                      <a>{filteredBanner.image != null ? <><img width='100%' style={{height:'400px'}} className='carimg' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}
                         <input  accept="image/*"
                                onChange={imageChange14} type="file"/>
                                <button style={{fontSize:'24px'}} onClick={add_img}> Заменить Background </button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage14 ? <>
                          {selectedImage14 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage14)}
              style={{width:'100%',height:'280px'}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'24px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
              </div>
              </div>
              </> : <></>
            } 
           
            
                  {/* {banner != null || banner != undefined || banner ?.length > 0 ?
                <>
                        <div className="col-md-auto col-12 mt-3">
                            <Select onChange={setPrioritet} defaultValue={banner[0].prioritet} style={{width:'100%'}}>{banner.map((products)=>
                                <Option value={products.prioritet}>{products.prioritet},{products.image}</Option>
                                )
                            }
                              </Select>
                        </div>
                        <div className='col-md-12 mt-3'>
                          <Button onClick={add_img}>Изменить</Button>
                        </div>
                </>
                :<>Loading</>
            } */}
  
                   
            </div>

            <div className='col-md-2 col-4'>
            {banner != null ? <>
            <div style={{marginTop:'20px',textAlign:'center'}}>
                <h5>R1</h5>
                {selectedImage9 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'R1').map(filteredBanner => ( 
                      <a>{filteredBanner.image != null ? <><img style={{marginTop:'0px',}} width='100%' height='280px' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}
                         <input  accept="image/*"
                                onChange={imageChange9} type="file"/>
                                <button onClick={add_img}> Заменить R1</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage9 ? <>
                          {selectedImage9 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage9)}
              style={{width:'100%',height:'280px'}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
                
            </div>

            <div style={{marginTop:'20px',textAlign:'center'}}>
                <h5>R2</h5>
                {selectedImage10 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'R2').map(filteredBanner => ( 
                      <a>{filteredBanner.image != null ? <><img style={{marginTop:'0px',}} width='100%' height='280px' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}
                         <input  accept="image/*"
                                onChange={imageChange10} type="file"/>
                                <button onClick={add_img}> Заменить R2</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage10 ? <>
                          {selectedImage10 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage10)}
              style={{width:'100%',height:'280px'}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
           </div>

            <div style={{marginTop:'20px',textAlign:'center'}}>
                <h5>R3</h5>
                {selectedImage11 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'R3').map(filteredBanner => ( 
                      <a>{filteredBanner.image != null ? <><img style={{marginTop:'0px',}} width='100%' height='280px' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}
                         <input  accept="image/*"
                                onChange={imageChange11} type="file"/>
                                <button onClick={add_img}> Заменить R3</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage11 ? <>
                          {selectedImage11 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage11)}
              style={{width:'100%',height:'280px'}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
            </div>

            <div style={{marginTop:'20px',textAlign:'center'}}>
                <h5>R4</h5>
                {selectedImage12 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'R4').map(filteredBanner => ( 
                      <a>{filteredBanner.image != null ? <><img style={{marginTop:'0px',}} width='100%' height='280px' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}
                         <input  accept="image/*"
                                onChange={imageChange12} type="file"/>
                                <button onClick={add_img}> Заменить R4</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage12 ? <>
                          {selectedImage12 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage12)}
              style={{width:'100%',height:'280px'}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
            </div>

            <div style={{marginTop:'20px',textAlign:'center'}}>
                <h5>R5</h5>
                {selectedImage13 ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label">
                              {banner.filter(banner => banner.prioritet == 'R5').map(filteredBanner => ( 
                      <a>{filteredBanner.image != null ? <><img style={{marginTop:'0px',}} width='100%' height='280px' src={uploads+filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
                  ))}
                         <input  accept="image/*"
                                onChange={imageChange13} type="file"/>
                                <button onClick={add_img}> Заменить R5</button>
                              </label>
                          </div>
                </>
              }
                      {selectedImage13 ? <>
                          {selectedImage13 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage13)}
              style={{width:'100%',height:'280px'}}
              alt="Thumb"
            />
            <button style={{width:'100%',fontSize:'12px'}} className='btn btn-primary' onClick={add_img} >
              Заменить фото
            </button>
          </div>
        )}
                        </>:<> 
                      </>}
            </div>

            </> : <></>
            }
          
          </div>
          </div></div>
      <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab"><div className='row'>
              {products_category != null ?
              <>
              <div className='col-md-4 col-12  ml-4 '>
              <Select onChange={setCategory} defaultValue={'Выберите родительскую категорию'} style={{width:'100%'}}>
                {products_category.map((products_category)=>
                <Option style={{width:'100%'}} value={products_category.id}>{products_category.name}</Option>
                )
                }
                {subCategories != null ?
                <>
                {subCategories.map((sub_category)=>
                <Option  value={sub_category.id} >{sub_category.name}</Option>
                )
                }
                </>:
                <>
                1
                </>
                }
               <Option><a><b>Добавить Категорию</b></a> </Option>
               
               </Select>
              </div>
              <div className='col-md-1'>
              
  

              
              </div>
              </>
              :
              <>
              </>
              }
              
        </div>
        <div className='col-md-3 mt-2'>
              <input onChange={(e) => { setName_cat(e.target.value) }} placeholder='Названия категория' className='form-control'></input>
        </div>
        <div className='col-md-3 mt-3'>
              <button onClick={fetchProducts4} style={{margin:'0px'}} className='btn btn-primary'>Добавить</button>
              </div></div>
      <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">...</div>
    </div>


    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list"></div>

      <div class="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">
          
      </div>

      <div class="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">
        
      </div>
     
      <div class="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">
        <div className='row'>
          123
        </div>
      </div>
    </div>
  </div>
</div>



                    </div>
                <div  className='col-md-10'>
                
                </div>
                
                <div className='row'>
                  
                
            

             
                
                

           
        </div></>
    :<>Loading</>
}
<div className='col-md-12 mt-4'>
<Footer/>
</div>
        </> : <></>
        }
         
        
        
    </>
    
    );
    }
    
    
    export default Admin;