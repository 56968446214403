
import React from 'react';
const Footer =()=>{
    return(

       
<div style={{backgroundColor:'#1E90FF',marginTop:'20%'}} class="row ">
<div class="col-md-1">

</div>
<div class="col-md-5 col-7">
<h5  class="footertxt"><a color='white' style={{color:'white'}} href={'info/'+1}>Сервис и поддержка</a>
</h5>
<h5 class="footertxt">
<a color='white' style={{color:'white'}} href={'info/'+2}>О нас</a>
</h5>
</div>
<div class="col-md-4 col-5">
<h5 class="footertxt">
<a color='white' style={{color:'white'}} href={'info/'+3}>Правила размещения</a>
</h5>
<h5 class="footertxt">
<a color='white' style={{color:'white'}} href={'info/'+4}>Реклама</a>
</h5>
</div>
<div class="col-md-2 col-6">
<h5 class="footertxt">
<a color='white' style={{color:'white'}} href={'info/'+5}>Контакты</a>
</h5>
<h5 class="footertxt">
<a color='white' style={{color:'white'}} href={'info/'+6}></a>
    </h5>
</div>

</div>

        

    )
    }
    
    
    export default Footer;
    





