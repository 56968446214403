import React,{ Component } from 'react';
import { Button, Modal, Radio, Select, Tabs } from 'antd';
import  {useState} from 'react'
import { AudioOutlined } from '@ant-design/icons';
import { Input, Space  } from 'antd';
import { useEffect } from 'react';
import { API_URL } from '../config/config';
import axios from "axios";
import { Option } from 'antd/lib/mentions';
import { Link } from 'react-router-dom';

const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1890ff',
    }}
  />
);
const onSearch = (value) => console.log(value);

// const room = localStorage.getItem('room');
const Nav =()=>{
  // const user_id = localStorage.getItem('token');
  const [products2,setProducts2] = useState(null);
  const [status, setStatus] = useState(false);
  const [usertoken, setUsertoken] = useState(null);
  const useridtoken = async () => {
    if(localStorage.getItem('token') != null){
        const post = {
            id: localStorage.getItem('token'),
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users5'
        });
        if (data.data.status == 200) {
            setUsertoken(data.data.users5[0].id);
            sms1(data.data.users5[0].id);
        }if(localStorage.getItem('g') != null){
            const post = {
                id: localStorage.getItem('g'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            
            if (data.data.status == 200) {
                
                setUsertoken(data.data.users5[0].id);
                sms1(data.data.users5[0].id);
            }
    }
        } 
  }
  const sms1 = async (user_id) => {
    const params = {
        id: user_id
    };
    const data = await axios({
        method: 'get',
        params: params,
        url: API_URL + '/chat_s'
    });
    
    if (data != null) {
        if (data.data.status == 200) {
            setStatus(true);
        } else {
            setStatus(false);
        }
    }
}
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () =>{
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [logged,setLogged]=useState(false);
  const [local,setLocal]=useState(localStorage.getItem('token'));
  const [local2,setLocal2]=useState(localStorage.getItem('g'));
 const Logged = ()=>{
  if(local != null || local2 != null){
    setLogged(true);
  }
 }

//  const fetchProducts2 = async () => {
//   const data = await axios({
//     method: 'get',
//     url: API_URL + '/getmetro'
//   });
//   if (data.data.status == 200) {
//     setProducts2(data.data.getmetro);
//     
//   } else {
//     
//   }
// }

  const LogOut = ()=>{
    localStorage.removeItem('token');
    localStorage.removeItem('token2');
    localStorage.removeItem('room');
    localStorage.removeItem('ad_id');
    localStorage.removeItem('login');
    localStorage.removeItem('number');
    localStorage.removeItem('uid');
    localStorage.removeItem('g');
    localStorage.removeItem('chat');
    localStorage.removeItem('text');
    localStorage.removeItem('limit');
    localStorage.removeItem('a');
    setLogged(false);
    window.location.href="/";
}
useEffect(()=>{
  useridtoken();
  Logged();
},[])
return(
  
    <>
    <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Согласие обработка данных</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{overflow:'scroll',height:'350px',fontSize:'8px'}} class=" modal-dialog-scrollable">
  
"Оставляя на сайте, размещенном по веб-адресу: Birimdik.ru , Birimdik.kg (далее – Сайт), принадлежащем ИНН 770379123213, оператор персональных данных рег.№770379123213 (далее - Администрация Сайта), любые из Ваших данных (которые отнесены к категории персональных в соответствии с ФЗ «О персональных данных» от 27.07.2006 №152-ФЗ), путем заполнения полей любых форм на Сайте, Вы автоматически присоединяетесь к настоящему Соглашению, приобретаете статус пользователя Сайта (далее-Пользователь Сайта) и соглашаетесь со всеми без исключения его условиями.

Пользователь Сайта:

1. Подтверждает, что все указанные им в формах регистрации на Сайте данные принадлежат лично ему, соответствуют действительности, не искажены.

2. Подтверждает и признает, что им внимательно в полном объеме прочитано настоящее Соглашение и условия обработки его персональных данных. Текст Соглашения и условия обработки персональных данных ему понятны и он с ними согласен;

3. Дает согласие на обработку Администрацией Сайта предоставляемых в составе информации персональных данных в целях заключения между ним и Сайтом настоящего Соглашения, а также его последующего исполнения;

4. Выражает согласие с условиями обработки персональных данных без оговорок и ограничений.

5. В соответствии с ФЗ «О персональных данных» от 27.07.2006 №152-ФЗ свободно, своей волей и в своем интересе выражает согласие на сбор, анализ, обработку, хранение и использование своих персональных данных (ФИО, дата рождения, мобильный телефон, электронный адрес (E-mail), прочие данные).

Согласие Пользователя Сайта на обработку персональных данных является конкретным, информированным и сознательным.

Настоящее согласие Пользователя Сайта признается исполненным в простой письменной форме.

Пользователь Сайта предоставляет Администрации Сайта право осуществлять следующие действия (операции) с персональными данными:

1. сбор и накопление;

2. хранение в течение установленных нормативными документами сроков хранения отчетности, но не менее трех лет, с момента даты прекращения пользования услуг Пользователем Сайта;

3. уточнение (обновление, изменение);

4. использование;

5. уничтожение;

6. обезличивание;

7. передача по требованию суда, в том числе, третьим лицам, с соблюдением мер, обеспечивающих защиту персональных данных от несанкционированного доступа.

Указанное согласие действует бессрочно с момента предоставления данных и может быть отозвано Пользователем Сайта путем подачи заявления Администрации Сайта с указанием данных, определенных ст. 14 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных» (ред. от 22.02.2017г.).

Отзыв согласия на обработку персональных данных может быть осуществлен путем направления Пользователем соответствующего распоряжения в простой письменной форме на адрес электронной почты birimdik.site@gmail.com.

Администрация Сайта не несет ответственности за использование (как правомерное, так и неправомерное) третьими лицами Информации, размещенной Пользователем Сайта на Сайте, включая её воспроизведение и распространение, осуществленные всеми возможными способами.

Администрация Сайта в любое время имеет право вносить изменения в настоящее Соглашение.

При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Соглашения вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Соглашения.

К настоящему Соглашению и отношениям между Пользователем Сайта и Администрацией Сайта, возникающим в связи с применением Соглашения, подлежит применению действующее законодательство Российской Федерации."
</div>
      <div class="modal-footer">
        {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
        <a href='/registr'><button type="button" class="btn btn-primary" >Принмаю соглашение обработке данных</button></a>
      </div>
    </div>
  </div>
</div>
    <div  className='row '>
                  <div style={{minHeight:'100px',backgroundColor:'#1E90FF',width:'100%'}} class="uk-card uk-card-default  uk-position-z-index"  className='row'>
                  <div className='col-md-auto col-6'>
                    <Link  to="/"><b style={{fontSize:'28px'}} class="market ">Birimdik</b></Link>
                  </div>
                    <div className='col-md-2 col-6'>
                    <ul class="navbar-nav ml-auto text-uppercase f1 mt-3">
                    <li style={{color:'white'}}>
                      {logged ?<><a className='podat' style={{color:'white'}} href={'/upload1/'+ 0}> Подать обьявление </a></>:<><a style={{color:'white'}} href='/vhod'>Подать обьявление</a></>}
                    </li>
                    </ul>
                    </div>

                    <div  className='col-md-1 col-3 mt-3'>
                    <ul  class="navbar-nav ml-auto text-uppercase f1">
                    <li>
                      {logged ?<><a className='navmobtext' style={{color:'white'}} onClick={LogOut} href=''><Button onClick={LogOut}  ghost   type="solid " ><a className='navmobtext' >Выход</a> </Button></a></>:<><a href='/vhod' ><Button  ghost   type="solid " >   <a className='navmobtext' href='/vhod'>Вход</a> </Button></a></>}
                    </li>
                    </ul>
                    </div>
                    <div className='col-md-2 col-4 mt-3'>
                    <ul class="navbar-nav ml-auto text-uppercase f1">
                    <li>
                      {logged ?<><a className='navmobtext' style={{color:'white'}} href={'/userinfo/'+3}><Button   ghost   type="solid " >   <a style={{color:'white'}} className='navmobtext' href={'/userinfo/'+3}>Личный кабинет</a> </Button></a></>:<><Button   ghost   type="solid " ><a style={{color:'white'}}  data-toggle="modal" data-target="#staticBackdrop">Регистрация</a></Button></>}
                    </li>
                    </ul>
                    </div>
                    <div className='col-12 d-none d-md-block'>
                    {logged ?<><a href={'/mainsms/' + usertoken} ><i  class="fa-solid fa-envelope sms1"></i></a></>:<><a href='/vhod'><i  class="fa-solid fa-envelope sms1"></i></a></>}
              
                  {status ? <>
                    <i style={{color:'red',fontSize:'18px',marginLeft:'5px',position:'absolute',marginTop:'-5px'}} class="fas fa-sms"></i>
                  </>
                :
                <></>  
                }
                {logged ?<><a className='ml-4' href={'/korzina/'}><i class="fa-solid fa-heart heart1 "></i> </a></>:<><a className='ml-4' href={'/vhod'}><i class="fa-solid fa-heart heart1 "></i> </a></>}
              </div>
                    <div className='col-5 mt-2 d-block d-md-none'>
                    {logged ?<><a href={'/mainsms/' + usertoken} ><i  class="fa-solid fa-envelope sms1"></i></a></>:<><a href={'/vhod'} ><i  class="fa-solid fa-envelope sms1"></i></a></>}
              
                  {status ? <>
                    <i style={{color:'red',fontSize:'18px',marginLeft:'5px',position:'absolute',marginTop:'-5px'}} class="fas fa-sms"></i>
                  </>
                :
                <></>  
                }
                {logged ?<><a className='ml-4' href={'/korzina/'}><i class="fa-solid fa-heart heart1 "></i> </a></>:<><a className='ml-4' href={'/vhod'}><i class="fa-solid fa-heart heart1 "></i> </a></>}
                 
              </div>
                    {/* <div  className='col-md-auto col-2 mt-3'>
                    <ul style={{float:'left'}} class="navbar-nav ml-auto text-uppercase f1">
                    <li>
                      {logged ?<><a  className='navmobtext' href='/userinfo'><Button   ghost   type="solid " >   <a style={{color:'white'}} className='navmobtext' href='/userinfo'>Язык</a> </Button></a></>:<><a href='/userinfo'><Button   ghost   type="solid " >   <a style={{color:'white'}} href='/userinfo'>Язык</a> </Button></a></>}
                    </li>
                    </ul>
                    </div> */}

                    
                  </div>
                </div>



               

                
                 



             


    
    


      <div style={{backgroundColor:'#2d2d7f'}} class="fix d-none" >
        <div class="row birimdikdiv2">
          
            <div style={{height:'100%'}} className='col-md-3 col-6 birimdikdiv '>
            
                  <a href="/"><b class="market ">Birimdik</b></a>
            </div>
            
            {logged ? <>
            <div  className='col-6 d-sm-block d-md-none'>
              <a  style={{float:'right',marginTop:'15px',marginRight:'10px'}} onClick={LogOut} href=''><Button onClick={LogOut}  ghost   type="solid " >   <a style={{color:'white'}} >Выход</a> </Button></a>
            </div>
                  </>
                  :
                  <>
                    <div className='reg col-6'><Button className='reg' href='/vhod' ghost style={{float:'right',marginTop:'10px'}}>Войти / Регистрация</Button></div>
                  </>}
            <div className='col-md-3 d-none d-xl-none d search2'>
                  <Search
                  placeholder="Я ищу..."
                  onSearch={onSearch}
                  style={{
                    width: '100%',
                    borderRadius:'25px'
                  }}
                />
                  </div>

                <div style={{backgroundColor:'#2d2d7f',width:'100%'}} className='col-md-12'>
                <a href={'/mainsms/' + usertoken} ><i  class="fa-solid fa-envelope sms1"></i></a>
                </div>



                  <div className='row d-none'>
                  <div  style={{backgroundColor:'#2d2d7f',width:'100%'}}  className='col-md-9 col-sm-12 '>
              <div   className='row'>
                  <nav class="navbar navbar-expand-lg main-nav px-0">
                <div className='col-6 col-md-auto'>
                  <a href={'/mainsms/' + usertoken} ><i  class="fa-solid fa-envelope sms1"></i></a>
                  {status ? <>
                    <i style={{color:'red',fontSize:'18px',marginLeft:'5px',position:'absolute',marginTop:'-5px'}} class="fas fa-sms"></i>
                  </>
                :
                <></>  
                }
                  
                  <a className='ml-4' href='/korzina'><i class="fa-solid fa-heart heart1 "></i> </a>
                </div>
                {/* <div className='col-6 reg'><label style={{color:'white',float:'right',marginTop:'-25px'}}>Станция метро: <i class="fas fa-map-marked-alt"></i></label></div> */}
                
                {products2 != null || products2 != undefined || products2?.length > 0 ?
                <>
                  <div style={{marginTop:'-20px',float:'right'}} className="col-md-3 col-6 d-sm-block d-md-none">
                    {/* auto */}
                    <Select   defaultValue={'Выберите метро'} style={{ width: '100%',fontSize:'12px',float:'right'}}>
                      {products2.map((products) =>
                        <Option style={{ width: '100%',fontSize:'12px'}} value={products.id}>{products.name}</Option>
                      )
                      }
                    </Select>
                  </div>
                  </>
                : <>Loading</>
              }
              
              
      </nav>
               
              </div>
              
            </div>
                  </div>
            
           
          
          

           
         </div>
      </div>

      
      

    
    </>
    
)
}


export default Nav;


