import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Nav from './navbar';
import Post2 from './post2';
import Post_lk_ak from './post_lk_ak';
import { API_URL } from '../config/config';
import { Button, Image, Tabs } from 'antd';
import Footer_menu from './footer_menu';
import moment from 'moment';
const User = () => {
  const params = useParams();
//   const userid = params.id;
  const [neaktiv2, setNeaktiv2] = useState(null);
  const [productuser, setProductuser] = useState(null);
  const [products2,setProducts2] = useState(null);
  const [usertoken, setUsertoken] = useState(null);

  const useridtoken123 = async () => {
    if(localStorage.getItem('token') != null){
        const post = {
            id: localStorage.getItem('token'),
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users5'
        });
        
        if (data.data.status == 200) {
            
            user_status_get(data.data.users5[0].id);
            fetchProductuser(data.data.users5[0].id);
            setUsertoken(data.data.users5[0].id);
          
                  //   fetchProducts(data.data.users5[0].id);
        //   fetchNeaktiv(data.data.users5[0].id);
        }
        }if(localStorage.getItem('g') != null){
            const post = {
                id: localStorage.getItem('g'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            
            if (data.data.status == 200) {
                // 
                user_status_get(data.data.users5[0].id);
                fetchProductuser(data.data.users5[0].id);
                setUsertoken(data.data.users5[0].id);
        //   fetchProducts(data.data.users5[0].id);
        //   fetchNeaktiv(data.data.users5[0].id);
          
            }
    }if(localStorage.getItem('token2') != null){
        const post = {
            id: localStorage.getItem('token2'),
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users5'
        });
        
        if (data.data.status == 200) {
            // 
            fetchProductuser(data.data.users5[0].id);
             user_status_get(data.data.users5[0].id);
            setUsertoken(data.data.users5[0].id);
      
    //   fetchProducts(data.data.users5[0].id);
    //   fetchNeaktiv(data.data.users5[0].id);
     
        }
}
        
  }
  
  const fetchProductuser = async (userid) => {
    const post = {
        id: userid,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/users1'
    });
    
    if (data.data.status == 200) {
        setProductuser(data.data.users1);
    } else {
        

    }  
}
const [limit, setLimit] = useState(10);
const fetchProducts123 = async (userid) => {
//   const post = {
//       id: userid,
//       limit: limit
//   };
//   const data = await axios({
//       method: 'get',
//       params: post,
//       url: API_URL+'/my_ad'
//   });
//   if (data.data.status == 200) {
//       setProducts2(data.data.my_ad);
//       setLimit(limit+10);
//       
//   } else {
//       
//       
//   }
}

const fetchNeaktiv123 = async (userid) => {
//   const post = {
//       user_id: userid,
//   };
//   const data = await axios({
//       method: 'get',
//       params: post,
//       url: API_URL+'/neaktiv'
//   });
//   if (data.data.status == 200) {
//       setNeaktiv2(data.data.neaktiv);
//       
//   } else {
//       
//       
//   }
}
const [user_status_value, setUser_status_value] = useState(null);
const user_status_get = async (usertoken)=>{
    const params = {
        user1_id:usertoken
    };
    const data = await axios({
        method:'get',
        params:params,
        url:API_URL+'/user_status'
    });
    
    if(data != null){
        if(data.data.status  == 200){
            setUser_status_value(data.data.user_status);
        
        }else{

        }
    }
  }
  const users_status = (user2_id) => {
    
    if (user_status_value.length > 0 || user_status_value != null) {
        const ad = user_status_value.filter(i => i.user_id == user2_id);
        
        if (ad.length > 0 && ad[0].date != null) {
            
            return <a  ><p>{moment.tz(ad[0].date, "Europe/Moscow").fromNow()}</p></a>;
        } else {
            return  <a  ><p>{'Недавно'}</p></a>;
        }
    }
}
useEffect(() => {
  useridtoken123();
}, [])
const tab3 = <Button   type="dark " ><a style={{color:'black'}}>Обьявления</a> </Button>;
   
const tab8 = <Button  type="dark "  onClick={fetchProducts123(usertoken)}>   <a style={{color:'black'}} >Активные</a> </Button>;  
const tab9 = <Button  onClick={fetchNeaktiv123(usertoken)} type="dark " >   <a style={{color:'black'}} >Неактивные</a> </Button>;  
  return (
    <>
    
    {productuser != null || productuser != undefined || productuser ?.length > 0 ?
    <>
      <div className='container'>
      <Nav/>
    <div className='row mt-4'>
        
        <div className='col-md-1'>
<a href={'/user/' + productuser[0].uid}><img style={{width:'100px',height:'100px',borderRadius:'50%',marginLeft:'-10px'}} src={productuser[0].avatar != null ? 'https://birimdik.kg/birimdik/uploads/' + productuser[0].avatar : 'https://cdn-icons-png.flaticon.com/512/149/149071.png'}></img></a>
    
        </div>

        <div className='col-md-2'>
          <h4 style={{fontFamily:'serif'}}><b>{productuser[0].name}</b></h4>
          <p style={{marginTop:'-15px'}}><label color='gray'>Был в сети :</label><b style={{color:'green',marginTop:'-15px'}}>{users_status(productuser[0].id )}</b></p>
          {/* <p style={{color:'grey',marginTop:'-15px',fontSize:'18px'}}>Потвержден</p> */}
          <p style={{color:'grey',marginTop:'-15px',fontSize:'16px'}}>Дата регистрации : {moment.tz(productuser[0].date1, "Europe/Moscow").fromNow()}</p>
        </div>
<div className='row'>
<div  className='col-md-12'>
  <div className='row ml-2'>
    

  <Tabs defaultActiveKey="1">
                
    
                <Tabs.TabPane className='row' tab={tab3} key="3">
                    
                <Tabs defaultActiveKey="3">
                
                    <Tabs.TabPane className='row' tab={tab8} key="8">
                    {/* {products != null || products != undefined || products?.length > 0 ?
                <>
                <h5>Активные</h5>
                {products.map((item)=>
                <div class="col-6 col-md-3 mt-3">
                    <Post2 product={item}/>      
                </div>
                
                )
                }
                </>
                :<>Нет активных обьявлений...
                </>
            } */}
            <div className='col-md-12'>
                <Button onClick={fetchProducts123()} type="primary" block>
                Показать Ещё
                </Button>
                </div>
                    </Tabs.TabPane>
            
                    <Tabs.TabPane className='row' tab={tab9} key="9">
                   
                      {/* <div  className='row'>
                      {neaktiv != null || neaktiv != undefined || neaktiv?.length > 0 ?
                                    <>
                                    <h5>Неактивные</h5>
                                    {neaktiv.map((item)=>
                                    <div style={{pointerEvents:'none',opacity:'0.5'}} class="col-6 col-md-3 mt-3">
                                        <Post2 product={item}/>
                                    </div>
                                    
                                    )
                                    }
                                    </>
                                    :<>Нет деактивированных обьявлений...
                                    </>
                                }
                     
                    </div> */}
                    </Tabs.TabPane>
                    
                </Tabs>
                
                </Tabs.TabPane>
            
               
            
                
            
               
            
              </Tabs>


 
  </div>
</div>





</div>     
</div>
      
    </div>
  </>
                :<>
                </>
            }     

            <div className='d-sm-block d-lg-none d-xl-none'>
                <Footer_menu />
            </div>
    </>
    
  );
};
export default User;

