
const New_main =()=>{
  
  return(
      <>
 <body>

{/* <!--Header--> */}
<div class="top-nav col-12">
  <header class="header">
    <nav class="navbar navbar-expand-md navbar-dark bg-dark pt-1 pb-1">
      <div class="container-fluid container">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample03"
          aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarsExample03">
          <ul class="navbar-nav me-auto mb-2 mb-sm-0">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Главная</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Помощь</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Каталог</a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </li>
          </ul>

          <a href="#" class="me-3"><i class="fa-solid fa-cart-shopping"></i></a>
          <a href="#" class="me-3"><i class="fa-solid fa-heart"></i></a>
          <a class="me-4 text-white">Вход и Регистрация</a>
          <button class="btn btn-primary">Разместить объявление</button>
        </div>
      </div>
    </nav>
  </header>

  {/* <!--Category and Search--> */}

  <div class="search-box">
    <div class="container d-flex">


      <div class="logo-search">Birimdik</div>

      <div class="div all-category">
        <button class="btn btn-primary category-btn"><i class="fa-solid fa-bars"></i> Все категории</button>
      </div>

      <div class="search">
        <div class="input-group mb-1">
          <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Поиск"
            aria-describedby="basic-addon2"></input>
          <button class="btn btn-primary">Search</button>
        </div>
      </div>

      <div class="location">
        <a href=""><i class="fa-solid fa-location-dot"></i> Москва</a>
      </div>
    </div>

  </div>
  {/* <!-- Close top nav --> */}

  {/* <!--Block category on click--> */}

  <div class="all-category-click">
    <div class="container">
      <div class="list-group category-list">

        <div class="row">
          <div class="col-lg-3">
            <ul class="main-category">

              <li class="list-group-item list-group-item-action">
                <div class="main-category-img"></div>
                <div class="category-name">Транспорт</div>
                <i class="fa-solid fa-angle-right icon"></i>
                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">Транспорт</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>

                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">dfgd</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>

                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">dfgd</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>
              </li>


              <li class="list-group-item list-group-item-action">
                <div class="main-category-img"></div>
                <div class="category-name">Транспорт</div>
                <i class="fa-solid fa-angle-right icon"></i>
                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">Транспорт</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>

                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">dfgd</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>

                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">dfgd</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>
              </li>


              <li class="list-group-item list-group-item-action">
                <div class="main-category-img"></div>
                <div class="category-name">Транспорт</div>
                <i class="fa-solid fa-angle-right icon"></i>
                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">Транспорт</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>

                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">dfgd</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>

                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">dfgd</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>
              </li>

              <li class="list-group-item list-group-item-action">
                <div class="main-category-img"></div>
                <div class="category-name">Транспорт</div>
                <i class="fa-solid fa-angle-right icon"></i>
                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">Транспорт</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>

                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">dfgd</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>

                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">dfgd</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>
              </li>

              <li class="list-group-item list-group-item-action">
                <div class="main-category-img"></div>
                <div class="category-name">Транспорт</div>
                <i class="fa-solid fa-angle-right icon"></i>
                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">Транспорт</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>

                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">dfgd</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>

                <ul class="main-category-item">
                  <lh>
                    <h5><a href="#">dfgd</a></h5>
                  </lh>
                  <li><a href="#">Автомобили</a></li>
                  <li><a href="#">Мотоциклы</a></li>
                  <li><a href="#">Подкатегории</a></li>
                  <li><a href="#">Подкатегории</a></li>
                </ul>
              </li>
            </ul>
          </div>


        </div>



      </div>
    </div>
  </div>


</div>
<main>
    <div class="container">
      <div class="row">

        <div class="main-left col-lg-9">

          <div class="category-slider">

            <div class="category-slider-box big-size">
              <a href="">
                <article>Транспорт</article>
                <div class="category-img auto"></div>
              </a>
            </div>

            <div class="category-slider-box big-size">
              <a href="">
                <article>
                  <p>Недвижимость</p>
                </article>
                <div class="category-img building"></div>
              </a>
            </div>

            <div class="category-slider-box small-size">
              <a href="">
                <article>Услуги</article>
                <div class="category-img service"></div>
              </a>
            </div>

            <div class="category-slider-box big-size">
              <a href="">
                <article>Электроника</article>
                <div class="category-img electronics"></div>
              </a>
            </div>

            <div class="category-slider-box big-size">
              <a href="">
                <article>Дом и сад</article>
                <div class="category-img house"></div>
              </a>
            </div>

            <div class="category-slider-box big-size">
              <a href="">
                <article>Работа</article>
                <div class="category-img work"></div>
              </a>
            </div>

            <div class="category-slider-box small-size">
              <a href="">
                <article>Мода и стиль</article>
                <div class="category-img building"></div>
              </a>
            </div>

            <div class="category-slider-box small-size">
              <a href="">
                <article>Детский мир</article>
                <div class="category-img toys"></div>
              </a>
            </div>

            <div class="category-slider-box big-size">
              <a href="">
                <article>Отдых и спорт</article>
                <div class="category-img sport"></div>
              </a>
            </div>

            <div class="category-slider-box small-size">
              <a href="">
                <article>Животные</article>
                <div class="category-img animal"></div>
              </a>
            </div>

            <div class="category-slider-box big-size">
              <a href="">
                <article>Охота и рыбалка</article>
                <div class="category-img fishing"></div>
              </a>
            </div>

            <div class="category-slider-box big-size">
              <a href="">
                <article>Подарки</article>
                <div class="category-img gifts"></div>
              </a>
            </div>

            <div class="category-slider-box small-size">
              <a href="">
                <article>Отдам даром</article>
                <div class="category-img building"></div>
              </a>
            </div>

            <div class="category-slider-box big-size">
              <a href="">
                <article>Авто запчасти</article>
                <div class="category-img building"></div>
              </a>
            </div>

            <div class="category-slider-box big-size">
              <a href="">
                <article>Бизнес</article>
                <div class="category-img building"></div>
              </a>
            </div>

            <div class="category-slider-box small-size">
              <a href="">
                <article>Обувь</article>
                <div class="category-img building"></div>
              </a>
            </div>

            <div class="category-slider-box big-size">
              <a href="">
                <article>Личные вещи</article>
                <div class="category-img personal"></div>
              </a>
            </div>



            <div class="category-slider-box big-size">
              <a href="">
                <article>Одежда(новые)</article>
                <div class="category-img building"></div>
              </a>
            </div>



            <div class="category-slider-box big-size">
              <a href="">
                <article>Медицинские<br>товары</br></article>
                <div class="category-img medical"></div>
              </a>
            </div>

          </div>



          <div class="slider-full">
            <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="https://plus.unsplash.com/premium_photo-1674406481284-43eba097a291?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    class="d-block w-100" alt="..."> </img>
                </div>
                <div class="carousel-item">
                  <img
                    src="https://plus.unsplash.com/premium_photo-1674067078920-5ca21b04244c?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    class="d-block w-100" alt="..."> </img>
                </div>
                <div class="carousel-item">
                  <img
                    src="https://images.unsplash.com/photo-1592862037671-e8b981b49dcf?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    class="d-block w-100" alt="...">
                      </img>
                </div>
              </div>
            </div>
          </div>

          <div class="product-box">
            <h6>Рекомендации</h6>
            <div class="row">

              <div class="col-lg-3 mt-2 mb-2">
                <a class="product-link" href="/pages/product.html">
                  <div class="product">
                    <div class="product-img product1">
                      <div class="liked"><i class="fa-solid fa-heart"></i></div>
                    </div>
                    <div class="product-body">
                      <p class="product-name"><strong>Lorem ipsum dolor sit amet consectetur adipisicing</strong></p>
                      <p class="product-price"><strong>1000 р</strong></p>
                      <div class="subway">
                        <div class="subway-icon"></div>
                        <p><a href="#" class="subway-name">Метро</a></p>
                      </div>
                      <button class="btn btn-primary">Купить</button>
                      <button class="btn btn-secondary"><i class="fa-regular fa-message"></i></button>
                      <div class="product-date">Сегодня 10:00</div>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-3 mt-2 mb-2">
                <a class="product-link" href="#">
                  <div class="product">
                    <div class="product-img product1">
                      <div class="liked"><i class="fa-solid fa-heart text-danger"></i></div>
                    </div>
                    <div class="product-body">
                      <p class="product-name"><strong>Lorem ipsum dolor sit amet consectetur adipisicing</strong></p>
                      <p class="product-price"><strong>1000 р</strong></p>
                      <div class="subway">
                        <div class="subway-icon"></div>
                        <p><a href="#" class="subway-name">Метро</a></p>
                      </div>
                      <button class="btn btn-primary">Купить</button>
                      <button class="btn btn-secondary"><i class="fa-regular fa-message"></i></button>
                      <div class="product-date">Сегодня 10:00</div>
                    </div>
                  </div>
                </a>
              </div>



              <div class="col-lg-3 mt-2 mb-2">
                <a class="product-link" href="#">
                  <div class="product">
                    <div class="product-img product1">
                      <div class="liked"><i class="fa-solid fa-heart"></i></div>
                    </div>
                    <div class="product-body">
                      <p class="product-name"><strong>Lorem ipsum dolor sit amet consectetur adipisicing</strong></p>
                      <p class="product-price"><strong>1000 р</strong></p>
                      <div class="subway">
                        <div class="subway-icon"></div>
                        <p><a href="#" class="subway-name">Метро</a></p>
                      </div>
                      <button class="btn btn-primary">Купить</button>
                      <button class="btn btn-secondary"><i class="fa-regular fa-message"></i></button>
                      <div class="product-date">Сегодня 10:00</div>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-3 mt-2 mb-2">
                <a class="product-link" href="#">
                  <div class="product">
                    <div class="product-img product1">
                      <div class="liked"><i class="fa-solid fa-heart"></i></div>
                    </div>
                    <div class="product-body">
                      <p class="product-name"><strong>Lorem ipsum dolor sit amet consectetur adipisicing</strong></p>
                      <p class="product-price"><strong>1000 р</strong></p>
                      <div class="subway">
                        <div class="subway-icon"></div>
                        <p><a href="#" class="subway-name">Метро</a></p>
                      </div>
                      <button class="btn btn-primary">Купить</button>
                      <button class="btn btn-secondary"><i class="fa-regular fa-message"></i></button>
                      <div class="product-date">Сегодня 10:00</div>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-3 mt-2 mb-2">
                <a class="product-link" href="#">
                  <div class="product">
                    <div class="product-img product1">
                      <div class="liked"><i class="fa-solid fa-heart"></i></div>
                    </div>
                    <div class="product-body">
                      <p class="product-name"><strong>Lorem ipsum dolor sit amet consectetur adipisicing</strong></p>
                      <p class="product-price"><strong>1000 р</strong></p>
                      <div class="subway">
                        <div class="subway-icon"></div>
                        <p><a href="#" class="subway-name">Метро</a></p>
                      </div>
                      <button class="btn btn-primary">Купить</button>
                      <button class="btn btn-secondary"><i class="fa-regular fa-message"></i></button>
                      <div class="product-date">Сегодня 10:00</div>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-3 mt-2 mb-2">
                <a class="product-link" href="#">
                  <div class="product">
                    <div class="product-img product1">
                      <div class="liked"><i class="fa-solid fa-heart"></i></div>
                    </div>
                    <div class="product-body">
                      <p class="product-name"><strong>Lorem ipsum dolor sit amet consectetur adipisicing</strong></p>
                      <p class="product-price"><strong>1000 р</strong></p>
                      <div class="subway">
                        <div class="subway-icon"></div>
                        <p><a href="#" class="subway-name">Метро</a></p>
                      </div>
                      <button class="btn btn-primary">Купить</button>
                      <button class="btn btn-secondary"><i class="fa-regular fa-message"></i></button>
                      <div class="product-date">Сегодня 10:00</div>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-3 mt-2 mb-2">
                <a class="product-link" href="#">
                  <div class="product">
                    <div class="product-img product1">
                      <div class="liked"><i class="fa-solid fa-heart"></i></div>
                    </div>
                    <div class="product-body">
                      <p class="product-name"><strong>Lorem ipsum dolor sit amet consectetur adipisicing</strong></p>
                      <p class="product-price"><strong>1000 р</strong></p>
                      <div class="subway">
                        <div class="subway-icon"></div>
                        <p><a href="#" class="subway-name">Метро</a></p>
                      </div>
                      <button class="btn btn-primary">Купить</button>
                      <button class="btn btn-secondary"><i class="fa-regular fa-message"></i></button>
                      <div class="product-date">Сегодня 10:00</div>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-3 mt-2 mb-2">
                <a class="product-link" href="#">
                  <div class="product">
                    <div class="product-img product1">
                      <div class="liked"><i class="fa-solid fa-heart"></i></div>
                    </div>
                    <div class="product-body">
                      <p class="product-name"><strong>Lorem ipsum dolor sit amet consectetur adipisicing</strong></p>
                      <p class="product-price"><strong>1000 р</strong></p>
                      <div class="subway">
                        <div class="subway-icon"></div>
                        <p><a href="#" class="subway-name">Метро</a></p>
                      </div>
                      <button class="btn btn-primary">Купить</button>
                      <button class="btn btn-secondary"><i class="fa-regular fa-message"></i></button>
                      <div class="product-date">Сегодня 10:00</div>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-3 mt-2 mb-2">
                <a class="product-link" href="#">
                  <div class="product">
                    <div class="product-img product1">
                      <div class="liked"><i class="fa-solid fa-heart"></i></div>
                    </div>
                    <div class="product-body">
                      <p class="product-name"><strong>Lorem ipsum dolor sit amet consectetur adipisicing</strong></p>
                      <p class="product-price"><strong>1000 р</strong></p>
                      <div class="subway">
                        <div class="subway-icon"></div>
                        <p><a href="#" class="subway-name">Метро</a></p>
                      </div>
                      <button class="btn btn-primary">Купить</button>
                      <button class="btn btn-secondary"><i class="fa-regular fa-message"></i></button>
                      <div class="product-date">Сегодня 10:00</div>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-3 mt-2 mb-2">
                <a class="product-link" href="#">
                  <div class="product">
                    <div class="product-img product1">
                      <div class="liked"><i class="fa-solid fa-heart"></i></div>
                    </div>
                    <div class="product-body">
                      <p class="product-name"><strong>Lorem ipsum dolor sit amet consectetur adipisicing</strong></p>
                      <p class="product-price"><strong>1000 р</strong></p>
                      <div class="subway">
                        <div class="subway-icon"></div>
                        <p><a href="#" class="subway-name">Метро</a></p>
                      </div>
                      <button class="btn btn-primary">Купить</button>
                      <button class="btn btn-secondary"><i class="fa-regular fa-message"></i></button>
                      <div class="product-date">Сегодня 10:00</div>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-3 mt-2 mb-2">
                <a class="product-link" href="#">
                  <div class="product">
                    <div class="product-img product1">
                      <div class="liked"><i class="fa-solid fa-heart"></i></div>
                    </div>
                    <div class="product-body">
                      <p class="product-name"><strong>Lorem ipsum dolor sit amet consectetur adipisicing</strong></p>
                      <p class="product-price"><strong>1000 р</strong></p>
                      <div class="subway">
                        <div class="subway-icon"></div>
                        <p><a href="#" class="subway-name">Метро</a></p>
                      </div>
                      <button class="btn btn-primary">Купить</button>
                      <button class="btn btn-secondary"><i class="fa-regular fa-message"></i></button>
                      <div class="product-date">Сегодня 10:00</div>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-3 mt-2 mb-2">
                <a class="product-link" href="#">
                  <div class="product">
                    <div class="product-img product1">
                      <div class="liked"><i class="fa-solid fa-heart"></i></div>
                    </div>
                    <div class="product-body">
                      <p class="product-name"><strong>Lorem ipsum dolor sit amet consectetur adipisicing</strong></p>
                      <p class="product-price"><strong>1000 р</strong></p>
                      <div class="subway">
                        <div class="subway-icon"></div>
                        <p><a href="#" class="subway-name">Метро</a></p>
                      </div>
                      <button class="btn btn-primary">Купить</button>
                      <button class="btn btn-secondary"><i class="fa-regular fa-message"></i></button>
                      <div class="product-date">Сегодня 10:00</div>
                    </div>
                  </div>
                </a>
              </div>






            </div>
          </div>


        </div>


        <div class="main-right col-lg-3">

          <h6>Расспродажа и акции</h6>

          <div class="row">

            <a class="add-right col" href="#">
              <div class="black-box"></div>
              <div class="add-img"></div>
            </a>

          </div>

          <h6 class="mt-4">Расспродажа и акции</h6>


        </div>


      </div>
    </div>
  </main>




  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script>
</body>
  </>
  );
  }
  
  
  export default New_main;