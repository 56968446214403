import axios from "axios";
import * as React from 'react';
import { useState } from "react";
import Footer from "./footer";
import Nav from "./navbar";
import { Button, Modal, Radio, Space, Tabs , message, Select ,} from 'antd';
import { Checkbox, Form, } from 'antd';
import GoogleLogin from "@leecheuk/react-google-login";
import { gapi } from 'gapi-script';
import { API_URL, uploads } from '../config/config';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { Option } from "antd/lib/mentions";
import { styled } from '@mui/joy/styles';
import Stack from '@mui/joy/Stack';
import LinearProgress from '@mui/joy/LinearProgress';
import Typography from '@mui/joy/Typography';
import Key from '@mui/icons-material/Key';
// import { CheckCircleOutlineOutlined, CheckCircleOutlined } from "@mui/icons-material";
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import { CheckCircleOutlineOutlined } from "@mui/icons-material";

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const TextMaskAdapter = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+7 000 000 00 00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});
const TextMaskAdapter2 = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+996 000 00 00 00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});
const TextMaskAdapter3 = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#########################"
      definitions={{
        '#': /[a-z,A-Z,0-9,(?=.*\&^%$#@!_+{}":?></|)]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});
const StyledInput = styled('input')({
  border: 'none', // remove the native input border
  minWidth: 0, // remove the native input width
  outline: 0, // remove the native input outline
  padding: 0, // remove the native input padding
  paddingTop: '1em',
  flex: 1,
  color: 'inherit',
  backgroundColor: 'transparent',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  textOverflow: 'ellipsis',
  '&::placeholder': {
    opacity: 0,
    transition: '0.1s ease-out',
  },
  '&:focus::placeholder': {
    opacity: 1,
  },
  '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label': {
    top: '0.5rem',
    fontSize: '0.75rem',
  },
  '&:focus ~ label': {
    color: 'var(--Input-focusedHighlight)',
  },
  '&:-webkit-autofill': {
    alignSelf: 'stretch', // to fill the height of the root slot
  },
  '&:-webkit-autofill:not(* + &)': {
    marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
    paddingInlineStart: 'var(--Input-paddingInline)',
    borderTopLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
    borderBottomLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
  },
});
const StyledLabel = styled('label')(({ theme }) => ({
  position: 'absolute',
  lineHeight: 1,
  top: 'calc((var(--Input-minHeight) - 1em) / 2)',
  color: theme.vars.palette.text.tertiary,
  fontWeight: theme.vars.fontWeight.md,
  transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}));
const InnerInput = React.forwardRef(function InnerInput(props, ref) {
  const id = React.useId();
  return (
    <React.Fragment>
      <StyledInput {...props} ref={ref} id={id} />
      <StyledLabel htmlFor={id}>Label</StyledLabel>
    </React.Fragment>
  );
});

const clientId = '806199593777-9nsuk0sv1rroo6tkrmr5641u7e3td4fp.apps.googleusercontent.com';

const Vhod = () =>{
  const local = localStorage.getItem('token');
  const local2 = localStorage.getItem('g');
  const [logged, setLogged] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [numbers, setNumbers] = React.useState();
 
  TextMaskAdapter.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  const [country, setCountry] = useState('1');
  const onChange = (value) => {
    setCountry(value);
    setNumbers(null);
  };
  const Logged = () => {
    if (local != null || local2 != null) {
      setLogged(true);
    }
  }

  const [user_id, setUser_id] = useState(null);
  const [google_id, setGoogle_id] = useState(null);
  const [uid, setUid] = useState(null);
  const [name, setName] = useState(null);
  const [city, setCity] = useState(null);
  const [surname, setSurname] = useState(null);
  const responseGoogle = async (response) => {
      localStorage.setItem('g', response.googleId);
      localStorage.setItem('login', response.profileObj.email);
      setLogin(response.profileObj.email);
      setUid(response.profileObj.uid);
      setName(response.profileObj.givenName);
      setSurname(response.profileObj.familyName);
      setCity(response.profileObj.city);
      setGoogle_id(response.googleId);
    
    if(response.profileObj.email != null){
      const post = {
        id: response.profileObj.email,
      };
      const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/users_login'
      });
      
      if (data.data.status == 200) {
        setUser_id(data.data.users_login[0].id);
        localStorage.setItem('token2', data.data.users_login[0].id);
        localStorage.setItem('token', data.data.users_login[0].uid);
        localStorage.setItem('login', data.data.users_login[0].login);
        localStorage.setItem('g', data.data.users_login[0].google_id);
        localStorage.setItem('text','');
        Logged();
        window.location.href = '/';
      } else {
        const params = {
          'login' : response.profileObj.email,
          'password' : response.googleId,
          'numbers' :numbers,
          'name' : response.profileObj.givenName,
          'surname' : response.profileObj.familyName,
          'city' : response.profileObj.city,
          'uid' : response.profileObj.uid,
          'google_id' :response.googleId,
        }
        
        const data = await axios({
          method: 'post',
          params: params,
          url: API_URL+'/registr',
        });
        
        if (data != null) {
          if (data.data.status == 200) {
            success();
            Logged();
            window.location.href="/registr2";
          } else {
            alert('Ошибка при авторизации, Попробуйте другой способ !')
          }
        }
      }
    }
    const fetchProduct3 = async (login) => {
      const post = {
        id: login,
      };
      const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/users_login'
      });
      
      if (data.data.status == 200) {
        setUser_id(data.data.users_login[0].id);
        localStorage.setItem('token2', data.data.users_login[0].id);
        localStorage.setItem('token', data.data.users_login[0].uid);
        localStorage.setItem('login', data.data.users_login[0].login);
        localStorage.setItem('g', data.data.users_login[0].google_id);
        localStorage.setItem('text','');
        Logged();
        window.location.href = 'registr2';
      } else {
        alert('error')
      }
  
    }
    

      


      
    
    
  }
  const onFailure = (response) => {
    
  }

  
  

  const onFinish = (values) => {
    
  };
  const onFinishFailed = (errorInfo) => {
    
  };
    const [login,setLogin]= useState(null);
    const [password, setPassword] = useState(null);    
    const Login = async ()=>{
        const params = {
            'login':numbers,
            'password':password
        }
        const user = await axios({
            method: 'get',
            url: API_URL+'/users',
            params:params
        });
        
        if(user.data.status == 200){
            success();
            localStorage.setItem('token',user.data.user[0].uid);
            localStorage.setItem('token2',user.data.user[0].id);
            localStorage.setItem('g',user.data.user[0].google_id);
            
            localStorage.setItem('text','');
            window.location.href="/";
        }else{
            error2();
        }
    }
    const success = () => {
      messageApi.open({
        type: 'success',
        content: 'Успешно!',
      });
    };
    const error2 = () => {
      messageApi.open({
        type: 'error',
        content: 'Неверный пароль или логин!',
      });
    };
    const warning = () => {
      messageApi.open({
        type: 'warning',
        content: 'This is a warning message',
      });
    };
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const something=(event)=> {
      if (event.keyCode === 13) {
          
      }
  }
  const handleClick = (e) => {
    if(e.key === "Enter"){
      Login();
    }
  }
 
    // 
    return(
        <>
        {contextHolder}
        <Nav/>
        <div class="row bg-white mt-5">
			<div class="col-md-12" >
				<div class="row" >

        <div className="col-md-4"></div>
					<div class="col-md-4 card">
						<div class="row">
							<div class="col-md-12 text-center">
							<b class="a3">Birimdik</b>
              <p><b>Вход</b></p>
							</div>
              <div class="row">
									<div class="col-md-3  col-4">
                  <Select
                      onChange={onChange}
                      defaultValue={'1'}
                      style={{
                        marginLeft: '17px',
                        width:'100%',
                        height:'40px'
                      }}
                    >
                      <Option value={'2'}><img width='40px' height='40px'  src={uploads+'flag_kg.png'}></img>+996</Option>
                      <Option value={'1'}><img width='40px' height='40px'  src={uploads+'flag_rus.jpg'}></img>+7</Option>
                      <Option value={'3'}><img width='40px' height='40px'  src={uploads+'gmail.png'}></img>@gmail</Option>
                    </Select>
                  </div>
									<div class="col-md-8 col-8 ">
                    {country == '1' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="+7 *** *** ** **"
                      slotProps={{ input: { component: TextMaskAdapter } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                    {country == '2' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="+996 *** ** ** **"
                      slotProps={{ input: { component: TextMaskAdapter2 } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                    {country == '3' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="********** @gmail.com"
                      slotProps={{ input: { component: TextMaskAdapter3 } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                  
										{/* <Input   onChange={(e) => { setNumbers(e.target.value) }} type="text" id="numbers"  placeholder="Номер телефона" class="form-control inp" name=""/> */}
								</div>
                </div>
							<div class="col-md-12"  id="aa">
								<div class="row">
									<div class="col-md-1 col-1"></div>
									<div class="col-md-10 col-10">
                  {/* <Input
                    endDecorator={<CheckCircleOutlined />}
                    slots={{ input: InnerInput }}
                    slotProps={{ input: { placeholder: 'A placeholder', type: 'password' } }}
                    sx={{
                      '--Input-minHeight': '56px',
                      '--Input-radius': '6px',
                    }}
                  /> */}
										{/* <input onChange={(e) =>{setLogin(e.target.value)}} type="text" id="login" placeholder="Телефон или эл.адресс" class="form-control inp" name=""/>
                    <label>Пример: (+7*** ** ** **),(+996*** ** ** **),(@gmail.com)</label> */}
                </div>
									<div class="col-md-1"></div>
								
								<div class="col-md-3 col-1"></div>
								<div class="col-md-8 col-10 mt-2">
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl onKeyDown={handleClick} onChange={(e) => { setPassword(e.target.value) }} sx={{ m: 0, width: '100%'}} variant="outlined">
          {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
          <OutlinedInput

        
            id="outlineod-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment onKeyDown={handleClick}  onChange={(e) => { setPassword(e.target.value) }} position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  type="submit"
                  onkeypress="handleKeyPress(event)"
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
           placeholder="Пароль"
          />
        </FormControl>
        </Box>
       
                {/* <Input
                  endDecorator={<CheckCircleOutlined/>}
                  slots={{ input: InnerInput }}
                  slotProps={{ input: { placeholder: 'A placeholder', type: 'password' } }}
                  sx={{
                    '--Input-minHeight': '56px',
                    '--Input-radius': '6px',
                  }}
                /> */}
                {/* <Space style={{width:'100%'}}  direction="vertical">
                    <Input.Password onChange={(e) => { setPassword(e.target.value) }} placeholder="Пароль" />
                  </Space> */}
                  {/* <input type="password" class="form-control" id="exampleInputPassword1"/>
									<input onChange={(e) =>{setPassword(e.target.value)}} type="password" id="password" placeholder="Пароль" class="form-control" name=""/> */}
								</div>
								<div class="col-md-2 col-3"></div>
								<div class="col-md-8 col-6 mt-2">
								<button class="form-control btn-primary" onClick={Login}>Войти</button>
								</div>
								<div className="row d-none d-md-block">
                <div class="col-md-12">
									<hr align="" width="auto" class="mt-2" size="2" color="grey" />
								</div>
								<div class="col-md-12 text-center mt-1">
									<b class="mt-1">Или</b>
								</div>
								<div class="col-md-12">
									<hr align="" class="mt-2" width="auto" size="2" color="grey" />
								</div>
                </div>
                <div className="row d-block d-md-none">
                <div class="col-md-5">
									<hr align="" width="auto" class="" size="2" color="grey" />
								</div>
								<div class="col-md-2 text-center ">
									<b class="">Или</b>
								</div>
		
                </div>

								<div class="col-md-2"></div>
								<div class="col-md-8 text-center">
                  <GoogleLogin
                      
                      clientId={clientId}
                      buttonText="Войти через Google"
                      onSuccess={responseGoogle}
                      // onClick={responseGoogle}
                      onFailure={onFailure}
                      cookiePolicy={'single_host_origin'}
                      isSignedIn={false}
                    />
								</div>
								<div class="col-md-2"></div>

								<div class="col-md-2"></div>
								<div class="col-md-8 mt-3 text-center">
                  <a href="password_new"><label>Забыли пароль?</label></a>
								</div>
								<div class="col-md-2"></div>
								
								<div class="col-md-12 ins2">
						<label class="text-center mt-2">У вас ещё нет аккаунта?</label>
						<a href="registr"><b>Зарегистрироваться</b></a>
					</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4"></div>
					
			</div>
			</div>

			
		</div>
       
     

    <div style={{marginTop:'150px'}} className="col-md-12"></div>
    <Footer/>	
        </>
    )
}
export default Vhod;