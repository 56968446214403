import axios from "axios";
import { LoadingOutlined, MediumCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, message, Spin, Upload } from 'antd';
import React, { useState } from 'react';
import { Select, Checkbox, TreeSelect, Space } from 'antd';
import Input from '@mui/joy/Input';
// import { Input } from 'antd';
import Nav from "./navbar";
import Footer from "./footer";
import { useEffect } from "react";
import { Option } from "antd/lib/mentions";
import { useParams } from "react-router-dom";
import { Cascader } from 'antd';
import DragAndDropUploader from "./Drag_And_Drop_Uploader";
import { API_URL, uploads } from '../config/config';
import Textarea from '@mui/joy/Textarea';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import FormControl from '@mui/joy/FormControl';
import { styled } from '@mui/joy/styles';
import Footer_menu from "./footer_menu";
import InputEmojiWithRef from "react-input-emoji";

const onChange = (value) => {
  
};
const { TextArea } = Input;
const TextMaskAdapter = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+7 000 000 00 00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});
const TextMaskAdapter2 = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+996 000 00 00 00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});

const StyledInput = styled('input')({
  border: 'none', // remove the native input border
  minWidth: 0, // remove the native input width
  outline: 0, // remove the native input outline
  padding: 0, // remove the native input padding
  paddingTop: '1em',
  flex: 1,
  color: 'inherit',
  backgroundColor: 'transparent',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  textOverflow: 'ellipsis',
  '&::placeholder': {
    opacity: 0,
    transition: '0.1s ease-out',
  },
  '&:focus::placeholder': {
    opacity: 1,
  },
  '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label': {
    top: '0.5rem',
    fontSize: '0.75rem',
  },
  '&:focus ~ label': {
    color: 'var(--Input-focusedHighlight)',
  },
  '&:-webkit-autofill': {
    alignSelf: 'stretch', // to fill the height of the root slot
  },
  '&:-webkit-autofill:not(* + &)': {
    marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
    paddingInlineStart: 'var(--Input-paddingInline)',
    borderTopLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
    borderBottomLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
  },
});
const StyledLabel = styled('label')(({ theme }) => ({
  position: 'absolute',
  lineHeight: 1,
  top: 'calc((var(--Input-minHeight) - 1em) / 2)',
  color: theme.vars.palette.text.tertiary,
  fontWeight: theme.vars.fontWeight.md,
  transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}));
const InnerInput = React.forwardRef(function InnerInput(props, ref) {
  const id = React.useId();
  return (
    <React.Fragment>
      <StyledInput {...props} ref={ref} id={id} />
      <StyledLabel htmlFor={id}>Label</StyledLabel>
    </React.Fragment>
  );
});
const Upload1 = () => {
  TextMaskAdapter.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  const [country, setCountry] = useState('1');
  const onChange0 = (value) => {
    setCountry(value);
    setNumbers(null);
  };

  const params = useParams();
  const [id, setId] = useState(params.id);

  // const userid = localStorage.getItem('token');
  const [location, setLocation] = useState();
  const [descriptions, setDescriptions] = useState(null);
  const [categories, setCategories] = useState(null);
  const [images, setImages] = useState('no_image3.png');
  const [price, setPrice] = useState(0);
  const [numbers, setNumbers] = useState(null);
  const [currency, setCurrency] = useState('RUB');
  const [custom, setCustom] = useState(false);
  const [custom2, setCustom2] = useState(false);
  const [custom3, setCustom3] = useState(false);
  const [custom4, setCustom4] = useState(false);
  const [custom5, setCustom5] = useState(false);
  const [custom6, setCustom6] = useState(false);
  const [custom7, setCustom7] = useState(false);
  const [custom8, setCustom8] = useState(false);
  const [custom9, setCustom9] = useState(false);
  const [atributes, setAtributes] = useState([]);
  const [subCategories, setSubcategories] = useState();
  const [subCategories2, setSubcategories2] = useState(null);
  const [subCategories3, setSubcategories3] = useState(null);
  const [subCategories4, setSubcategories4] = useState(null);
  const [subCategories5, setSubcategories5] = useState(null);
  const [subCategories6, setSubcategories6] = useState(null);
  const [subCategories7, setSubcategories7] = useState(null);
  const [podcategories, setPodcategories] = useState(null);
  const [period, setPeriod] = useState();
  const [categories2, setCategories2] = useState(null);
  const [models, setModels] = useState();
  const [files, setFiles] = useState([]);
  const [buy_sell, setBuy_sell] = useState();
  const [image1, setImage1] = useState('no_image3.png');
  const [image2, setImage2] = useState('no_image3.png');
  const [image3, setImage3] = useState('no_image3.png');
  const [image4, setImage4] = useState('no_image3.png');
  const [image5, setImage5] = useState('no_image3.png');
  const [image6, setImage6] = useState('no_image3.png');
  const [recommend, setRecommend] = useState();
  const [city, setCity] = useState();
  const [rooms, setRooms] = useState();
  const [get_currency, setGet_currency] = useState(null);
  const [names, setNames] = useState(['']);
  const [metro, setMetro] = useState(null);
  const [floor, setFloor] = useState();
  const [square, setSquare] = useState();
  const [acres, setAcres] = useState();
  const [mark, setmark] = useState(null);
  const [my_ad, setMy_ad] = useState(null);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const { Option } = Select;
  const handleChange = (value) => {
    setAtributes(value);
    
    
  };
  const [usertoken, setUsertoken] = useState(localStorage.getItem('token2'));
  const [useruid, setUseruid] = useState(localStorage.getItem('token'));

  // const useridtoken = async () => {
  //   if(localStorage.getItem('token') != null){
  //     const post = {
  //       id: localStorage.getItem('token'),
  //   };
  //   const data = await axios({
  //       method: 'get',
  //       params: post,
  //       url: API_URL + '/users5'
  //   });
  //   
  //   if (data.data.status == 200) {
  //       
  //       setUsertoken(data.data.users5[0].id);
  //   } else {
  //       

  //   }
  //   }
  //   if(localStorage.getItem('g') != null){
  //     const post = {
  //       id: localStorage.getItem('g'),
  //   };
  //   const data = await axios({
  //       method: 'get',
  //       params: post,
  //       url: API_URL + '/users5'
  //   });
  //   
  //   if (data.data.status == 200) {
  //       
  //       setUsertoken(data.data.users5[0].id);
  //   } else {
  //       

  //   }
  //   }
      
  // }
  
  const addpost = async () => {
    const form = {
      price: price,
      numbers: numbers,
      descriptions: descriptions,
      categories: categories,
      currency: currency,
      userid: usertoken,
      podcategories: parseInt(podcategories),
      buy_sell: buy_sell,
      metro: metro,
      categories2: categories2
    }
    // form.append('price', price);
    // form.append('numbers', numbers);
    // form.append('descriptions', descriptions);
    // form.append('categories', categories);
    // form.append('currency', currency);
    // form.append('userid', usertoken);
    // form.append('podcategories', parseInt(podcategories));
    // form.append('buy_sell', buy_sell);
    // form.append('metro', metro);
    // form.append('categories2', categories2);
    const data = await axios({
      method: 'post',
      params: form,
      url: API_URL + '/client1',
      // headers: { "Content-Type": "multipart/form-data" }
    });
    
    if (data != null) {
      if (data.status == 200) {
        
        success2();
        // setTimeout(() => {
        //   get_my_ad();
        // }, 1000);
        window.location.href='/userinfo/'+3
      } else {
        
        error2();
      }
    }
  }
  
  const  get_my_ad = async () => {
    const post = {
        id: usertoken,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/my_ad_upload'
    });
    
    if (data.data.status == 200) {
        setMy_ad(data.data.my_ad[0].id);
        
        // 
        window.location.href='/upload_image/'+ data.data.my_ad[0].id
    }if(useruid != null){
      const post = {
        id: useruid,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/my_ad_upload'
    });
    
    if (data.data.status == 200) {
        setMy_ad(data.data.my_ad[0].id);
        
        // 
        window.location.href='/upload_image/'+ data.data.my_ad[0].id
    }
    } else {
      warning2();
    } 
}




  

  const fetchCategory = async (id) => {
    setCustom(false);
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    })
    
    if (data.data.status == 200) {
      setCustom(true);
      setCustom3(false);
      setCustom2(false);
      setCustom4(false);
      setSubcategories(data.data.getcategory);
    } else {
      setCustom(false);
    }
  }
  const fetchCurrency = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcurrency',
    })
    
    if (data.data.status == 200) {
      setGet_currency(data.data.getcurrency);
    } else {

    }
  }
  const fetchCategory2 = async (id) => { 
    setCustom3(false);
    setCustom5(false);
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    });
    
    if (data.data.status == 200) {
      setCustom2(true);
      setCustom3(false);
      setSubcategories2(data.data.getcategory);
    } else {
      setCustom2(false);
    }
  }
  const fetchCategory5 = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory4',
      
    });
    
    if (data.data.status == 200) {
      setCustom6(true);
      setSubcategories5(data.data.getcategory4);
    } else {
      setCustom6(false);
    }
  }
  // Площадь,срок 
  const fetchCategory6 = async (id) => {

    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    });
    
    if (data.data.status == 200) {
      setCustom5(true);
      setSubcategories6(data.data.getcategory);

    } else {
      // setCustom3(false);
    }
  }
  const fetchCategory7 = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    });
    
    if (data.data.status == 200) {
      setSubcategories7(data.data.getcategory);
    } else {

    }
  }
  // главная категория 
  const onChange = async (value) => {
    
    error();
    
    if (value == 2){
      window.location.href='/transport';
    }
    if (value == 6){
      fetchCategory2(value + 0);
      setCustom(true);
    }
    if(value == 14){
      window.location.href='/upload_zapchasti';
      // fetchCategory5();
    }
    if (value ==723){
      window.location.href='/upload_odejda';
    }
    if (value ==724){
      window.location.href='/upload_obuvi';
    }
    setCategories(value);
    fetchCategory(value);
    setSubcategories(null);
    setCustom(false);
    setCustom2(false);
    setCustom3(false);
    setCustom4(false);
    setCustom5(false);
    setCustom6(false);
    setCustom7(false);
    setCustom8(false);
    setCustom9(false);
    
  };


  // podcategories 
  const onChange2 = async (value) => {
    setCustom9(false);
    if (value == 25){
      window.location.href='/kvartiry/'+ value;
      // fetchCategory5(2500);
      // setCustom6(true);
    } 
    if (value == 26){
      window.location.href='/doma/';

    }
    if(value == 30 ){
      setCustom9(true);
    }else{
      setCustom9(false);
    }
    if(value == 28 || value == 27){
    setCustom8(true);
    fetchCategory2(value);
    }else{
      setCustom8(false);
    }
    if(value == 29 || value == 30){
      setCustom8(true);
      fetchCategory2(28);
    }
    if(value == 629 || value == 628){
      fetchCategory2(60);
      setCustom(true);
    }
    if( value == 734 ){
      fetchCategory4(734);
      setCustom4(true);
    }
    if( value == 735 ){
      fetchCategory4(735);
      setCustom4(true);
    }
    if( value == 773 ){
      fetchCategory4(773);
      setCustom4(true);
    }
    if( value == 774 ){
      fetchCategory4(774);
      setCustom4(true);
    }
    
    setPodcategories(value);
    setSubcategories2(null);
  };
  // arenda prodaja 
  const onChange3 = async (value) =>{
    
    setCustom3(false);
    setBuy_sell(value);
    setSubcategories3(null);
    fetchCategory6(value);
    if (value == 33 || value == 36 || value == 37 || value == 38 || value == 39) {
      setCustom8(true);
      setBuy_sell(value);
    } else {
      setCustom8(false);
    }
    if (value == 32 || value == 34 || value == 35) {
      setCustom5(true);
      setBuy_sell(value);
      fetchCategory6(33);
    }
  };
  const onChange4 = async (value) => {
    setCategories2(value);
    
  };
  const onChange5 = async (value) => {
    
    setPeriod(value);
  };
  const onChange6 = async (value) => {
    
      setmark(value);
  };

  const [products2, setProducts2] = useState(null);
  const fetchProducts2 = async () => {
    const post = {
      id: id || id+'00',
      type: 2
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL + '/getcategory'
    });
    if (data.data.status == 200) {
      setProducts2(data.data.getcategory);
      filter();
      
    } else {
      
      
    }
  }
  const fetchCategory4 = async (value) => {
    const params = {
      id: value
    }
    
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    })
    
    if (data.data.status == 200) {
      setCustom4(true);
      setSubcategories4(data.data.getcategory);
    } else {
      setCustom4(false);
    }

  }

  const [products, setProducts] = useState(null);
  const fetchProducts = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getmetro'
    });
    if (data.data.status == 200) {
      setProducts(data.data.getmetro);
      
    } else {
      
    }
  }
  const filter = () => {
    if (products > 0 || products != null) {
      const ad = products.filter(i => i.parent_id == 0);
      
      if (ad.length > 0 && ad[0].city != null) {
        
        return ad[0].city;
      } else {
        return 'error';
      }
    }
  }
  const [messageApi, contextHolder] = message.useMessage();
  const error = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
  };

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
  const success2 = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
    setTimeout(success,100)
    // window.location.href='/userinfo/'+3
  };
  const error2 = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
    setTimeout(warning,3000)
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'This is a warning message',
    });
}
const warning2 = () => {
  messageApi.open({
    type: 'warning',
    content: 'Ошибка! Переавторизуйтесь!',
  });
}
const onEmojiClick = (event, emojiObject) => {
  setChosenEmoji(emojiObject);
};

  useEffect(() => {
    fetchProducts();
    fetchProducts2();
    fetchCurrency();
    filter();
  }, [])
  return (
    <>

    {contextHolder}
      <Nav />
      <div style={{marginTop:'100px'}} className="container ">
        <div class="row">
          <div className="col-md-3"></div>
          <div className="col-md-7 card">
            <div class="row">
              <div className='col-md-12'>
                <h5>Разместите БЕСПЛАТНОЕ обьявление</h5>
              </div>
              <div className='col-md-12'>
               
                <div className="row">
                  <div className="col-md-3">

                  </div>
                </div >
                


                
                

              </div>
              {/* <div className="col-md-3 mt-3 d-none">
                <Cascader options={options} onChange={onChange} placeholder="Please select" />
              </div> */}


              {products2 != null || products2 != undefined || products2?.length > 0 ?
                <>
                  <div className="col-md-auto mt-3">
                    {/* Главная */}
                    <Select onChange={onChange} defaultValue={products2[0].name} style={{ width: '100%' }}>
                      {products2.map((products) =>
                        <Option value={products.id}>{products.name}</Option>
                      )
                      }
                    </Select>
                  </div>
                  {custom6 ?
  <>
    <div className="col-md-3 mt-3">
      {subCategories5?.length > 0 ?
        <>
          {/* komnat */}
          <Select onChange={onChange6} defaultValue={subCategories5[0].name} style={{ width: '100%' }}>
            {
              subCategories5.map((products) =>
                <Option value={products.id_car_mark}>{products.name}</Option>

              )
            }

          </Select>
        </> : <></>
      }
    </div>
  </> :
  <></>
}
                  {custom ?
                    <>
                      <div className="col-md-auto mt-3">

                        {subCategories?.length > 0 ?
                          <>
                            {/* Подкатегории */}
                            <Select onChange={onChange2} defaultValue={subCategories[0].name} style={{ width: '100%' }}>
                              {
                                subCategories.map((products) =>
                                  <Option  value={products.id}>{products.name}</Option>
                                )
                              } </Select>
                          </> : <></>
                        }



                      </div>
                    </>
                    : <></>
                  }
                  {custom2 ?
                    <>
                      <div className="col-md-3 mt-3">
                        {subCategories2?.length > 0 ?
                          <>
                            {/* купить продать снимать */}
                            <Select onChange={onChange3} defaultValue={subCategories2[0].name} style={{ width: '100%' }}>
                              {
                                subCategories2.map((products) =>
                                  <Option value={products.id || products.id_car_mark}>{products.name}</Option>
                                )
                              }
                            </Select>
                          </> : <></>
                        }
                      </div>

                    </>
                    : <></>
                  }
                  {custom3 ?
                    <>


                     
                    </>
                    : <></>
                  }
                {custom8 ? <>
                {/* ploshad */}
                <div className="col-md-3 mt-3">
                        <Input onChange={(e) => { setPrice(e.target.value) }} rows={1} placeholder="Площадь (м2)" />
                      </div>
                      
                </>:
                <></>
                }
                {custom9 ? <>
                <div className="col-md-3 mt-3">
                        <Input onChange={(e) => { setPrice(e.target.value) }} rows={1} placeholder="Пл.(соток)" />
                      </div>
                </>:
                <></>
                }
                
                  {custom5 ?
                    <>
                      <div className="col-md-3 mt-3">
                        {subCategories6?.length > 0 ?
                          <>
                            {/* srok  */}
                            <Select onChange={onChange5} defaultValue={subCategories6[0].name} style={{ width: '100%' }}>
                              {
                                subCategories6.map((products) =>
                                  <Option value={products.id}>{products.name}</Option>
                                )
                              }
                            </Select>
                          </> : <></>
                        }
                      </div>
                    </> :
                    <></>
                  }
                  
                  {custom4 ?
                    <>
                      <div className="col-md-3 mt-3">
                        {subCategories4?.length > 0 ?
                          <>
                            {/* categories2  */}
                            <Select onChange={onChange4} defaultValue={subCategories4[0].name} style={{ width: '100%'}}>
                              {subCategories4.map((products) =>
                                <Option  value={products.id}>{products.name}</Option>
                              )
                              }
                            </Select>
                            {/* </Select> */}
                          </> : <></>
                        }
                      </div>
                    </> :
                    <></>
                  }
                </>
                : <>Loading</>
              }
              <div className="col-md-auto mt-3 ">
              {products != null || products != undefined || products ?.length > 0 ?<>
                  <Select
                    onChange={setMetro}
                  showSearch
                  style={{
                  width: 250, 
                }}
                placeholder="Метро"
                optionFilterProp="children"
                > 
                <Option value={null}>Все</Option>
              {products.map((products) =>
                <Option
                filterOption={(input, products) => (products?.label ?? '').includes(input)}
                filterSort={(productsA, productsB) =>
                  (productsA?.label ?? '').toLowerCase().localeCompare((productsB?.label ?? '').toLowerCase())
                }
                value={products.id_sql}>{products.Station}
                {products.color != 1 ? <> <MediumCircleFilled style={{color : '#' + products.color, fontSize:'22px'}} /> </> :
                                     <>
                                     <img src={products.image} width='15px' height='15px' style={{borderRadius:'50%'}}></img>
                                    </>}
                </Option>
              )
              }
              </Select>
        </>
        :<>Loading</>
        }
              </div>
              <div className='col-md-3 mt-3'>
              <Input id="price" defaultValue={0} name="price" type="number" onChange={(e) => { setPrice(e.target.value) }}   placeholder="Цена" />
                {/* <Input id="price" name="price" onChange={(e) => { setPrice(e.target.value) }} rows={1} placeholder="Цена" /> */}
              </div>
              <div className='col-md-3 mt-3'>
                {get_currency != null || get_currency != undefined || get_currency?.length > 0 ?
                  <><Select onChange={setCurrency} defaultValue={get_currency[1].currency} style={{ width: '100%' }}>
                    {get_currency.map((products) =>
                      <Option value={products.currency}>{products.currency}</Option>
                    )
                    }
                  </Select>
                  </>
                  : <>Loading</>
                }
              </div>
              
              <div className='col-md-auto mt-2'>
              <div class="row">
									<div class="col-md-3  col-4">
                  <Select
                      onChange={onChange0}
                      defaultValue={'1'}
                      style={{
                        marginLeft: '17px',
                        width:'100%',
                        height:'40px'
                      }}
                    >
                      <Option value={'2'}><img width='40px' height='40px'  src={uploads+'flag_kg.png'}></img>+996</Option>
                      <Option value={'1'}><img width='40px' height='40px'  src={uploads+'flag_rus.jpg'}></img>+7</Option>
                    </Select>
                  </div>
									<div class="col-md-8 col-8 ">
                    {country == '1' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="+7 *** *** ** **"
                      slotProps={{ input: { component: TextMaskAdapter } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                    {country == '2' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="+996 *** ** ** **"
                      slotProps={{ input: { component: TextMaskAdapter2 } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                   
                  </div>
                </div>
            </div>
      
              <h5>Описание</h5>
              <InputEmojiWithRef

              maxLength={300}
              onChange={setDescriptions}
              // onChange={(e) => { setDescriptions(e.target.value) }}
              placeholder="Описание"
              name="descriptions"
              id="descriptions"
                color="neutral"
                minRows={2}
                variant="outlined"
              />
                {/* <TextArea id="descriptions" name="descriptions" onChange={(e) => { setDescriptions(e.target.value) }} rows={4} placeholder="Описание" maxLength={300} /> */}

              {/* <p><b>Загрузите фото</b>(до 7)</p>
              <div className="col-md-12">
                  <DragAndDropUploader
                    className="mb-3 p-2"
                    style={{ width: '100%', maxHeight: '150px' }}
                    onChange={(file) => {
                      setFiles([...files, file]);
                    }}
                    onRemove={(f) => {
                      const index = files.indexOf(f);
                      if (index !== -1) {
                        const f = files.splice(index, 1);
                        setFiles(f);
                      }
                    }}
                  />
                </div> */}

              <div className='col-md-12 mt-2'>
                <button onClick={addpost} className='btn btn-primary'>
                  Опубликовать
                </button>
              </div>

            </div>
          </div>

        </div>
      </div>
       <Footer />
       <div  className='d-sm-block d-md-none d-lg-none d-xl-none '>
                <Footer_menu />
            </div>
    </>
  )
}
export default Upload1;

// {custom7 ?
//   <>
//     <div className="col-md-3 mt-3">
//       {subCategories7?.length > 0 ?
//         <>
//           {/* seria */}
//           <Select onChange={onChange6} defaultValue={222} style={{ width: '100%' }}>
//             {
//               subCategories7.map((products) =>
//                 <Option value={products.id}>{products.name}</Option>
//               )
//             }
//           </Select>
//         </> : <>
//         11111
//         </>
//       }
//     </div>
//   </> :
//   <></>
// }
