import axios from 'axios';
import { useEffect, useState } from 'react';
import Footer from './footer';
import Nav from './navbar';
import React from 'react';
import { Button, Image, message, Modal, Radio, Skeleton, Spin, Tabs } from 'antd';
import { Checkbox, Form, Input } from 'antd';
import Post2 from './post2';
import { tab } from '@testing-library/user-event/dist/tab';
import Post_lk from './post_lk';
import { useParams } from 'react-router-dom';
import Post_lk_ak from './post_lk_ak';
import { API_URL, uploads } from '../config/config';
import { CloseOutlined, MediumCircleFilled } from '@ant-design/icons';
import Footer_menu from './footer_menu';
import Vhod from './vhod';
import Post_aktiv from './post_aktiv';
import Post_deak from './post_deak';
import Post_moder from './post_moder';
import Post_izbr from './post_izbr';
import { setLikes } from '../redux/actions/like_actions';
import { useDispatch, useSelector } from 'react-redux';
import { click } from '@testing-library/user-event/dist/click';
import { setProducts2 } from '../redux/actions/products2_actions';
import Korzina from './korzina';
import moment from 'moment';

const Userinfo = ()=>{
    const [messageApi, contextHolder] = message.useMessage();
    const { products2 } = useSelector((state) => state.products);
    const params = useParams();
    const [id, setId] = useState(params.id);
    const dispatch = useDispatch();
    const user_uid = localStorage.getItem('token');
    const [neaktiv, setNeaktiv] = useState(null);
    const [moderatsii, setModeratsii] = useState(null);
    const [products, setProducts] = useState(null);
    const [products3, setProducts3] = useState(null);
    // const [products2, setProducts2] = useState(null);
    const [product, setProduct] = useState(null);
    const [product_cat, setProduct_cat] = useState(null);
    const [usertoken, setUsertoken] = useState(null);
    const [user2_id, setUser2_id] = useState(null);
    const [ad_id, setAd_id] = useState(null);
    const [logged, setLogged] = useState(false);
  const useridtoken = async () => {
    if(localStorage.getItem('token') != null){
        const post = {
            id: localStorage.getItem('token'),
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users5'
        });
        if (data.data.status == 200) {
            // fetchProducts2(data.data.users5[0].id);
            // setTimeout(fetchProducts(data.data.users5[0].id),200);
            setUsertoken(data.data.users5[0].id);
            fetchProduct(data.data.users5[0].id);
            fetchProducts3(data.data.users5[0].id);
            // fetchProducts2(data.data.users5[0].id);
            // fetchFavorites(data.data.users5[0].id);
            
        }if(localStorage.getItem('g') != null){
            const post = {
                id: localStorage.getItem('g'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            
            if (data.data.status == 200) {
                // fetchProducts2(data.data.users5[0].id);
                // setTimeout(fetchProducts(data.data.users5[0].id),200);
                setUsertoken(data.data.users5[0].id);
                fetchProduct(data.data.users5[0].id);
                fetchProducts3(data.data.users5[0].id);
                // fetchProducts2(data.data.users5[0].id);
                // fetchFavorites(data.data.users5[0].id);
                // fetchModeratsii(data.data.users5[0].id);
                
            }
    }
        } 
  }
    const fetchProduct = async (user_id) => {
        const post = {
            id: user_id,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL+'/users1'
        });
        // 
        if (data.data.status == 200) {
            setProduct(data.data.users1);
        } else {
            // 

        }  
    }
    // const fetchProduct_cat = async (categories) => {
    //     const post = {
    //         id: categories,
    //     };
    //     const data = await axios({
    //         method: 'get',
    //         params: post,
    //         url: API_URL + '/id_category'
    //     });
    //     if (data.data.status == 200) {
    //         setProduct_cat(data.data.post);
    //         
    //     } else {
    //         
    //         
    //     }
    // }
                                                                                                                        // const fetchProducts = async (usertoken) => {
                                                                                                                        //     const params={
                                                                                                                        //      user_id : usertoken
                                                                                                                        //      };
                                                                                                                        //      const data = await axios({
                                                                                                                        //          method:'get',
                                                                                                                        //          params: params,
                                                                                                                        //          url:API_URL+'/get_favorites'
                                                                                                                        //      });
                                                                                                                        //      if(data.data.status == 200){
                                                                                                                        //          dispatch(setProducts(data.data.favorites));
                                                                                                                        //         //  setLimit( limit + 16 );
                                                                                                                        //      }else{
                                                                                                                        //         //  
                                                                                                                        //         //  
                                                                                                                        //      }
                                                                                                                        //  }
const  fetchProducts3 = async (id) => {
      const post = {
          id: id,
      };
      const data = await axios({
          method: 'get',
          params: post,
          url: API_URL+'/my_ad'
      });
      if (data.data.status == 200) {
          setProducts3(data.data.my_ad);
      } else {

      } 
  }
 
//   const fetchProducts2 = async (userid) => {
//     const params={
//      user_id : userid
//      };
//      const data = await axios({
//          method:'get',
//          params: params,
//          url:API_URL+'/get_favorites'
//      });
//      if(data.data.status == 200){
//          dispatch(setProducts2(data.data.favorites));
//      }else{
//          
//          
//      }
//  }
                                                                                                // const fetchProducts2 = async (usertoken) => {
                                                                                                //     if(usertoken != null){
                                                                                                //         const post = {
                                                                                                //             user_id: usertoken,
                                                                                                //         };
                                                                                                //         const data = await axios({
                                                                                                //             method: 'get',
                                                                                                //             params: post,
                                                                                                //             url: API_URL+'/get_favorites'
                                                                                                //         });
                                                                                                //         // 
                                                                                                //         if (data.data.status == 200) {
                                                                                                //             dispatch(setLikes(data.data.favorites));
                                                                                                //             // 
                                                                                                //         } else {
                                                                                                //             // 
                                                                                                //             // 
                                                                                                //         }
                                                                                                //     }else{
                                                                                                //         // 
                                                                                                //         alert(usertoken);
                                                                                                //     }}
// const fetchProducts2 = async (user_id) => {
//   const post = {
//       user_id: user_id,
//   };
//   const data = await axios({
//       method: 'get',
//       params: post,
//       url: API_URL+'/get_favorites'
//   });
//   if (data.data.status == 200) {
//       setProducts2(data.data.favorites);
//       
//   } else {
//       
//       
//   }
// }
                                                                            // const fetchFavorites = async (user_id) => {
                                                                            //     const post = {
                                                                            //         user_id: user_id,
                                                                            //     };
                                                                            //     const data = await axios({
                                                                            //         method: 'get',
                                                                            //         params: post,
                                                                            //         url: API_URL+'/get_favorites'
                                                                            //     });
                                                                            //     if (data.data.status == 200) {
                                                                            //         dispatch(setLikes(data.data.favorites));
                                                                            //         // 
                                                                            //     } else {
                                                                            //         // 
                                                                            //         // 
                                                                            //     }
                                                                            // }

const fetchNeaktiv = async () => {
    const post = {
        user_id: usertoken,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/neaktiv'
    });
    if (data.data.status == 200) {
        setId(9)
        setNeaktiv(data.data.neaktiv);
        // 
    } else {
        // 
        // 
    }
}
const fetchModeratsii = async () => {
    const post = {
        user_id: usertoken,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/moderatsii'
    });
    // 
    if (data.data.status == 200) {
        setModeratsii(data.data.moderatsii);
        // 
    } else {
        // 
        // 
    }
}

const delfavorites = async (ad_id)=>{

    const params = {
        ad_id:ad_id,
        user_id:usertoken,
    };
    // 

    const data = await axios({
        method:'delete',
        params:params,
        url:API_URL+'/delete_favorites'
    });

    // 
    if(data != null){
        if(data.status  == 200){
        // 
        // fetchProducts2();
        Userinfo.reload();
        }else{
            alert('Error')
        }
    }
}

const iz_status4 = async (ad_id)=>{
    const params = {
        status:'4',
        ad_id:ad_id
    };
    const data = await axios({
        method:'post',
        params:params,
        url:API_URL+'/iz_status3'
    });
    // 
    if(data != null){
        if(data.status  == 200){
        // 
        fetchModeratsii();
        Userinfo.reload();
        // window.location.href="userinfo";
        }else{
            alert('Error')
        }
    }
}
const iz_status3 = async (ad_id)=>{
    const params = {
        status:'3',
        ad_id:ad_id
    };
    const data = await axios({
        method:'post',
        params:params,
        url:API_URL+'/iz_status3'
    });
    // 
    if(data != null){
        if(data.status  == 200){
        // 
        fetchNeaktiv();
        Userinfo.reload();
        // window.location.href="userinfo";
        }else{
            alert('Error')
        }
    }
}
const iz_status2 = async (ad_id)=>{
    const params = {
        status:'2',
        ad_id:ad_id
    };
    const data = await axios({
        method:'post',
        params:params,
        url:API_URL+'/iz_status3'
    });
    // 
    if(data != null){
        if(data.status  == 200){
        // 
        fetchModeratsii();
        Userinfo.reload();
        // window.location.href="userinfo";
        }else{
            alert('Error')
        }
    }
}

const iz_status1 = async (ad_id)=>{
    const params = {
        status:'1',
        ad_id:ad_id
    };
    const data = await axios({
        method:'post',
        params:params,
        url:API_URL+'/iz_status3'
    });
    // 
    if(data != null){
        if(data.status  == 200){
        // 
        fetchNeaktiv();
        Userinfo.reload();
        // window.location.href="userinfo";
        }else{
            alert('Error')
        }
    }
}
const [ad_get_value, setAd_get_value] = useState(null);
const [del_ad_id, setDel_ad_id] = useState(null);
const [images, setImages] = useState(null);
const [image1, setImage1] = useState(null);
const [image2, setImage2] = useState(null);
const [image3, setImage3] = useState(null);
const [image4, setImage4] = useState(null);
const [image5, setImage5] = useState(null);
const [image6, setImage6] = useState(null);
const ad_get = async (ad_id)=>{
    const params = {
      id: ad_id,
    };
    const data = await axios({
      method: 'get',
      params: params,
      url: API_URL+'/ad_get',
    });
    if (data.data.status == 200) {
        setAd_get_value(data.data.ad_get);
        setDel_ad_id(data.data.ad_get[0].id);
        setImages(data.data.ad_get[0].images);
        setImage1(data.data.ad_get[0].image1);
        setImage2(data.data.ad_get[0].image2);
        setImage3(data.data.ad_get[0].image3);
        setImage4(data.data.ad_get[0].image4);
        setImage5(data.data.ad_get[0].image5);
        setImage6(data.data.ad_get[0].image6);
        
        del_image(data.data.ad_get[0].id,data.data.ad_get[0].images,data.data.ad_get[0].image1,data.data.ad_get[0].image2,data.data.ad_get[0].image3,data.data.ad_get[0].image4,data.data.ad_get[0].image5,data.data.ad_get[0].image6);
    } else {
        
        
    }
  }
  
  const del_image = async (del_ad_id,images,image1,image2,image3,image4,image5,image6)=>{
    if(del_ad_id != null){
        const params = {
            ad_id: del_ad_id,
            images: images,
            image1: image1,
            image2: image2,
            image3: image3,
            image4: image4,
            image5: image5,
            image6: image6,
          };
      const data = await axios({
          method:'post',
          params:params,
          url:API_URL+'/del_image',
      });
      
      if(data != null){
          if(data.data.status  == 200){
        del_ad(del_ad_id,usertoken);
          }else{
            del_ad(del_ad_id,usertoken);
          }
      }
    }else{

    }
      
  }
  const del_ad = async (del_ad_id,user_id)=>{
    if(del_ad_id != null){
        const params = {
            ad_id: del_ad_id,
            user_id: user_id
          };
      const data = await axios({
          method:'post',
          params:params,
          url:API_URL+'/del_ad',
      });
      
      if(data != null){
          if(data.data.status  == 200){
            fetchNeaktiv();
            fetchModeratsii();
            Userinfo.reload();
          }
      }
    }else{
    //   error();
    }
      
  }
const delete_ad = async (ad_id)=>{
    const post = {
        id: id,
      };
      const data = await axios({
        method: 'delete',
        params: post,
        url: API_URL+'/update1',
      });
      fetchNeaktiv();
   
}



const [products_metro, setProducts_metro] = useState();
const fetchProducts_metro2 = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/getmetro'
});
if (data.data.status == 200){
  setProducts_metro(data.data.getmetro);
//   
} else {
//   
}
}
const [products_category, setProducts_category] = useState(null);
const fetchProducts_category = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/cat_get'
});
if (data.data.status == 200){
  setProducts_category(data.data.cat_get);
//   
} else {
//   
}
}
const [number, setNumber] = useState();

  

    const tab1 = <Button  type="dark ml-3" > <a style={{color:'black'}} >Профиль</a> </Button>;
    const tab2 = <Button href='/korzina'   type="dark " ><a style={{color:'black'}} >Избранные</a> </Button>;
    // fetchProducts3(usertoken)
    const tab3 = <Button type="dark " ><a style={{color:'black'}}>Мои обьявлении</a> </Button>;
    // const tab4 = <Button  type="dark " ><a style={{color:'black'}} >Покупки</a> </Button>;
    // const tab5 = <Button  type="dark " ><a style={{color:'black'}} >Отзывы и вопросы</a> </Button>;
    // const tab6 = <Button  type="dark " ><a style={{color:'black'}} href={'/mainsms/' + user_uid} >Сообщении</a> </Button>;
    // const tab7 = <Button  type="dark " ><a style={{color:'black'}} >Баланс</a> </Button>; 
    const tab8 = <Button  type="dark "  onClick={fetchProducts3(usertoken)}>   <a style={{color:'black'}} >Активные</a> </Button>;  
    const tab9 = <Button  onClick={fetchNeaktiv} type="dark " >   <a style={{color:'black'}} >Деактивированные</a> </Button>;  
    const tab10 = <Button  onClick={fetchModeratsii} type="dark " >   <a style={{color:'black'}} >На модерации</a> </Button>;  

    const [products_click, setProducts_click] = useState(null);
const fetchProducts_click = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/getclick'
});
// 
if (data.data.status == 200){
  setProducts_click(data.data.getview);
//   
} else {
//   
}
}
const click = async (ad_id) => {
    if (ad_id != null) {
        const params = {
            ad_id: ad_id,
        };
        const data = await axios({
            method: 'get',
            params: params,
            url: API_URL + '/click_post'
        });
        // 
        if (data.data.status == 200){
            add_click(ad_id);
            // setClicks(data.data.click_post);
            
        } else {
           new_click(ad_id);
        }
    } else {
    }
}
const add_click = async (ad_id) => {
    const params = {
        ad_id: ad_id
    };
    const data = await axios({
        method: 'put',
        params: params,
        url: API_URL + '/click_post'
    });
    // 
    if (data != null){
        if (data.status == 200){
            window.location.href='/post/'+ad_id
        } else {
        }
    }
}
const new_click = async (ad_id) => {
    const params = {
        ad_id: ad_id,
        click: 1
    };
    const data = await axios({
        method: 'post',
        params: params,
        url: API_URL + '/click_post'
    });
    // 
    if (data != null){
        if (data.status == 200){
            window.location.href='/post/'+ad_id
        } else {
        }
    }
    const iz_status = async (ad_id)=>{
        const params = {
            status:'2',
            ad_id:ad_id
        };
        const data = await axios({
            method:'post',
            params:params,
            url:API_URL+'/iz_status3'
        });
        // 
        if(data != null){
            if(data.status  == 200){
            // 
            window.location.href='userinfo/'+ 2
            }else{
                alert('Error')
            }
        }
    }
}

const iz_status_na2 = async (ad_id)=>{
    const params = {
        status:'2',
        ad_id:ad_id
    };
    const data = await axios({
        method:'post',
        params:params,
        url:API_URL+'/iz_status3'
    });
    // 
    if(data != null){
        if(data.status  == 200){
        // 
        fetchProducts3(usertoken);
        Userinfo.reload();
        }else{
            alert('Error')
        }
    }
}


const [product3, setProduct3] = useState(null);
const [product4, setProduct4] = useState(null);
const fetchProduct4 = async () => {
    const post = {
        id: product.userid,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL +'/users1'
    });
    // 
    if (data.data.status == 200) {
        setProduct4(data.data.users1);
        // 
    } else {
        
        
    }
}



const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Сообщение отправлено!',
    });
  };
  const success2 = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
  const success3 = () => {
    messageApi.open({
      type: 'success',
      content: 'Добавлено в избранное!',
    });
  };
  const success4 = () => {
    messageApi.open({
      type: 'success',
      content: 'Удалено из избранное!',
    });
  };
 
  const error = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
  };

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Авторизуйтесь!',
    });
}

    useEffect(() => {
        useridtoken();
        fetchProducts_click();
        fetchProducts_metro2();
        fetchProducts_category();
        fetchProduct4();
        fetchProducts3();
    }, [])
    
    return(
        <>
        {contextHolder}
        {product != null || product != undefined || product?.length > 0 ?
    <>
    <Nav/>
        <div class="container card usercard"> 
       
                <div class="row mt-2">
                <div className='col-md-12 col-12'>
                <Tabs defaultActiveKey={id}>
                
    <Tabs.TabPane  className='row' tab={tab1} key="1">
    <h5>Профиль</h5>
                <div style={{height:'100%'}} className='col-md-3 col-lg-5 col-12 card ml-3 mt-2'>
                <div className='row'>
                      <div className='col-md-3 col-3'><img style={{width:'100px',height:'100px',borderRadius:'50%'}} src={ product[0].avatar!= null ? uploads + product[0].avatar : "https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></div>
                        <div className='col-md-1 col-1'></div>
                      <div className='col-md-7 col-7 ml-3 mt-3'>
                        <p>{product[0].name}</p>
                        <b style={{color:'gold'}}>Подключить в бизнес аккаунт</b>
                        <p>ID: {product[0].id}</p>                        
                      </div>
                    
                    <div className='col-md-12 col-12'>
                        <Button href='put_user2'>Изменить данные</Button>
                    </div>
                    </div>
                </div>
                
                <div className='card ml-3 mt-3 col-lg-5'>
                    <div className='row'>
                        <div className='col-md-12  col-12'>
                        <p style={{color:'grey'}}>Логин</p>
                        <label className='card'>{product[0].login}</label>
                    </div>

                    <div className='col-md-12 col-12'>
                        <p style={{color:'grey'}}>Пароль</p>
                        <label className='card'>{product[0].password}</label>
                    </div>
                    </div>
                </div>

                <div className='card ml-3 mt-3 col-lg-5'>
                    <div className='row'>
                    <div className='col-md-12'>
                    <p style={{color:'grey'}}>Имя</p>
                    <label className='card'>{product[0].name}</label>
                  </div>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Фамилия</p>
                    <label className='card'>{product[0].surname}</label>
                  </div>
                    </div>
                </div>

                <div className='card ml-3 mt-3 col-lg-5'>
                    <div className='row'>
                    <div className='col-md-12'>
                    <p style={{color:'grey'}}>Номер телефона</p>
                    <label className='card'>{product[0].numbers}</label>
                  </div>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Город</p>
                    <label className='card'>{product[0].city}</label>
                  </div>
                    </div>
                </div>

                <div className='card ml-3 mt-3 col-lg-5'>
                    <div className='row'>
                    <div className='col-md-12'>
                    <p style={{color:'grey'}}>E-mail</p>
                    <label className='card'>Не указано</label>
                  </div>

                  {/* <div className='col-md-12'>
                    <p style={{color:'grey'}}>Uid</p>
                    <label style={{maxHeight:'40px',overflow:'scroll'}} className='card'>{product[0].uid}</label>
                  </div> */}
                    </div>
                </div>

    </Tabs.TabPane>
    <Tabs.TabPane className='row' tab={tab2} key="2">
   
    </Tabs.TabPane>
    <Tabs.TabPane className='row' tab={tab3} key="3">
        
    <Tabs defaultActiveKey="3">
       
        <Tabs.TabPane className='row' tab={tab8} key="8">
                    {products3 != null || products3 != undefined || products3?.length > 0 ?
                <>
                <h5>Активные</h5>
                {products3.map((product)=>
            <div   class=" col-6 col-md-3 mt-3 ">

                                {/* {products_category != null ? <>
                                    {products_category.filter(category => category.id == product.categories).map(filteredCategory => (
                                <a >
                                    <label className=' medname'  style={{fontSize:'16px',fontFamily:'revert-layer',marginLeft:'10px'}}>{filteredCategory.name}</label>
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>} */}
                    <div  style={{width:'100%',marginLeft:'0px'}} class="card " >
                    {products_click != null ? <>
                                    {products_click.filter(view => view.ad_id == product.id).map(filteredView => (
                                <a>
                                    {filteredView.click != null ? <><button  style={{opacity:'0.7',borderRadius:'15%',fontSize:'12px',zIndex:'1',position:'absolute',padding:'1px'}} type="button" class="btn btn-dark"><i class="fa-solid fa-eye"></i> {filteredView.click}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}
                                {products_category != null ? <>
                                    {products_category.filter(category => category.id == product.categories).map(filteredCategory => (
                                <a>
                                    {filteredCategory.name != null ? <><button  style={{opacity:'0.7',borderRadius:'30%',fontSize:'10px',zIndex:'1',position:'absolute',padding:'1px',float:'right',right:'0'}} type="button" class="btn btn-dark">{filteredCategory.name}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}
                        <a className='postimage2'  onClick={() => click(product.id)}>
                            <div style={{pointerEvents:'none'}}>
                                <Image className='postimage2' style={{width:'100%',height:'200px'}}  src={product.images != null ? (uploads + product.images) : (uploads + 'no_image.jpg')} />
                            </div>
                            
                        </a>
                        <a className='postimage' onClick={() => click(product.id)}>
                        <div style={{pointerEvents:'none'}}>
                            <Image className='postimage' style={{width: '100%',height:'160px',borderTopLeftRadius:'5px',borderTopRightRadius:'5px'}}  src={product.images != null ? (uploads + product.images) : (uploads + 'no_image.jpg')} />
                        </div>
                        </a>
                        {products_metro != null ? <>
                                    {products_metro.filter(metro => metro.id_sql == product.metro).map(filteredMetro => (
                                <a >
                                    {filteredMetro.color != 1 ? <><p style={{color:'gray',fontSize:'12px'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'12px'}} /></p></> :
                                     <>
                                    <p style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='30px' height='30px' style={{borderRadius:'50%'}}></img></p>
                                    </>}
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>}
                                <div style={{padding:'0',marginTop:'-10px'}} className='col-md-12'><span class="card-title txt7">{product.price != 0 ? product.price : 'Договорная'}</span> <label class="card-title ml-2 txt7cur "><b>{product.currency}</b></label></div>
                                <div className='col-md-12'><p style={{fontSize:'12px'}} className="destext" >{product.descriptions}</p></div> 
                                <div class="col-12 col-md-12">
                                    <label style={{fontSize:'10px',float:'right',marginRight:'-15px'}} class="text-secondary">{moment.tz(product.date1, "Europe/Moscow").fromNow()}</label>
                                </div>
                                
                                <div className='row '>
                                    <div className='col-6 '>
                                        <Button style={{width:'100%'}} danger onClick={()=>iz_status_na2(product.id)}><i class="fa-solid fa-power-off"></i></Button>
                                    </div>
                                    <div className='col-6'>
                                    <Button style={{width:'100%'}}  ghost type='primary' href={"/update_ad/"+ product.id}><i class="fa-solid fa-pencil"></i></Button>
                                    </div>
                                </div>
                        <div style={{marginTop:'-17px'}} class="card-body medbody">
                            <div  class="row d-block d-md-none ">
                           
                                
                             
                               
                        
                               
                            </div>
                          
                                    
                               
                                </div>
                                    
                               
                            
                           
                        
                    </div>


                {/* <Post_aktiv product={item}/> */}
            </div>
            
                )
                }
                </>
                :<>
                <div className='ml-5'><label className='text-center'>Нет активных обьявлений...</label></div>
                
                </>
            }

        </Tabs.TabPane>

        <Tabs.TabPane className='row' tab={tab9} key="9">
        {neaktiv != null || neaktiv != undefined || neaktiv?.length > 0 ?
                <>
                <h5>Деактивированные</h5>
                {neaktiv.map((product)=>
                <div className='col-6 d-block d-md-none'>
                <div style={{width:'100%',marginLeft:'0px'}} class="card " >
                    {products_click != null ? <>
                                    {products_click.filter(view => view.ad_id == product.id).map(filteredView => (
                                <a>
                                    {filteredView.click != null ? <><button  style={{opacity:'0.7',borderRadius:'15%',fontSize:'12px',zIndex:'1',position:'absolute',padding:'1px'}} type="button" class="btn btn-dark"><i class="fa-solid fa-eye"></i> {filteredView.click}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}
                                {products_category != null ? <>
                                    {products_category.filter(category => category.id == product.categories).map(filteredCategory => (
                                <a>
                                    {filteredCategory.name != null ? <><button  style={{opacity:'0.7',borderRadius:'30%',fontSize:'10px',zIndex:'1',position:'absolute',padding:'1px',float:'right',right:'0'}} type="button" class="btn btn-dark">{filteredCategory.name}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}
                        <a onClick={() => click(product.id)} className='postimage2 '  >
                            <div style={{pointerEvents:'none'}}>
                                <Image className='postimage2' style={{width:'100%',height:'200px',pointerEvents:'none'}}  src={product.images != null ? ( + product.images) : (uploads + 'no_image.jpg')} />
                            </div>  
                        </a>
                        <a onClick={() => click(product.id)} className='postimage' >
                            <div style={{pointerEvents:'none'}}>
                                <Image className='postimage' style={{width: '100%',height:'160px',borderTopLeftRadius:'5px',borderTopRightRadius:'5px',pointerEvents:'none'}}  src={product.images != null ? (uploads + product.images) : (uploads + 'no_image.jpg')} />
                            </div>
                        </a>
                        {products_metro != null ? <>
                                    {products_metro.filter(metro => metro.id_sql == product.metro).map(filteredMetro => (
                                <a >
                                    {filteredMetro.color != 1 ? <><p style={{color:'gray',fontSize:'12px'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'12px'}} /></p></> :
                                     <>
                                    <p style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='30px' height='30px' style={{borderRadius:'50%'}}></img></p>
                                    </>}
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>}
                                <div style={{padding:'0',marginTop:'-10px'}} className='col-md-12'><span class="card-title txt7">{product.price != 0 ? product.price : 'Договорная'}</span> <label class="card-title ml-2 txt7cur "><b>{product.currency}</b></label></div>
                                <div className='col-md-12'><p style={{fontSize:'12px'}} className="destext" >{product.descriptions}</p></div> 
                                <div class="col-12 col-md-12">
                                    <label style={{fontSize:'10px',float:'right',marginRight:'-15px'}} class="text-secondary">{moment.tz(product.date1, "Europe/Moscow").fromNow()}</label>
                                </div>
                                
                                <div className='row '>
                                    <div className='col-4 '>
                                        <Button style={{width:'100%',borderColor:'green',color:'green'}} danger onClick={()=>iz_status3(product.id)}><i class="fa-solid fa-power-off"></i></Button>
                                    </div>
                                    <div className='col-4'>
                                    <Button style={{width:'100%'}}  ghost type='primary' href={"/update_ad/"+ product.id}><i class="fa-solid fa-pencil"></i></Button>
                                    </div>
                                    <div className='col-4 '>
                                        <Button style={{width:'100%',borderColor:'red',color:'red'}} danger onClick={()=>ad_get(product.id)}><i class="fa-solid fa-power-off"></i></Button>
                                    </div>
                                </div>
                        <div style={{marginTop:'-17px'}} class="card-body medbody">
                            <div  class="row d-block d-md-none "> 
                            </div>  
                                </div>
                    </div>

                    {/* <Post_deak product={item}/> */}
                </div>
                )}
                
                {neaktiv.map((item)=>
                
                
                     <div   class="col-6 col-md-3 mt-3 d-none d-md-block">
                    <div  class="obv " >
                    {/* {products_category != null ? <>
                                    {products_category.filter(category => category.id == item.categories).map(filteredCategory => (
                                <a >
                                    <label className=' medname'  style={{fontSize:'16px',fontFamily:'revert-layer',marginLeft:'10px'}}>{filteredCategory.name}</label><i style={{float:'right',fontSize:'10px'}} class="fa-solid fa-eye mt-2"> </i>
                                </a>
                                
                                ))}
                                </> : <>  
                                </>} */}
                                
                 <div style={{padding:'5px',marginTop:'-10px'}} class="card-body medbody card">
                     {products_click != null ? <>
                                    {products_click.filter(view => view.ad_id == item.id).map(filteredView => (
                                <a>
                                    {filteredView.click != null ? <><button  style={{opacity:'0.7',borderRadius:'15%',fontSize:'12px',zIndex:'1',position:'absolute',padding:'1px'}} type="button" class="btn btn-dark"><i class="fa-solid fa-eye"></i> {filteredView.click}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}
                                {products_category != null ? <>
                                    {products_category.filter(category => category.id == item.categories).map(filteredCategory => (
                                <a>
                                    {filteredCategory.name != null ? <><button  style={{opacity:'0.7',borderRadius:'30%',fontSize:'10px',zIndex:'1',position:'absolute',padding:'1px',float:'right',right:'0'}} type="button" class="btn btn-dark">{filteredCategory.name}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}
        <div class="row ">

                <a onClick={() => click(item.id)}>
                    <div style={{pointerEvents:'none'}}>
                        <Image style={{maxHeight:'200px',minHeight:'200px',pointerEvents:'none',borderTopLeftRadius:'15px',borderTopRightRadius:'15px',width:'100%'}}  class="cardimg" width='100%' src={item.images != null ? (uploads+ item.images) : (uploads+ 'no_image.jpg')}/>
                    </div>
                
                </a>
            <div class="col-md-12">
                <div className="row">
                <div class="col-md-12">
                    <label style={{marginTop:'0px'}} class="card-title  txt7">{item.price != 0 ? item.price : 'Договорная'}</label>
                    <label style={{marginTop:'0px',marginLeft:'10px',fontSize:'12px'}} class="card-title  ">{item.currency}</label>
                </div>
                <div class="col-md-12">
                    <p className='destext2'>{item.descriptions}</p>
                </div>
                <div class="col-md-auto ">
               
                </div>
               
                <div className='col-md-8'>
                {products_metro != null ? <>
                                    {products_metro.filter(metro => metro.id_sql == item.metro).map(filteredMetro => (
                                <a >
                                    {filteredMetro.color != 1 ? <><p style={{color:'gray',fontSize:'14px'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'12px'}} /></p></> :
                                     <>
                                    <p style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='30px' height='30px' style={{borderRadius:'50%'}}></img></p>
                                    </>}
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>}
                </div>
                <div class="col-md-3 meddate">
                    <label style={{fontSize:'10px',float:'right',marginRight:'-15px'}} class="text-secondary meddate">{item.date1}</label>
                </div>
                <div className='row'>
                    <div className='col-md-7'><Button  type="primary" ghost href={"/update_ad/"+ item.id}>Редактировать</Button></div>
                    <div className='col-md-7'><Button  type="primary" ghost onClick={()=>ad_get(item.id)}>Удалить</Button></div>
                    <div className='col-md-5'><Button style={{padding:'1px'}} danger onClick={()=>iz_status3(item.id)}>Активировать</Button></div>
                </div>
               {/* <div className='col-md-6 mt-2'>
                <Button  type="primary" ghost href={"/put_ad/"+ product.id}>Редактировать</Button>
               </div> */}
               <div className='col-md-6 mt-2'>
               {/* {status ?
              <>
                <Button style={{padding:'1px'}} danger onClick={iz_status2}>Деактивировать</Button>

              </>
              :<>
                <Button style={{padding:'1px'}} danger onClick={iz_status1}>Активировать</Button>

              </>
}         */}
                
               </div>
            
                </div>
                
                
                
            </div>

        </div>
    </div>
</div>  
                   
                </div>
                
               
                
                )
                }
                </>
                :<>
                 <div className='ml-5'><label className='text-center'>Нет деактивированных обьявлений...</label></div>
                </>
            }
        </Tabs.TabPane>

        <Tabs.TabPane className='row' tab={tab10} key="10">
            {moderatsii != null || moderatsii != undefined ? <>
                <h5>На модерации</h5>
                {moderatsii.map((product)=>
                <div className='col-6 d-block d-md-none'>
                    <div style={{width:'100%',marginLeft:'0px',marginTop:'10px'}} class="card " >
                    {products_click != null ? <>
                                    {products_click.filter(view => view.ad_id == product.id).map(filteredView => (
                                <a>
                                    {filteredView.click != null ? <><button  style={{opacity:'0.7',borderRadius:'15%',fontSize:'12px',zIndex:'1',position:'absolute',padding:'1px'}} type="button" class="btn btn-dark"><i class="fa-solid fa-eye"></i> {filteredView.click}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}
                    {products_category != null ? <>
                                    {products_category.filter(category => category.id == product.categories).map(filteredCategory => (
                                <a>
                                    {filteredCategory.name != null ? <><button  style={{opacity:'0.7',borderRadius:'30%',fontSize:'10px',zIndex:'1',position:'absolute',padding:'1px',float:'right',right:'0'}} type="button" class="btn btn-dark">{filteredCategory.name}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}
                        <a href={'/post/'+product.id} className='postimage2'>
                            <div style={{pointerEvents:'none'}}>
                                <Image  className='postimage2' style={{width:'100%',height:'200px',pointerEvents:'none'}}  src={product.images != null ? (uploads + product.images) : (uploads + 'no_image.jpg')} />
                            </div>
                             </a>
                        <a href={'/post/'+product.id} className='postimage' >
                        <div style={{pointerEvents:'none'}}>
                            <Image className='postimage' style={{width: '100%',height:'160px',borderTopLeftRadius:'5px',borderTopRightRadius:'5px',pointerEvents:'none'}}  src={product.images != null ? (uploads + product.images) : (uploads + 'no_image.jpg')} /> 
                        </div>
                        </a>
                        {products_metro != null ? <>
                                    {products_metro.filter(metro => metro.id_sql == product.metro).map(filteredMetro => (
                                <a >
                                    {filteredMetro.color != 1 ? <><p style={{color:'gray',fontSize:'12px'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'12px'}} /></p></> :
                                     <>
                                    <p style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='30px' height='30px' style={{borderRadius:'50%'}}></img></p>
                                    </>}
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>}
                                <div style={{padding:'0',marginTop:'-10px'}} className='col-md-12'><span class="card-title txt7">{product.price != 0 ? product.price : 'Договорная'}</span> <label class="card-title ml-2 txt7cur "><b>{product.currency}</b></label></div>
                                <div className='col-md-12'><p style={{fontSize:'12px'}} className="destext" >{product.descriptions}</p></div> 
                                <div class="col-12 col-md-12">
                                    <label style={{fontSize:'10px',float:'right',marginRight:'-15px'}} class="text-secondary">{moment.tz(product.date1, "Europe/Moscow").fromNow()}</label>
                                </div>
                                <div className='row '>
                                    <div className='col-6 '>
                                        <Button style={{width:'100%'}} danger onClick={()=>ad_get(product.id)}><i class="fa-solid fa-ban"></i></Button>
                                    </div>
                                    <div className='col-6'>
                                    <Button style={{width:'100%'}}  ghost type='primary' href={"/update_ad/"+ product.id}><i class="fa-solid fa-pencil"></i></Button>
                                    </div>
                                </div>
                        <div style={{marginTop:'-17px'}} class="card-body medbody">
                            <div  class="row d-block d-md-none ">
                           
                                
                             
                               
                        
                               
                            </div>
                          
                                    
                               
                                </div>
                                    
                               
                            
                           
                        
                    </div>

                    {/* <Post_moder product={item}/> */}
                </div>
                )}
            </>:<></>}
        {moderatsii != null || moderatsii != undefined || moderatsii ?.length > 0 ?
                <>
                
                {moderatsii.map((item)=>
                
                     <div   class="col-6 col-md-3 mt-3 d-none d-md-block">
                    <div  class="obv " >
                 
                                {products_click != null ? <>
                                    {products_click.filter(view => view.ad_id == item.id).map(filteredView => (
                                <a>
                                    {filteredView.click != null ? <><button  style={{opacity:'0.7',borderRadius:'15%',fontSize:'12px',zIndex:'1',position:'absolute',padding:'1px'}} type="button" class="btn btn-dark"><i class="fa-solid fa-eye"></i> {filteredView.click}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}

    <div style={{padding:'5px',marginTop:'-10px'}} class="card medbody">
    {products_category != null ? <>
                                    {products_category.filter(category => category.id == item.categories).map(filteredCategory => (
                                <a>
                                    {filteredCategory.name != null ? <><button  style={{opacity:'0.7',borderRadius:'30%',fontSize:'10px',zIndex:'1',position:'absolute',padding:'1px',float:'right',right:'0'}} type="button" class="btn btn-dark">{filteredCategory.name}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}
        <div class="row ">

                <a onClick={() => click(item.id)}>
                    <div style={{pointerEvents:'none'}}>
                        <Image style={{maxHeight:'200px',minHeight:'200px',pointerEvents:'none',borderTopLeftRadius:'5px',borderTopRightRadius:'5px'}}  class="cardimg" width='100%' src={item.images != null ? (uploads+ item.images) : (uploads+ 'no_image.jpg')}/>
                    </div>
                
                </a>
          
            
            <div class="col-md-12">
                <div className="row">
                <div class="col-md-12">
                    <label style={{marginTop:'0px'}} class="card-title  txt7">{item.price != 0 ? item.price : 'Договорная'}</label>
                    <label style={{marginTop:'0px',marginLeft:'10px',fontSize:'12px'}} class="card-title  ">{item.currency}</label>
                </div>
                <div class="col-md-12">
                    <p className='destext2'>{item.descriptions}</p>
                </div>
                <div class="col-md-auto ">
                {products_metro != null ? <>
                                    {products_metro.filter(metro => metro.id_sql == item.metro).map(filteredMetro => (
                                <a >
                                    {filteredMetro.color != 1 ? <><p style={{color:'gray',fontSize:'14px'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color}} /></p></> :
                                     <>
                                    <p style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='30px' height='30px' style={{borderRadius:'50%'}}></img></p>
                                    </>}
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>}
                </div>
                <div class="col-md-1">
                </div>
                <div class="col-md-3 meddate">
      <label style={{fontSize:'10px',float:'right',marginRight:'-15px'}} class="text-secondary meddate">{moment.tz(products_metro.date1, "Europe/Moscow").fromNow()}</label>
                </div>
                <div className='row'>
                    <div className='col-md-7'><Button  type="primary" ghost href={"/update_ad/"+ item.id}>Редактировать</Button></div>
                    <div className='col-md-5'><Button style={{padding:'1px'}} danger onClick={()=>ad_get(item.id)}>Удалить</Button></div>
                </div>
               {/* <div className='col-md-6 mt-2'>
                <Button  type="primary" ghost href={"/put_ad/"+ product.id}>Редактировать</Button>
               </div> */}
               <div className='col-md-6 mt-2'>
               {/* {status ?
              <>
                <Button style={{padding:'1px'}} danger onClick={iz_status2}>Деактивировать</Button>

              </>
              :<>
                <Button style={{padding:'1px'}} danger onClick={iz_status1}>Активировать</Button>

              </>
}         */}
                
               </div>
            
                </div>
                
                
                
            </div>

        </div>
    </div>
</div>  
                   
                </div>
                
               
                
                )
                }
                </>
                :<>
                <div className='ml-5'><label className='text-center'> Нет обьявлении на модерации ...</label></div>
                </>
            }
        </Tabs.TabPane>
        
    </Tabs>
    
    </Tabs.TabPane>

    {/* <Tabs.TabPane className='row' tab={tab4} key="4">
      Content of Tab Pane 2
    </Tabs.TabPane> */}

    {/* <Tabs.TabPane className='row' tab={tab5} key="5">
      Content of Tab Pane 2
    </Tabs.TabPane> */}

    {/* <Tabs.TabPane className='row' tab={tab6} key="6">
    
    </Tabs.TabPane> */}

    {/* <Tabs.TabPane className='row' tab={tab7} key="7">
      <div className='container'>
        <div className='row'>
            <div className='col-md-12'>
                <h2>Кошелек</h2>
            </div>
            <div className='col-md-6'>
                <h5>Кошелек - ваш персональный баланс на Birimdik.ru Пополняйте ваш Кошелек любым удобным способом и платите за услуги, не выходя из дома!</h5>
            </div>

            <div className='col-md-12'>
                <label style={{fontSize:'18px'}}>Выберите сумму пополнения:</label>
            </div>

            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>3000 KGS</label>
            </div>
            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>2000 KGS</label>
            </div>
            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>1000 KGS</label>
            </div>
            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>500 KGS</label>
            </div>
            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>300 KGS</label>
            </div>
            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>200 KGS</label>
            </div>
            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>150 KGS</label>
            </div>
            <div className='col-md-12'>
                <button className='btn btn-primary'>Пополнить</button>
            </div>
        </div>
      </div>
    </Tabs.TabPane> */}

  </Tabs>
                </div>
                
                <div className='row'>
                  
                
                </div>
                

                </div>
                
                
            </div>
      
        <div className='col-md-12 '>
<Footer/>
</div>
<div  className='d-sm-block d-md-none d-lg-none d-xl-none '>
                <Footer_menu />
            </div>
        </>
    :<>
    <h1 className='text-center mt-5'>Загрузка...</h1>
    </>
    
}

        
    </>
    
    );
    }
    
    
    export default Userinfo;