import axios from 'axios';
import { useEffect, useState } from 'react';
import Footer from './footer';
import Nav from './navbar';
import React from 'react';
import { Button, Modal, Radio, Tabs, message } from 'antd';
import { Checkbox, Form, Input } from 'antd';
import Post2 from './post2';
import { tab } from '@testing-library/user-event/dist/tab';
import Post_lk from './post_lk';
import { Select } from 'antd';
import { API_URL } from '../config/config';
import Footer_menu from './footer_menu';

const Put_user = ()=>{
  const [messageApi, contextHolder] = message.useMessage();
    const [product, setProduct] = useState(null);
    const [login,setLogin]= useState();
    const [password, setPassword] = useState();
    const [avatar,setAvatar] = useState();
    const [numbers, setNumbers] = useState();
    const [name,setName] = useState();
    const [surname,setSurname] = useState();
    const [city,setCity] = useState();
    const [image_name,setImage_name] = useState();
    const [usertoken, setUsertoken] = useState(null);
    const useridtoken = async () => {
      if(localStorage.getItem('token') != null){
          const post = {
              id: localStorage.getItem('token'),
          };
          const data = await axios({
              method: 'get',
              params: post,
              url: API_URL + '/users5'
          });
          // 
          if (data.data.status == 200) {
              
              setUsertoken(data.data.users5[0].id);
              fetchProduct(data.data.users5[0].id);
          }if(localStorage.getItem('g') != null){
              const post = {
                  id: localStorage.getItem('g'),
              };
              const data = await axios({
                  method: 'get',
                  params: post,
                  url: API_URL + '/users5'
              });
              // 
              if (data.data.status == 200) {
                  // 
                  setUsertoken(data.data.users5[0].id);
                  fetchProduct(data.data.users5[0].id);
              }
      }
          } 
    }
    const fetchProduct = async (user_id) => {
        const post = {
            id: user_id,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL+'/users1'
        });
        
        if (data.data.status == 200) {
            setProduct(data.data.users1);
            setLogin(data.data.users1[0].login);
            setPassword(data.data.users1[0].password);
            setAvatar(data.data.users1[0].avatar);
            setNumbers(data.data.users1[0].numbers);
            setName(data.data.users1[0].name);
            setSurname(data.data.users1[0].surname);
            setCity(data.data.users1[0].city);
            setImage_name(data.data.users1[0].avatar);
        } else {
            
        }
    }
    // const image_name = product[0].avatar;
    // const reg = async ()=>{
    //     const form = new FormData();
    //     form.append('login', login);
    //     form.append('password',password);
    //     form.append('avatar',avatar);
    //     form.append('numbers',numbers);
    //     form.append('name',name);
    //     form.append('surname',surname);
    //     form.append('city',city);
    //     form.append('user_id',usertoken);
    //     form.append('image_name',image_name);
    //     const data = await axios({
    //         method:'post',
    //         data:form,
    //         url:API_URL+'/put_avatar',
    //         headers: { "Content-Type": "multipart/form-data" } 
    //     });
    //     
    //     if(data != null){
    //         if(data.data.status  == 200){
    //         
    //         
    //         alert('data',data.status);
    //         }else{
    //             alert('Error');
    //         }
    //     }
    // }
    const put = async () => {
    if (usertoken != null) {
      const params = {
        login: login,
        password: password,
        numbers: numbers,
        name: name,
        surname: surname,
        city: city,
        user_id: usertoken
        
      }
      
      const data = await axios({
        method: 'post',
        params: params,
        url: API_URL + '/put_users4',
      });
      if (data != null) {
        if (data.data.status == 200) {
          success2();
          // alert('data',data.status)
          window.location.href = '/userinfo/'+1;
        } else {
          
          alert('Error')
        }
      }
    }
  }
   const success2 = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
    useEffect(() => {
        useridtoken();
    }, []);
    return(
        <>
         {contextHolder}
        <Nav/>
        {product != null || product != undefined || product?.length > 0 ?
    <>
    
        <div class="container card usercard"> 
                <div class="row mt-2">
    <h5>Изменить данные</h5>
                <div className='col-md-3 card  mt-2'>
                <div className='row'>
                
                      <div className='col-md-3'><img style={{width:'100px',height:'100px',borderRadius:'50%'}} src={'https://birimdik.kg/birimdik/uploads/' + product[0].avatar}></img></div>
                  
                      <div className='col-md-8 ml-3 mt-3'>
                        <p>{product[0].name}</p>
                        <b style={{color:'gold'}}>Подключить в бизнес аккаунт</b>
                        <p>ID: {product[0].id}</p>                        
                      </div>
                      {/* <input type={'file'} name="avatar" onChange={(e)=>{setAvatar(e.target.files[0])}} /> */}

                    <div className='col-md-12'>
                        <Button onClick={put}>Сохранить</Button>
                    </div>
                    </div>
                </div>
                
                <div className='col-md-8 ml-5 mt-5'>
                  <div className='row'>
                  <div style={{width:'200px'}} className=' card'>
                  <div className='row'>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Логин</p>
                     <Input onChange={(e) =>{setLogin(e.target.value)}} allowClear defaultValue={product[0].login} />
                  </div>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Пароль</p>
                    <Input onChange={(e) =>{setPassword(e.target.value)}} allowClear defaultValue={product[0].password} />
                  </div>

                  </div>
                </div>

                <div style={{width:'200px'}} className=' ml-3 card'>
                  <div className='row'>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Имя</p>
                    <Input onChange={(e) =>{setName(e.target.value)}} allowClear defaultValue={product[0].name} />
                  </div>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Фамилия</p>
                    <Input onChange={(e) =>{setSurname(e.target.value)}} allowClear defaultValue={product[0].surname} />
                  </div>

                  </div>
                </div>

                <div style={{width:'200px'}} className=' ml-3 card'>
                  <div className='row'>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Номер телефона</p>
                    <Input onChange={(e) =>{setNumbers(e.target.value)}} allowClear defaultValue={product[0].numbers} />
                  </div>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Город</p>
                    <Input onChange={(e) =>{setCity(e.target.value)}} allowClear defaultValue={product[0].city} />
                  </div>

                  </div>
                </div>

                <div style={{width:'200px'}} className=' ml-3 card'>
                  <div className='row'>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>E-mail</p>
                    <Input allowClear defaultValue='Не указано' />
                  </div>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Пол</p>
                    <Input allowClear defaultValue='Не указано' />
                  </div>

                 
                  </div>
                </div>
                
                  </div>
                </div>
                </div>
                </div>
           
</>
    :<>Loading</>
}
<div className='col-md-12 '>
<Footer/>
</div>
<div className='d-sm-block d-lg-none d-xl-none'>
                <Footer_menu />
            </div>
        
    </>
    
    );
    }
    
    
    export default Put_user;









// import axios from 'axios';
// import { useEffect, useState } from 'react';
// import Footer from './footer';
// import Nav from './navbar';
// import React from 'react';
// import { Button, Modal, Radio, Tabs } from 'antd';
// import { Checkbox, Form, Input } from 'antd';
// import Post2 from './post2';
// import { tab } from '@testing-library/user-event/dist/tab';
// import Post_lk from './post_lk';
// import { Select } from 'antd';
// import { API_URL } from '../config/config';
// const Put_user = ()=>{
//     const user_id = localStorage.getItem('token');
//     const [product, setProduct] = useState(null);
//     const [login,setLogin]= useState();
//     const [password, setPassword] = useState();
//     const [avatar,setAvatar] = useState();
//     const [numbers, setNumbers] = useState();
//     const [name,setName] = useState();
//     const [surname,setSurname] = useState();
//     const [city,setCity] = useState();
//     const [image_name,setImage_name] = useState();
//     const [uid_value,setUid_value] = useState();
//     const [google_id,setGoogle_id] = useState();
//     const uid = localStorage.getItem('uid');
//    
//     const fetchProduct = async () => {
//         const post = {
//             id: user_id,
//         };
//         const data = await axios({
//             method: 'get',
//             params: post,
//             url: API_URL+'/users1'
//         });
//         
//         if (data.data.status == 200) {
//             setProduct(data.data.users1);
//             setLogin(data.data.users1[0].login);
//             setPassword(data.data.users1[0].password);
//             setAvatar(data.data.users1[0].avatar);
//             setNumbers(data.data.users1[0].numbers);
//             setName(data.data.users1[0].name);
//             setSurname(data.data.users1[0].surname);
//             setCity(data.data.users1[0].city);
//             setImage_name(data.data.users1[0].avatar);
//             setUid_value(data.data.users1[0].uid);
//             setGoogle_id(data.data.users1[0].google_id);

//         } else {
//             

//         }   
//     }
//     // const image_name = product[0].avatar;
//     const reg = async ()=>{

//         const form = new FormData();
//         form.append('avatar',avatar);
//         form.append('user_id',user_id);
//         form.append('image_name',image_name);

//         const data = await axios({
//             method:'post',
//             data:form,
//             url:API_URL+'/put_avatar',
//             headers: { "Content-Type": "multipart/form-data" }
           
//         });
//         
//         if(data != null){
//             if(data.status  == 200){
//             
//             
//             alert('data',data.data.status)
//             }else{
//                 alert('Error')
//             }
//         }
//     }

//     useEffect(() => {

//         fetchProduct();
//     }, []) 
//     return(
//         <>
//         <Nav/>
//         {product != null || product != undefined || product?.length > 0 ?
//     <>
//         <div class="container card usercard"> 
//                 <div class="row mt-2">
//     <h5>Изменить данные</h5>
//                 <div className='col-md-3 card  mt-2'>
//                 <div className='row'>
                
//                       <div className='col-md-3'><img style={{width:'100px',height:'100px',borderRadius:'50%'}} src={'https://birimdik.kg/birimdik/uploads/' + product[0].avatar}></img></div>
                  
//                       <div className='col-md-8 ml-3 mt-3'>
//                         <p>{product[0].name}</p>
//                         <b style={{color:'gold'}}>Подключить в бизнес аккаунт</b>
//                         <p>ID: {product[0].id}</p>                        
//                       </div>
//                       <input type={'file'} name="avatar" onChange={(e)=>{setAvatar(e.target.files[0])}} />

//                     <div className='col-md-12'>
//                         <Button onClick={reg}>Сохранить</Button>
//                     </div>
//                     </div>
//                 </div>
                
//                 <div className='col-md-8 col-10 ml-3 mt-5'>
//                   <div className='row'>
//                   <div style={{width:'100%'}} className=' card'>
//                   <div className='row'>

//                   <div className='col-md-12 col-12'>
//                     <p style={{color:'grey'}}>Логин</p>
//                      <Input onChange={(e) =>{setLogin(e.target.value)}} allowClear defaultValue={product[0].login} />
//                   </div>

//                   <div className='col-md-12 col-12'>
//                     <p style={{color:'grey'}}>Пароль</p>
//                     <Input onChange={(e) =>{setPassword(e.target.value)}} allowClear defaultValue={product[0].password} />
//                   </div>

//                   </div>
//                 </div>

//                 <div style={{width:'100%'}} className='mt-3  card'>
//                   <div className='row'>

//                   <div className='col-md-12'>
//                     <p style={{color:'grey'}}>Имя</p>
//                     <Input onChange={(e) =>{setName(e.target.value)}} allowClear defaultValue={product[0].name} />
//                   </div>

//                   <div className='col-md-12'>
//                     <p style={{color:'grey'}}>Фамилия</p>
//                     <Input onChange={(e) =>{setSurname(e.target.value)}} allowClear defaultValue={product[0].surname} />
//                   </div>

//                   </div>
//                 </div>

//                 <div style={{width:'100%'}} className='mt-3  card'>
//                   <div className='row'>

//                   <div className='col-md-12'>
//                     <p style={{color:'grey'}}>Номер телефона</p>
//                     <Input onChange={(e) =>{setNumbers(e.target.value)}} allowClear defaultValue={product[0].numbers} />
//                   </div>

//                   <div className='col-md-12'>
//                     <p style={{color:'grey'}}>Город</p>
//                     <Input onChange={(e) =>{setCity(e.target.value)}} allowClear defaultValue={product[0].city} />
//                   </div>

//                   </div>
//                 </div>

//                 <div style={{width:'100%'}} className='mt-3
//                   card'>
//                   <div className='row'>

//                   <div className='col-md-12'>
//                     <p style={{color:'grey'}}>E-mail</p>
//                     <Input allowClear defaultValue='Не указано' />
//                   </div>

//                   <div className='col-md-12'>
//                     <p style={{color:'grey'}}>Пол</p>
//                     <Input allowClear defaultValue='Не указано' />
//                   </div>

                 
//                   </div>
//                 </div>
                
//                   </div>
//                 </div>
//                 </div>
//                 </div>
           
// </>
//     :<>Loading</>
// }
// <div className='col-md-12 '>
// <Footer/>
// </div>
        
        
//     </>
    
//     );
//     }
    
    
//     export default Put_user;