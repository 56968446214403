import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import './fonts/Dangrek-Regular.ttf';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
// import { StyledEngineProvider, CssVarsProvider } from '@mui/joy/styles';
import { StyledEngineProvider } from '@mui/material/styles';

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
