import axios from "axios";
import { useState } from "react";
import Footer from "./footer";
import Nav from "./navbar";
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  InputNumber,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Alert
  , Space
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import React from 'react';
import { auth } from "../firebase/firebase-config";
import { useEffect } from "react";
import firebase from "firebase";
// import GoogleLogin from "react-google-login";
import { GoogleLogin } from '@leecheuk/react-google-login'
import { gapi } from 'gapi-script';
import { API_URL, uploads } from '../config/config';
import Footer_menu from "./footer_menu";
import FormControl from '@mui/joy/FormControl';
import { IMaskInput } from 'react-imask';
import { Option } from "antd/lib/mentions";
import PropTypes from 'prop-types';
import Input from '@mui/joy/Input';
const clientId = '806199593777-9nsuk0sv1rroo6tkrmr5641u7e3td4fp.apps.googleusercontent.com';
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};
const TextMaskAdapter = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+7 000 000 00 00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});
const TextMaskAdapter2 = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+996 000 00 00 00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});
const TextMaskAdapter3 = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="############################"
      definitions={{
        '#': /[a-z,A-Z,0-9,(?=.*\&^%$#@!_+{}":?></|)]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});

const Password_new = () => {
  TextMaskAdapter.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  const [login, setLogin] = useState(null);
  const [password, setPassword] = useState();
  const [avatar, setAvatar] = useState();
  const [numbers, setNumbers] = useState(null);
  const [code, setCode] = useState();
  const [code2, setCode2] = useState(null);
  const [uid, setUid] = useState();
  const [uid2, setUid2] = useState();
  const [final, setFinal] = useState();
  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [city, setCity] = useState(null);
  const [style1, setStyle1] = useState(false);
  const [style2, setStyle2] = useState(false);
  const [product, setProduct] = useState(null);
  const [product2, setProduct2] = useState(null);
  const [logged, setLogged] = useState(false);
  const [google_id, setGoogle_id] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const local = localStorage.getItem('token');
  const Logged = () => {
    if (local != null) {
      setLogged(true);
    }
  }

  const [country, setCountry] = useState('1');
  const onChange = (value) => {
    setCountry(value);
    setNumbers(null);
  };

  const fetchProduct2 = async () => {
    const post = {
      id: numbers,
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL+'/users4'
    });
    
    if (data.data.status == 200) {
      register();

    } else {
      error3();
      
    }
  }

  const fetchProduct = async () => {
    const post = {
      id: numbers,
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL+'/users2'
    });
    

    if (data.data.status == 200) {
      setProduct2(data.data.users2);
      Logged();
      
      localStorage.setItem('token2', data.data.users2[0].id || product2[0].id);
      localStorage.setItem('token', data.data.users2[0].id || product2[0].id);
      
      window.location.href = 'registr2';

    } else {
      

    }
  }
  const fetchStyle2 = async () => {
    setStyle2(true);
    
  }

  const fetchProduct3 = async () => {
    const post = {
      id: login,
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL+'/users_login'
    });
    
    if (data.data.status == 200) {
      reg2();
      // alert('Такой логин существует');
    } else {
      
      // reg2();
    }

  }

  const fetchProduct4 = async () => {
    const post = {
      id: login,
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL+'/users_login'
    });
    
    if (data.data.status == 200) {
      setProduct2(data.data.users_login);
      
      localStorage.setItem('token2', data.data.users_login[0].id);
      localStorage.setItem('token', data.data.users_login[0].uid);
      localStorage.setItem('login', data.data.users_login[0].login);
      localStorage.setItem('g', data.data.users_login[0].google_id);
      
      Logged();
      window.location.href = 'registr2';

    } else {
      
    }

  }

  const register = async () => {
    auth.signInWithPhoneNumber(`${numbers}`, window.verify).then((result) => {
      success();
      
      setFinal(result);
      fetchStyle1();
    }).catch((error) => {
      
      
      error2();
    })
    setInterval(timer,1000);
  }

  const fetchStyle1 = async () => {
    setStyle1(true);
    setStyle2(true);
  }
  const confirmOtp = () => {
    if (code === null || final === null)
      return;
    final.confirm(code).then((result) =>{
      
      if (result.user.uid != null) {
        setUid(result.user.uid);
        setProduct(result);
        localStorage.setItem('number', numbers);
        localStorage.setItem('uid', result.user.uid);
        reg();
        
      }
    }).catch((err) => {
      
    })
  }
  

  const responseGoogle = async (response) => {
    setGoogle_id(response.profileObj.googleId);
      setLogin(response.profileObj.email);
      setName(response.profileObj.name);
      setSurname(response.profileObj.familyName);
    
    const email = response.profileObj.email;
    const name = response.profileObj.name;
    const uid2 = response.profileObj.googleId;
    
    
    if (response != null) {
        fetchProduct3();
      // setAvatar(response.profileObj.imageUrl);
      
    }
  }
  const onFailure = (response) => {
    
  }

  const reg2 = async () => {
    const params = {
      'login' : login,
      'password' : password,
      'numbers' :numbers,
      'name' : name,
      'surname' : surname,
      'city' : city,
      'uid' : uid,
      'google_id' :google_id,
    }
    
    const data = await axios({
      method: 'post',
      params: params,
      url: API_URL+'/registr',
      // headers: { "Content-Type": "multipart/form-data" }
    });
    
    if (data != null) {
      if (data.status == 200) {
        fetchProduct4();
        
      } else {
        alert('fetchproduct4 не запущен!')
      }

    }
  }


  const reg = async () => {
    const params = {
      'login' : login,
      'password' : password,
      'numbers' : code2 + numbers,
      'name' : name,
      'surname' : surname,
      'city' : city,
      'uid' : uid,
      'google_id' :google_id,
    }
    const data = await axios({
      method: 'post',
      params: params,
      url: API_URL+'/registr_form',
    });
    
    
      if (data.status == 200) {
        success()
        fetchProduct();
      } else {
        alert('fetchproduct не запущен!')
      }
  }

  useEffect(() => {
    window.verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    window.verify.render();
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: ""
      })
    };
    gapi.load('client:auth2', start);
  }, [])
  
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
  const success2 = () => {
    messageApi.open({
      type: 'success',
      content: '!',
    });
  };
  
  const error2 = () => {
    messageApi.open({
      type: 'error',
      content: 'Слишком много попыток повторите позже! или напишите в службу поддержки',
    });
  };
  const error3 = () => {
    messageApi.open({
      type: 'error',
      content: 'Такой номер не зарегистрирован!',
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'This is a warning message',
    });
  };
  var t=1;
  var m=59000;
  var min = m/m;
  function timer(){
    if(t>0){
      t=t-1;
      document.getElementById("a1").innerHTML=""+min+":"+t;
    }
    else if(m>0){
      m=m-1000;
      min=m/1000;
      document.getElementById("a1").innerHTML=""+t+":"+min;
    }
    else if(m==0){
      setStyle1(false);
      setStyle2(false);
      clearInterval(timer,1000);
      window.location.href='/password_new'
    }
  }
  
  
  
  return (
    <>
{contextHolder}
		<body  className="body">

    
      <div style={{paddingRight:'0'}} className="container ">
      <Nav />
        <div class="row ">
    
       
 
          <div style={{paddingRight:'0'}}  class="row ">
			<div  class="col-md-12 bg-white" >
				<div  class="row bg-white" >

        <div className="col-md-4"></div>
					<div  class="col-md-4 mt-5 card">
						<div  class="row ">
							<div class="col-md-12 text-center">
							<b class="a3">Birimdik</b>
              <p><b>Восстановления</b></p>
							</div>

							<div class="col-md-12"  id="aa">
								<div class="row">
                <div class="col-md-3  col-4">
                  <Select
                      onChange={onChange}
                      defaultValue={'1'}
                      style={{
                        marginLeft: '17px',
                        width:'100%',
                        height:'40px'
                      }}
                    >
                      <Option value={'2'}><img width='40px' height='40px'  src={uploads+'flag_kg.png'}></img>+996</Option>
                      <Option value={'1'}><img width='40px' height='40px'  src={uploads+'flag_rus.jpg'}></img>+7</Option>
                      <Option value={'3'}><img width='40px' height='40px'  src={uploads+'gmail.png'}></img>@gmail</Option>
                    </Select>
                  </div>
									<div class="col-md-8 col-8 ">
                    {country == '1' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="+7 *** *** ** **"
                      slotProps={{ input: { component: TextMaskAdapter } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                    {country == '2' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="+996 *** ** ** **"
                      slotProps={{ input: { component: TextMaskAdapter2 } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                    {country == '3' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="********** @gmail.com"
                      slotProps={{ input: { component: TextMaskAdapter3 } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                  
										{/* <Input   onChange={(e) => { setNumbers(e.target.value) }} type="text" id="numbers"  placeholder="Номер телефона" class="form-control inp" name=""/> */}
								</div>
									<div class="col-md-1"></div>
								
								<div class="col-md-1 col-1"></div>
								<div class="col-md-12 col-10 mt-2">
                {style2 ?
                 <>
                 </>: 
                <>
                <div  style={{float:'right'}} id="recaptcha-container"></div>
                </>}
                
									{/* <input onChange={(e) =>{setPassword(e.target.value)}} type="password" id="password" placeholder="Пароль" class="form-control" name=""/> */}
								</div>

								<div class="col-md-1"></div>
								<div class="col-md-3 col-3"></div>
								<div class="col-md-8 col-6 mt-2">
                {style2 ?
                 <>
                 </>: 
                <>
                <button class="form-control btn-primary" onClick={fetchProduct2}>ОК</button>
                </>}
								
								</div>
								

							
                 
                    
                        

                {style1 ?
                      <>
                      <div className="col-md-3 col-4"></div>
                      <div className="col-md-6 col-4 mt-2">
                      <Input placeholder="Код из СМС" onChange={(e) => { setCode(e.target.value) }} />
                      </div>
                      <div className="col-md-4 col-4"></div>
                      <div  className="col-4"></div>
                      <div className="col-md-4 col-4 mt-3">
                          <Form.Item >
                          <div class="col-md-12" id="a1"></div>
                            <Button style={{width:'100%'}} type="secondary" onClick={confirmOtp} >OK</Button>
                          </Form.Item>
                          
                        </div>
                      </>
                      : <>
                      </>
                    }
                    	<div class="col-md-2"></div>
								<div class="col-md-10 mt-2 text-center">
                  {/* <GoogleLogin
                      
                      clientId={clientId}
                      buttonText="Войти через Google"
                      onSuccess={responseGoogle}
                      // onClick={responseGoogle}
                      onFailure={onFailure}
                      cookiePolicy={'single_host_origin'}
                      isSignedIn={false}
                    /> */}
								</div>
								<div class="col-md-2"></div>

								<div class="col-md-2"></div>
								
								<div class="col-md-2"></div>
						
          
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4"></div>
          <div style={{ marginTop: '100px' }}>
            <Footer />
          </div>
			</div>
			</div>

			
		</div>
       

          

        

        </div>
      </div>
      </body>
      <div className='d-sm-block d-lg-none d-xl-none'>
                <Footer_menu />
            </div>
    </>
  )
}
export default Password_new;