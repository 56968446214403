import React,{ Component, useEffect } from 'react';
import{BrowserRouter as Router,Routes,Route, useParams} from 'react-router-dom';
import { Button, Modal, Radio, Tabs } from 'antd';
import  {useState} from 'react'
import { AudioOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import axios from 'axios';
import { clear } from '@testing-library/user-event/dist/clear';

const { Search } = Input;


const Search1 =()=>{
  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: 'green',
      }}
    />
  
  );

  const [text, setText] = useState(localStorage.getItem('text'));
  const Text = () => {
        window.location.href="/result";
        localStorage.setItem('text',text);
}

return(
  
    <>
    <div  className='col-md-12 medsearch'>
         <Space  style={{width:'100%',}} direction="vertical">
    <Search 
      id='search'
      placeholder={text != null ? 'Вы искали:' + text: 'Вы искали:  '}
      enterButton="Поиск"
      size='large'
      defaultValue={text != null ? text : ''}
      onChange={(e) =>{setText(e.target.value)}}
      suffix={suffix}
      onSearch={Text}
      
    />
  </Space>
            
            </div>         
    </>
    
)
}


export default Search1;