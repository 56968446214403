
import React, { useEffect ,useState} from 'react';
import 'flowbite';
const Footer_menu =()=>{
    const user_id = localStorage.getItem('token');
    const [logged,setLogged]=useState(false);
    const [local,setLocal]=useState(localStorage.getItem('token'));
    const [local2,setLocal2]=useState(localStorage.getItem('g'));
   const Logged = ()=>{
    if(local != null || local2 != null){
      setLogged(true);
    }
   }
   useEffect(() => {
    Logged();
}, [])
    return(

       <div className='container '>
<div style={{width:'105%',backgroundColor:'aliceblue'}} class="row foot_mob">
   
<div className='col-3 text-center'>
<a href='/'>
<button style={{borderRadius:'50%',width:'50px',height:'50px',marginTop:'5px'}} type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800  "><i style={{fontSize:'24px'}} class="fa-solid fa-house"></i></button>
</a>
</div>

<div className='col-3 text-center'>
{logged ?<><a href={'/upload1/'+ 0}>
<button style={{borderRadius:'50%',width:'50px',height:'50px',marginTop:'5px'}} type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800  "><i style={{fontSize:'24px'}} class="fa-solid fa-plus"></i></button>
</a></>:<><a href={'/vhod'}>
<button style={{borderRadius:'50%',width:'50px',height:'50px',marginTop:'5px'}} type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800  "><i style={{fontSize:'24px'}} class="fa-solid fa-plus"></i></button>
</a></>}

</div>
<div className='col-3 text-center'>
{logged ?<> <a  href={'/korzina/'}>
    <button style={{borderRadius:'50%',width:'50px',height:'50px',marginTop:'5px'}} type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800  "><i style={{fontSize:'24px'}} class="fa-solid fa-heart" ></i></button>
    </a></>:<> <a href={'/vhod'}>
    <button style={{borderRadius:'50%',width:'50px',height:'50px',marginTop:'5px'}} type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800  "><i style={{fontSize:'24px'}} class="fa-solid fa-heart" ></i></button>
    </a></>}
   
</div>
<div className='col-3 text-center'>
{logged ?<><a href={'/userinfo/'+ 3}>
<button style={{borderRadius:'50%',width:'50px',height:'50px',marginTop:'5px'}} type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800  "><i style={{fontSize:'24px'}} class="fa-solid fa-user" ></i></button>
</a></>:<><a href={'/vhod'}>
<button style={{borderRadius:'50%',width:'50px',height:'50px',marginTop:'5px'}} type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800  "><i style={{fontSize:'24px'}} class="fa-solid fa-user" ></i></button>
</a></>}

</div>

</div>
</div>
        

    )
    }
    
    
    export default Footer_menu;
    





