import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { Button, Modal, Radio, Spin, Tabs , Space } from 'antd';
import { message, Upload } from 'antd';
import { AudioOutlined, MediumCircleFilled } from '@ant-design/icons';
import { Image } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLikes } from '../redux/actions/like_actions';
import moment from 'moment-timezone';
import { API_URL, uploads } from '../config/config';
import { setProducts } from '../redux/actions/products_actions ';
import { Cloud } from '@mui/icons-material';
import { Box, Chip } from '@mui/joy';
const Post2 = ({ product }) => {

    
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    // const user_id = localStorage.getItem('token');
    const { likes } = useSelector((state) => state.likes);
    const { products } = useSelector((state) => state.products);
    const [favorited, setFavorited] = useState(null);
    const [product3, setProduct3] = useState(null);
    const [product4, setProduct4] = useState(null);
    const [message1, setMessage1] = useState(null);
    const limit2 = localStorage.getItem('limit');
    const [limit, setLimit] = useState(localStorage.getItem('limit'));
    // 
    // 
    // const [products, setProducts] = useState(null);
    const [logged, setLogged] = useState(false);
    const [ad_id, setAd_id] = useState(null);
    const [user2_id, setUser2_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [usertoken, setUsertoken] = useState(null);
    const useridtoken = async () => {
        if(localStorage.getItem('token') != null){
            const post = {
                id: localStorage.getItem('token'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            // 
            if (data.data.status == 200) {
                
                setUsertoken(data.data.users5[0].id);
                Logged(data.data.users5[0].id);
            }if(localStorage.getItem('g') != null){
                const post = {
                    id: localStorage.getItem('g'),
                };
                const data = await axios({
                    method: 'get',
                    params: post,
                    url: API_URL + '/users5'
                });
                // 
                if (data.data.status == 200) {
                    // 
                    setUsertoken(data.data.users5[0].id);
                    Logged(data.data.users5[0].id);
                }
        }
            } 
      }
    
    const Logged = (usertoken) => {
        if (usertoken != null) {
            // 
            setUsertoken(usertoken);
            setLogged(true);
        }
    }
    const [products_metro, setProducts_metro] = useState();
const fetchProducts_metro2 = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/getmetro'
});
if (data.data.status == 200){
  setProducts_metro(data.data.getmetro);
//   
} else {
  
}
}
const [products_click, setProducts_click] = useState(null);
const fetchProducts_click = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/getclick'
});
// 
if (data.data.status == 200){
  setProducts_click(data.data.getview);
//   
} else {
  
}
}
const [products_category, setProducts_category] = useState(null);
const fetchProducts_category = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/cat_get'
});
// 
if (data.data.status == 200){
  setProducts_category(data.data.cat_get);
//   
} else {
  
}
}
    const fetchProduct3 = async () => {
        const data = await axios({
            method: 'get',
            url: API_URL +'/users_get'
        });
        if (data.data.status == 200) {
            setProduct3(data.data.users_get);
            // 
        } else {
            
        }
    }
    
    const fetchProducts = async () => {
        const params={
             id : limit
         };
         const data = await axios({
             method:'get',
             params: params,
             url:API_URL+'/client1get'
         });
         if(data.data.status == 200){
             dispatch(setProducts(data.data.limit));
             setLimit( limit + 4);
             
         }else{
             
             
         }
     }


const chat_if = async () => {
    if (logged == true) {
        const params = {
            ad_id: ad_id,
            user1_id: usertoken,
            user2_id: user2_id,
        };
        const data = await axios({
            method: 'get',
            params: params,
            url: API_URL + '/chat_if'
        });
        
        if (data.data.status == 200){
            chat(data.data.chat_if[0].ad_id,data.data.chat_if[0].id);
        } else {
           error();
            
            chats();
            // window.location.href="/mainsms/";
            
        }
    } else {
       warning();
    }

}
    
    const chats = async ()=>{
        const params = {
            ad_id:ad_id,
            user1_id:usertoken,
            user2_id:user2_id,
        };
        const data = await axios({
            method:'post',
            params:params,
            url:API_URL+'/chats'
        });
        
        if(data != null){
            if(data.status  == 200){
            
            // fetchProducts();
            localStorage.setItem('chat',message1);
            setTimeout(chat_if,2000);
            // window.location.href="/mainsms/"+product[0].id;
            }else{
                alert('Error')
            }
        }
    }
    
    const chat = async (ad_id,room_id) => {
        if(usertoken != null){
            const params = {
                ad_id: ad_id,
                user_id: usertoken,
                message: message1,
                room_id: room_id,
                user_id2: user2_id
            };
            const data = await axios({
                method: 'post',
                params: params,
                url: API_URL + '/chat'
            });
            
            if (data != null){
                if (data.status == 200){
                    chats_date(room_id);
                    success();
                } else {
                    alert('Error')
                }
            }
        }
    }
    const chats_date = async (room_id)=>{
        const params = {
            room_id:room_id
        };
        const data = await axios({
            method:'put',
            params:params,
            url:API_URL+'/chats'
        });
        
        if(data != null){
            if(data.data.status  == 200){
            
            }else{
                alert('Error')
            }
        }
    }
    const fetchProducts2 = async (usertoken) => {
        if(usertoken != null){
            const post = {
                user_id: usertoken,
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL+'/get_favorites'
            });
            
            if (data.data.status == 200) {
                dispatch(setLikes(data.data.favorites));
                
            } else {
                
                
            }
        }
       
      }
      const iffavorite = async (ad_id) => {
        if(usertoken != null){
            const params = {
                ad_id: ad_id,
                user_id: usertoken,
            };
            const data = await axios({
                method: 'get',
                params: params,
                url: API_URL+'/favorite'
            });
            
            if (data != null) {
                if (data.data.status == 200) {
                    
                    success3();
                } else {
                    // addfavorites(ad_id);
                }
            }
        }else{
            warning();
        }
        
    }

    const addfavorites = async (ad_id) => {
        if(usertoken != null){
            const params = {
                ad_id: ad_id,
                user_id: usertoken,
            };
            const data = await axios({
                method: 'post',
                params: params,
                url: API_URL+'/favorites'
            });
            
            if (data != null) {
                if (data.data.status == 200) {
                    
                    setFavorited(true);
                    success3();
                    fetchProducts2(usertoken); 
                } else {
  
                }
            }
        }else{
            warning();
        }
        
    }
                
    const delfavorites = async (ad_id) => {
        if(usertoken != null){
            const params = {
                ad_id: ad_id,
                user_id: usertoken,
            };
            
            const data = await axios({
                method: 'delete',
                params: params,
                url: API_URL+'/delete_favorites'
            });
            
            if (data != null) {
                if (data.data.status == 200) {
                    
                    setFavorited(false);
                    success4();
                    fetchProducts2(usertoken);
                    
                } else {
                }
            }
        }
        
    }

    

    const fetchProduct4 = async () => {
        const post = {
            id: product.userid,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL +'/users1'
        });
        // 
        if (data.data.status == 200) {
            setProduct4(data.data.users1);
            // 
        } else {
            
            
        }
    }
    


    const checkLikes = (id) => {
        // 
        const check = likes.filter(i => i.ad_id == id);
        if (check.length > 0) {
            setFavorited(true);
            return true;
        } else {
            setFavorited(false);
            return false;
        }
    }
    if (favorited == null) { 
            checkLikes(product.id);
    }
    
const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Сообщение отправлено!',
    });
  };
  const success2 = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
  const success3 = () => {
    messageApi.open({
      type: 'success',
      content: 'Добавлено в избранное!',
    });
  };
  const success4 = () => {
    messageApi.open({
      type: 'success',
      content: 'Удалено из избранное!',
    });
  };
 
  const error = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
  };

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Авторизуйтесь!',
    });
}
// const [products_category, setProducts_category] = useState(null);
// const fetchProducts_category = async () => {
// const data = await axios({
//   method: 'get',
//   url: API_URL + '/cat_get'
// });
// if (data.data.status == 200){
//   setProducts_category(data.data.cat_get);
//   
// } else {
//   
// }
// }



    useEffect(() => {
        useridtoken(); 
        fetchProducts_category(); 
            fetchProducts_click();
            fetchProduct3();
            Logged();
            fetchProduct4();
            fetchProducts_metro2();  
    }, []);

    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clicks, setClicks] = useState(false);
    const showModal = (id,user2_id) => {
        setAd_id(id);
        setUser2_id(user2_id);
      setIsModalOpen(true);
      
    };
    // 
    const handleOk = () => {
        chat_if();
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );
   
   
      const [searchItem, setSearchItem] = useState('')
     

      const handleInputChange = (e) => { 
        const searchTerm = e.target.value;
        setSearchItem(searchTerm)
      }
      

     
      const click = async (ad_id) => {
        if (ad_id != null) {
            const params = {
                ad_id: ad_id,
            };
            const data = await axios({
                method: 'get',
                params: params,
                url: API_URL + '/click_post'
            });
            
            if (data.data.status == 200){
                add_click(ad_id);
                // setClicks(data.data.click_post);
                
            } else {
               new_click(ad_id);
            }
        } else {
        }
    }
    const add_click = async (ad_id) => {
        const params = {
            ad_id: ad_id
        };
        const data = await axios({
            method: 'put',
            params: params,
            url: API_URL + '/click_post'
        });
        
        if (data != null){
            if (data.status == 200){
                window.location.href='/post/'+ad_id
            } else {
            }
        }
    }
    const new_click = async (ad_id) => {
        const params = {
            ad_id: ad_id,
            click: 1
        };
        const data = await axios({
            method: 'post',
            params: params,
            url: API_URL + '/click_post'
        });
        
        if (data != null){
            if (data.status == 200){
                window.location.href='/post/'+ad_id
            } else {
            }
        }
    }
   
     
    return (
        <>
       <div>
  
</div>
 {contextHolder}
 <div>
 
</div>
      <Modal  title="Отправить сообщение" open={isModalOpen} onOk={handleOk} okText='Отправить' cancelText="Отмена" onCancel={handleCancel}>
      <textarea onChange={(e) => { setMessage1(e.target.value) }} style={{height:'100px'}} className="form-control"></textarea>
      </Modal>
         
            {product != null || product != undefined || product ?.length > 0 ?
                <>
                                {/* {products_category != null ? <>
                                    {products_category.filter(category => category.id == product.categories).map(filteredCategory => (
                                <a >
                                    <label className=' medname'  style={{fontSize:'16px',fontFamily:'revert-layer',marginLeft:'10px'}}>{filteredCategory.name}</label>
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>} */}
                                
                    <div style={{width:'100%',marginLeft:'0px'}}  class="card obv" >
                    {products_click != null ? <>
                                    {products_click.filter(view => view.ad_id == product.id).map(filteredView => (
                                <a>
                                    {filteredView.click != null ? <><button  style={{opacity:'0.7',borderRadius:'15%',fontSize:'10px',zIndex:'1',position:'absolute',padding:'1px'}} type="button" class="btn btn-dark"><i class="fa-solid fa-eye"></i> {filteredView.click}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}
                                {products_category != null ? <>
                                    {products_category.filter(category => category.id == product.categories).map(filteredCategory => (
                                <a>
                                    {filteredCategory.name != null ? <><button  style={{opacity:'0.7',borderRadius:'30%',fontSize:'10px',zIndex:'1',position:'absolute',padding:'1px',float:'right',right:'0'}} type="button" class="btn btn-dark">{filteredCategory.name}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}
                        <a onClick={() => click(product.id)} className='postimage2'>
                            <div style={{pointerEvents:'none'}}>
                                <Image  className='postimage2' style={{width:'100%',height:'180px',pointerEvents:'none'}}  src={product.images != null ? (uploads + product.images) : (uploads + 'no_image.jpg')} />
                            </div>
                             </a>
                        <a onClick={() => click(product.id)} className='postimage' >
                        <div style={{pointerEvents:'none'}}>
                            <Image className='postimage' style={{width: '100%',height:'140px',borderTopLeftRadius:'5px',borderTopRightRadius:'5px',pointerEvents:'none'}}  src={product.images != null ? (uploads + product.images) : (uploads + 'no_image.jpg')} /> 
                        </div>
                        </a>
                        <div  style={{marginTop:'-17px'}} class="card-body medbody">

                            <div class="row d-none d-md-flex">

                                <div className='col-md-12'><span title='price' class="card-title txt7">{product.price != 0 ? product.price : 'Договорная'}</span> <label class="card-title ml-2 txt7cur "><b>{product.currency}</b></label></div>
                             
                                <div className='col-md-12'><p className="destext" >{product.descriptions}</p></div>
                            
                                {/* <div className='col-md-7 col-6'>{Metro(product.metro)}</div> */}
                           
                              <div className='row'>
                                <div className='col-md-8'>
                                {products_metro != null ? <>
                                    {products_metro.filter(metro => metro.id_sql == product.metro).map(filteredMetro => (
                                <a >
                                    {filteredMetro.color != 1 ? <><p style={{color:'gray'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'22px'}} /></p></> :
                                     <>
                                    <p style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='30px' height='30px' style={{borderRadius:'50%'}}></img></p>
                                    </>}
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>}
                                </div>
                                
                                <div className='col-md-4 col-6'><label style={{ fontSize: '10px', float: 'right', marginRight: '-15px',color:'black' }} class="text-secondary meddate">{moment.tz(product.date1, "Europe/Moscow").fromNow()}</label></div>
                                
                               
                              </div>
                               
                               
                                <div className='row'>
                                <div className='userimg4 col-md-6'>
                                {product3 != null ? <>
                                        <div>
                                {product3.filter(product3 => product3.id == product.userid).map(filteredAvatar => (
                                    <a href={'/user/' + filteredAvatar.id}>{filteredAvatar.avatar != null ? <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads + filteredAvatar.avatar}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads + 'user.jpg'}></img></>}</a>
                                ))}
                                </div>
                                    </> : <></>}

                                </div>
                                 <div  className='col-md-6  '>
                                    {favorited ?
                                    <>
                                        <a className='ml-5' > <label class="text-secondary postsms"> <i  type="primary" onClick={() => showModal(product.id,product.userid)}  class="fa-solid fa-envelope  sms2"></i> <i onClick={() => delfavorites(product.id)} class=" fa-solid fa-heart   heart2"></i></label></a>
                                    </>
                                    : <>
                                        <a className='ml-5'>  <label class="text-secondary postsms"> <i type="primary" onClick={() => showModal(product.id,product.userid)}  class="fa-solid fa-envelope  sms2"></i> <i onClick={() => addfavorites(product.id)} class=" fa-solid fa-heart   heart"></i></label></a>
                                    </>
                                    }
                                </div>

                                </div>
                            
                               

                            </div>


                            <div  class="row d-block d-md-none ">

                                <div className='col-md-12'><span class="card-title txt7">{product.price != 0 ? product.price : 'Договорная'}</span> <label class="card-title ml-2 txt7cur "><b>{product.currency}</b></label></div>
                             
                                <div className='col-md-12'><p style={{fontSize:'12px'}} className="destext" > {product.descriptions}</p></div>
                            
                                {/* <div className='col-md-7 col-6'><p style={{fontSize:'12px',marginTop:'15px'}} className='location_text'><i class="fa-sharp fa-solid fa-map-location-dot"></i> | </p></div> */}
                            
                                {/* <div className='col-md-5 col-6'><label style={{ fontSize: '10px', float: 'right', marginRight: '-15px' }} class="text-secondary meddate">{moment(product.date1, "YYYY-MM-DD HH:mm:ss").fromNow()}</label></div> */}

                                {/* <div className='col-md-6'><a href={'/user/' + product.userid}><img style={{ width: '30px', height: '30px', borderRadius: '50%',marginTop:'0px'}} src={product3[0].avatar != null ? uploads + product3[0].avatar : "https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></a></div> */}
                               
                               
                                
                                

                            </div>
                            <div className='d-block d-md-none'>
                            {products_metro != null ? <>
                                    {products_metro.filter(metro => metro.id_sql == product.metro).map(filteredMetro => (
                                <a >
                                    {filteredMetro.color != 1 ? <><p style={{color:'gray',fontSize:'14px'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'14px'}} /></p></> :
                                      
                                     <>
                                    <p style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='30px' height='30px' style={{borderRadius:'50%'}}></img></p>
                                    </>}
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>}
                            </div >
                            
                            <div className='row'>
                            <div className='col-4 d-block d-md-none'>
                            {product3 != null ? <>
                                        <div>
                                {product3.filter(product3 => product3.id == product.userid).map(filteredAvatar => (
                                    <a href={'/user/' + filteredAvatar.id}>{filteredAvatar.avatar != null ? <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads + filteredAvatar.avatar}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads + 'user.jpg'}></img></>}</a>
                                ))}
                                </div>
                                    </> : <></>}
                                    {/* {product4 != null || product4 != undefined || product4?.length > 0 ?
                                    <>
                                        <div className='userimg3'><a href={'/user/' + product.userid}><img style={{ width: '25px', height: '25px', borderRadius: '50%', marginLeft: '-10px', marginTop: '0px' }} src={product4[0].avatar != null ? uploads + product4[0].avatar : uploads + 'nouser.png'}></img></a></div>
                                        <div className='userimg4'><a href={'/user/' + product.userid}><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '0px' }} src={product4[0].avatar != null ? uploads + product4[0].avatar : uploads + 'nouser.png'}></img></a></div>
                                    </>
                                    : <>
                                    </>
                                } */}

                                    </div>

                                    <div className='col-8 d-block d-md-none'>
                                    {favorited ?
                                    <>
                                        <a style={{float:'right'}}> <label class="text-secondary postsms"> <i onClick={() => showModal(product.id,product.userid)} type="button" data-toggle="modal" data-target="#exampleModal3" class="fa-solid fa-envelope  sms2"></i> <i onClick={() => delfavorites(product.id)} class=" fa-solid fa-heart   heart2"></i></label></a>
                                    </>
                                    : <>
                                        <a style={{float:'right'}}>  <label class="text-secondary postsms"> <i onClick={() => showModal(product.id,product.userid)} type="button" data-toggle="modal" data-target="#exampleModal3" class="fa-solid fa-envelope  sms2"></i> <i onClick={() => addfavorites(product.id)} class=" fa-solid fa-heart   heart"></i></label></a>
                                    </>
                                    }
                                    </div>
                            </div>
                            
                                
                                    
                               
                                </div>
                        
                        
                           <div className='medbody2'>
                            <div class="row d-block d-sm-none d-md-none ">

                                <div style={{marginTop:'-10px'}} className='col-md-12 col-12'><span class="card-title " style={{fontFamily:'',fontSize:'16px'}}><b>{product.price != 0 ? product.price : 'Договорная'}</b></span> <label style={{fontSize:'8px'}} class="card-title ml-2 txt7cur "><b>{product.currency}</b></label></div>
                                {/* {products_metro != null ? <>
                                    {products_metro.filter(metro => metro.id_sql == product.metro).map(filteredMetro => (
                                <a  >
                                    {filteredMetro.color != 1 ? <><a style={{color:'gray'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'12px'}} /></a></> :
                                     <>
                                    <a style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='30px' height='30px' style={{borderRadius:'50%'}}></img></a>
                                    </>}
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>} */}
                                <div className='col-md-12 col-12'><p style={{fontSize:'12px'}} className="destext" >{product.descriptions}</p></div>
                            
                                {/* <div className='col-md-7 col-6'><p style={{fontSize:'12px',marginTop:'15px'}} className='location_text'><i class="fa-sharp fa-solid fa-map-location-dot"></i> | </p></div> */}
                            
                                {/* <div className='col-md-5 col-6'><label style={{ fontSize: '10px', float: 'right', marginRight: '-15px' }} class="text-secondary meddate">{moment(product.date1, "YYYY-MM-DD HH:mm:ss").fromNow()}</label></div> */}

                                {/* <div className='col-md-6 col-6'><a href={'/user/' + product.userid}><img style={{ width: '25px', height: '25px', borderRadius: '50%',marginTop:'-25px'}} src={product3[0].avatar != null ? uploads + product3[0].avatar : "https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></a></div> */}
                                
                             
                                <div style={{width:'100%'}} className='row'>
                                <div className='col-8'>
                                {products_metro != null ? <>
                                    {products_metro.filter(metro => metro.id_sql == product.metro).map(filteredMetro => (
                                <a >
                                    {filteredMetro.color != 1 ? <><p style={{color:'gray',fontSize:'10px'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'12px'}} /></p></> :
                                     <>
                                    <p style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='20px' height='20px' style={{borderRadius:'50%'}}></img></p>
                                    </>}
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>}
                                </div>
                                
                                {/* {product4 != null || product4 != undefined || product4?.length > 0 ?
                                    <>
                                        <div className='userimg3 col-6'><a href={'/user/' + product.userid}><img style={{ width: '25px', height: '25px', borderRadius: '50%', }} src={product4[0].avatar != null ? uploads + product4[0].avatar : uploads + 'nouser.png'}></img></a></div>
                                        <div className='userimg4 col-6'><a href={'/user/' + product.userid}><img style={{ width: '30px', height: '30px', borderRadius: '50%', }} src={product4[0].avatar != null ? uploads + product4[0].avatar : uploads + 'nouser.png'}></img></a></div>
                                    </>
                                    : <>
                                    </>
                                } */}
                                <div style={{padding:0}} className='col-md-6 col-4'>
                                    {favorited ?
                                    <>
                                        <a> <label class="text-secondary postsms"> <i onClick={() => showModal(product.id,product.userid)} type="button" data-toggle="modal" data-target="#exampleModal" class="fa-solid fa-envelope  sms2"></i> <i onClick={() => delfavorites(product.id)} class=" fa-solid fa-heart   heart2"></i></label></a>
                                    </>
                                    : <>
                                        <a>  <label class="text-secondary postsms"> <i onClick={() => showModal(product.id,product.userid)} type="button" data-toggle="modal" data-target="#exampleModal" class="fa-solid fa-envelope  sms2"></i> <i onClick={() => addfavorites(product.id)} class=" fa-solid fa-heart   heart"></i></label></a>

                                    </>
                                    }
                                </div>
                                </div>
                                
                                

                                </div>
                                
                        </div>
                        
                    </div>

                </>
                : <>Loading</>
            }





        </>
        
    );
}
export default Post2;