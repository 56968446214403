
import React from 'react';
const Catalog =()=>{
    return(

       
<div title='catalog' style={{backgroundColor:'#1E90FF',marginTop:'0%'}} class="row ">

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/meimankana'}>
    Мейманкана</a>
    </h5>
</div>
<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/kvartirant_alam'}>
    Квартирант алам</a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/komnata_berem'}>
    Комната берем</a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/kvartira_berilet'}>
    Квартира берилет</a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/koika'}>
    Койка место</a>
    </h5>
</div>


<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/komnata_izdeim'}>
    Комната издейм</a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/taksopark'}>
    Таксопарктар</a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/jumush_izdeim'}>
    Жумуш издейм</a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/jumush_berilet'}>
    Жумуш берилет</a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/medical'}>
    Медицина</a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/zapchasti'}>
    Авто запчасти</a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/arenda_avto'}>
    Аренда авто
    </a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/obuvi'}>
    Обувь</a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/odejda_new'}>
    Одежда</a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/lichnye_veshi'}>
    Личные вещи</a>
    </h5>
</div>


<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/ohota_rybalka'}>
    Охота и рыбалка</a>
    </h5>
</div>

<div class="col-auto">
<h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/darom'}>
    Отдам даром</a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/migranttyk_konsultasia'}>
    Мигранттык консультация</a>
    </h5>
</div>

<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/agenstvo'}>
    Агентство</a>
    </h5>
</div>


<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/detskiy_mir'}>
    Детский мир
    </a>
    </h5>
</div>
<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/moda_style'}>
    Мода и стиль
    </a>
    </h5>
</div>
<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/dom_i_sad'}>
    Дом и сад
    </a>
    </h5>
</div>
<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/electronics'}>
    Электроника
    </a>
    </h5>
</div>
<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/uslugi'}>
    Услуги
    </a>
    </h5>
</div>
<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/transport_ad'}>
    Транспорт
    </a>
    </h5>
</div>
<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/realty'}>
    Недвижимость
    </a>
    </h5>
</div>
<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/animals'}>
    Животное
    </a>
    </h5>
</div>
<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/darom'}>
    Отдам даром
    </a>
    </h5>
</div>
<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/podarki'}>
    Подарки
    </a>
    </h5>
</div>
<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/biznes'}>
    Бизнес
    </a>
    </h5>
</div>
<div class="col-auto">
    <h5  class="footertxt"><a color='white' style={{color:'white',fontSize:'12px'}} href={'/hobbi_sport_otdyh'}>
    Отдых и спорт
    </a>
    </h5>
</div>


</div>

        

    )
    }
    
    
    export default Catalog;
    





