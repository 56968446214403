import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { Button, Modal, Radio, Spin, Tabs , Space } from 'antd';
import { message, Upload } from 'antd';
import { AudioOutlined, MediumCircleFilled } from '@ant-design/icons';
import { Image } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLikes } from '../redux/actions/like_actions';
import moment from 'moment-timezone';
import { API_URL, uploads } from '../config/config';
import Userinfo from './userinfo' 
const Post_aktiv = ({ product }) => {

    
    const [messageApi, contextHolder] = message.useMessage();
    // const dispatch = useDispatch();
    // const user_id = localStorage.getItem('token');
    // const { likes } = useSelector((state) => state.likes);
    const [favorited, setFavorited] = useState(null);
    const [product3, setProduct3] = useState(null);
    const [product4, setProduct4] = useState(null);
    const [message1, setMessage1] = useState(null);
    // 
    // 
    const [products, setProducts] = useState(null);
    const [logged, setLogged] = useState(false);
    const [ad_id, setAd_id] = useState(null);
    // const local = localStorage.getItem('token');
    const [usertoken, setUsertoken] = useState(null);
    const useridtoken = async () => {
        const post = {
            id: localStorage.getItem('token'),
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users5'
        });
        // 
        if (data.data.status == 200) {
            // 
            setUsertoken(data.data.users5[0].id);
            Logged(data.data.users5[0].id);
        } else {
            // 
        }
    }
    const Logged = (local) => {
        if (local != null) {
            setLogged(true);
            // setMetro_state(false);
        }
    }
    const [products_metro, setProducts_metro] = useState();
const fetchProducts_metro2 = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/getmetro'
});
if (data.data.status == 200){
  setProducts_metro(data.data.getmetro);
//   
} else {
//   
}
}


    const fetchProduct3 = async () => {
        const data = await axios({
            method: 'get',
            url: API_URL +'/users_get'
        });
        if (data.data.status == 200) {
            setProduct3(data.data.users_get);
            // 
        } else {
            // 
        }
    }

    // const fetchProducts = async () => {
    //     const data = await axios({
    //         method: 'get',
    //         url: API_URL+'/client1'
    //     });
    //     if (data.data.status == 200) {
    //         setProducts(data.data.posts);
    //         // 
    //     } else {
    //         // 
    //     }
    // }

// const chat_if = async () => {
//     if (logged == true) {
//         const params = {
//             ad_id: ad_id,
//             user1_id: usertoken,
//             user2_id: products[0].userid,
//         };
//         const data = await axios({
//             method: 'get',
//             params: params,
//             url: API_URL + '/chat_if'
//         });
//         // 
//         if (data.data.status == 200){
//             chat(data.data.chat_if[0].ad_id,data.data.chat_if[0].id);
//             // 
//         } else {
//            error();
//             // 
//             chats();
//             // window.location.href="/mainsms/";
//             // 
//         }
//     } else {
//         alert('Авторизуйтесь!');
//     }

// }
    
    // const chats = async ()=>{

    //     const params = {
    //         ad_id:ad_id,
    //         user1_id:usertoken,
    //         user2_id:products[0].userid,
    //     };
    //     const data = await axios({
    //         method:'post',
    //         params:params,
    //         url:API_URL+'/chats'
    //     });
    //     // 
    //     if(data != null){
    //         if(data.status  == 200){
    //         // 
    //         // fetchProducts();
    //         localStorage.setItem('chat',message1);
    //         setTimeout(chat_if,2000);
    //         // window.location.href="/mainsms/"+product[0].id;
    //         }else{
    //             alert('Error')
    //         }
    //     }
    // }


    // const chat = async (ad_id,room_id) => {
    //     const params = {
    //         ad_id: ad_id,
    //         user_id: usertoken,
    //         message: message1,
    //         room_id: room_id,
    //         user_id2: products[0].userid
    //     };
    //     const data = await axios({
    //         method: 'post',
    //         params: params,
    //         url: API_URL + '/chat'
    //     });
    //     // 
    //     if (data != null){
    //         if (data.status == 200){
    //             success();
    //         } else {
    //             alert('Error')
    //         }
    //     }
    // }

    const addfavorites = async (ad_id) => {

        const params = {
            ad_id: ad_id,
            user_id: usertoken,
        };

        const data = await axios({
            method: 'post',
            params: params,
            url: API_URL+'/favorites'
        });

        // 
        if (data != null) {
            if (data.status == 200) {
                // 
                success2();
                // Post2.reload();
                // window.location.href = '/'
            } else {
                alert('Error')
            }
        }
    }
    
    const delfavorites = async (ad_id) => {

        const params = {
            ad_id: ad_id,
            user_id: usertoken,

        };
        // 

        const data = await axios({
            method: 'delete',
            params: params,
            url: API_URL+'/delete_favorites'
        });

        
        if (data != null) {
            if (data.status == 200) {
                // 
                success2();
                // window.location.href = '/'
                // checkLikes();
            } else {
                alert('Error')
            }
        }
    }

    

    const fetchProduct4 = async () => {
        const post = {
            id: product.userid,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL +'/users1'
        });
        // 
        if (data.data.status == 200) {
            setProduct4(data.data.users1);
            // 
        } else {
            // 
            // 
        }
    }
    


    // const checkLikes = (id) => {
        
    //     // 
    //     const check = likes.filter(i => i.ad_id == id);
    //     if (check.length > 0) {
    //         setFavorited(true);
    //         return true;
    //     } else {
    //         setFavorited(false);
    //         return false;
    //     }
    // }
    // if (favorited == null) {
    //     if (products != null || products != undefined || products?.length > 0) {
    //         checkLikes(product.id);
    //     }
    // }
    
const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Сообщение отправлено!',
    });
  };
  const success2 = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
 
  const error = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
  };

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'This is a warning message',
    });
}
// const [products_category, setProducts_category] = useState(null);
// const fetchProducts_category = async () => {
// const data = await axios({
//   method: 'get',
//   url: API_URL + '/cat_get'
// });
// if (data.data.status == 200){
//   setProducts_category(data.data.cat_get);
//   
// } else {
//   
// }
// }



    useEffect(() => {
        useridtoken();
        fetchProduct3();
        fetchProduct4();
        fetchProducts_metro2();
        fetchProducts_click();
        // fetchProducts_category();
    }, []);

    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (id) => {
        setAd_id(id);
      setIsModalOpen(true);
    //   
    };
    // 
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );
   
   
      const [searchItem, setSearchItem] = useState('')
     

      const handleInputChange = (e) => { 
        const searchTerm = e.target.value;
        setSearchItem(searchTerm)
      }

      const iz_status = async (ad_id)=>{
        const params = {
            status:'2',
            ad_id:ad_id
        };
        const data = await axios({
            method:'post',
            params:params,
            url:API_URL+'/iz_status3'
        });
        // 
        if(data != null){
            if(data.status  == 200){
            // 
            window.location.href='userinfo/'+ 2
            }else{
                alert('Error')
            }
        }
    }
    const [clicks, setClicks] = useState(false);
    const [products_click, setProducts_click] = useState(null);
    const fetchProducts_click = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getclick'
    });
    // 
    if (data.data.status == 200){
      setProducts_click(data.data.getview);
    //   
    } else {
    //   
    }
    }
    const click = async (ad_id) => {
        if (ad_id != null) {
            const params = {
                ad_id: ad_id,
            };
            const data = await axios({
                method: 'get',
                params: params,
                url: API_URL + '/click_post'
            });
            // 
            if (data.data.status == 200){
                add_click(ad_id);
                // setClicks(data.data.click_post);
                
            } else {
               new_click(ad_id);
            }
        } else {
        }
    }
    const add_click = async (ad_id) => {
        const params = {
            ad_id: ad_id
        };
        const data = await axios({
            method: 'put',
            params: params,
            url: API_URL + '/click_post'
        });
        // 
        if (data != null){
            if (data.status == 200){
                window.location.href='/post/'+ad_id
            } else {
            }
        }
    }
    const new_click = async (ad_id) => {
        const params = {
            ad_id: ad_id,
            click: 1
        };
        const data = await axios({
            method: 'post',
            params: params,
            url: API_URL + '/click_post'
        });
        // 
        if (data != null){
            if (data.status == 200){
                window.location.href='/post/'+ad_id
            } else {
            }
        }
    }
    
     
    return (
        <>
 {contextHolder}
      <Modal  title="Отправить сообщение" open={isModalOpen} onOk={handleOk} cancelText="Отмена" onCancel={handleCancel}>
      <textarea onChange={(e) => { setMessage1(e.target.value) }} style={{height:'100px'}} className="form-control"></textarea>
      </Modal>
            {product != null || product != undefined || product ?.length > 0 ?
                <>
                                {/* {products_category != null ? <>
                                    {products_category.filter(category => category.id == product.categories).map(filteredCategory => (
                                <a >
                                    <label className=' medname'  style={{fontSize:'16px',fontFamily:'revert-layer',marginLeft:'10px'}}>{filteredCategory.name}</label>
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>} */}
                    <div  style={{width:'100%',marginLeft:'0px'}} class="card " >
                    {products_click != null ? <>
                                    {products_click.filter(view => view.ad_id == product.id).map(filteredView => (
                                <a>
                                    {filteredView.click != null ? <><button  style={{opacity:'0.7',borderRadius:'15%',fontSize:'12px',zIndex:'1',position:'absolute',padding:'1px'}} type="button" class="btn btn-dark"><i class="fa-solid fa-eye"></i> {filteredView.click}</button></> :
                                     <>
                                    </>}
                                </a>
                                ))}
                                </> : <>
                                </>}
                        <a className='postimage2'  onClick={() => click(product.id)}>
                            <div style={{pointerEvents:'none'}}>
                                <Image className='postimage2' style={{width:'100%',height:'200px'}}  src={product.images != null ? (uploads + product.images) : (uploads + 'no_image.jpg')} />
                            </div>
                            
                        </a>
                        <a className='postimage' onClick={() => click(product.id)}>
                        <div style={{pointerEvents:'none'}}>
                            <Image className='postimage' style={{width: '100%',height:'160px',borderTopLeftRadius:'5px',borderTopRightRadius:'5px'}}  src={product.images != null ? (uploads + product.images) : (uploads + 'no_image.jpg')} />
                        </div>
                        </a>
                        {products_metro != null ? <>
                                    {products_metro.filter(metro => metro.id_sql == product.metro).map(filteredMetro => (
                                <a >
                                    {filteredMetro.color != 1 ? <><p style={{color:'gray',fontSize:'12px'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'12px'}} /></p></> :
                                     <>
                                    <p style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='30px' height='30px' style={{borderRadius:'50%'}}></img></p>
                                    </>}
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>}
                                <div style={{padding:'0',marginTop:'-10px'}} className='col-md-12'><span class="card-title txt7">{product.price != null ? product.price : 'Договорная'}</span> <label class="card-title ml-2 txt7cur "><b>{product.currency}</b></label></div>
                                <div className='col-md-12'><p style={{fontSize:'12px'}} className="destext" >{product.descriptions}</p></div> 
                                <div class="col-12 col-md-12">
                                    <label style={{fontSize:'10px',float:'right',marginRight:'-15px'}} class="text-secondary">{moment.tz(product.date1, "Europe/Moscow").fromNow()}</label>
                                </div>
                                
                                <div className='row '>
                                    <div className='col-6 '>
                                        <Button style={{width:'100%'}} danger onClick={()=>iz_status(product.id)}><i class="fa-solid fa-power-off"></i></Button>
                                    </div>
                                    <div className='col-6'>
                                    <Button style={{width:'100%'}}  ghost type='primary' href={"/update_ad/"+ product.id}><i class="fa-solid fa-pencil"></i></Button>
                                    </div>
                                </div>
                        <div style={{marginTop:'-17px'}} class="card-body medbody">
                            <div  class="row d-block d-md-none ">
                           
                                
                             
                               
                        
                               
                            </div>
                          
                                    
                               
                                </div>
                                    
                               
                            
                           
                        
                    </div>

                </>
                : <>Loading</>
            }





        </>
        
    );
}
export default Post_aktiv;