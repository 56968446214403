import React,{ Component, useEffect } from 'react';
import{BrowserRouter as Router,Routes,Route, useParams} from 'react-router-dom';
import { Button, Modal, Radio, Select, Tabs } from 'antd';
import  {useState} from 'react'
import { AudioOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import axios from 'axios';
import { API_URL, uploads } from '../config/config';
import { Option } from 'antd/lib/mentions';


const Ozon_park2 =()=>{
  const [park_value, setPark_value] = useState(null);
  const [park, setPark] = useState(null);
 
  const [result, setResult] = useState(null);
  const [cars_value, setCars_value] = useState(null);

  const [input_value, setInput_value] = useState();
  const [comment_value, setComment_value] = useState();

  const fetchPark = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/park',
    })
    
    if (data.data.status == 200) {
      setPark(data.data.park);
      setPark_value(data.data.park[14].src);
    } else {
    }
  }

  
  const ozon_get = async () => {
    if (input_value != null) {
    const params = {
      'number' : input_value,
    }
    const data = await axios({
      method: 'get',
      params: params,
      url: API_URL + '/ozon_get',
    });
      
      if (data.data.status == 200) {
        setResult(data.data.ozon_get);
        setPark_value(data.data.ozon_get[0].src);
      } else {

      }
  }}
 


  const handleChange = (value) => {
    setPark_value(value);
    
  };
  const handleChange2 = (value) => {
    setCars_value(value);
    
  };
  useEffect(() => {
  fetchPark();
}, [])


return(
  
    <>


  <div className='container'>
    <div className='row'>
      <div className='col-md-12'>
    </div>
      <div className='col-md-12'>
      {park ?
            <>
            
            <iframe className='d-none d-md-block' src={park_value} width="100%" height="400" frameborder="0"></iframe>
            <iframe className='d-block d-md-none' src={park_value} width="100%" height="600px" frameborder="0"></iframe>
            <footer className='row' style={{zIndex:'1',position:'fixed',width:'100%'}}>
            {/* <div className="col-md-2 col-4 mt-3">
                  <Select defaultValue={park[0].src} onChange={handleChange} style={{ width: '100%' }}>
                    {park.map((products) =>
                      <Option value={products.src}>{products.name}</Option>
                    )
                    }
                  </Select>
              </div> */}
{/*               
              <div className='col-md-2 col-4 mt-3'>
                {cars ? <>
                  <Select defaultValue={cars[0].model} onChange={handleChange2} style={{ width: '100%' }}>
                    {cars.map((products) =>
                      <Option value={products.id}>{products.model}</Option>
                    )
                    }
                  </Select>
                </> : <></>}
              </div> */}
              <div className='col-md-3 col-5'>
                <Input onChange={(e) => { setInput_value(e.target.value) }} placeholder='А000АА000' maxLength={9} max={9} style={{textTransform:'uppercase'}} type='text'></Input>
              </div>
              {/* <div className='col-md-3 col-5'>
                <Input onChange={(e) => { setComment_value(e.target.value) }} placeholder='не обязательно'  type='text'></Input>
              </div> */}
              <Button onClick={ozon_get}>Показать</Button>
              
            </footer>
            
            </>:
            <></>  
            }
      </div>
    </div>
  </div>       
    </>
    
)
}


export default Ozon_park2;