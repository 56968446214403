import {combineReducers} from "redux";
import { categoriesReducer } from "./category_reducers";
import { likesReducer } from "./like_reducers";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { productsReducer } from "./products_reducers ";
import { products2Reducer } from "./products2_reducers";




export const reducers = combineReducers({
 likes: likesReducer,
 categories: categoriesReducer,
 products: productsReducer,
 products2: products2Reducer
});
