import Footer from './footer';
import Nav from './navbar';



const Mainpost =()=>{

    return(
        <>
        

<div className='container'>
<div class="row bgmainposts">
<div class="col-6 col-md-3 mt-3">
    <div class="card" >
        <a href="/post">
        <img src="http://img3.tbcdn.cn/img.alicdn.com/tfscom/TB2HtwAfmBjpuFjSsplXXa5MVXa_!!2020108766.jpg" class="cardimg" alt="..."/>
        </a>
        
    <div class="card-body">
    <div class="row">
    
        <div class="col-md-6">
        <label class="card-title txt7">10000 сом</label>
        </div>
        <div class="col-md-6"></div>
    <div class="col-md-4"></div>
        </div>
        <p class="card-text text4">Часы</p>
        <img src='images/user.png' className='carduserimg'></img>
        <label class="text-secondary">10.02.2022 <i class="fas fa-sms ml-5 sms2"></i> <i class=" fa-solid fa-heart ml-2 text-secondary heart"></i></label>
    </div>
    </div>  
    </div>

    <div class="col-6 col-md-3 mt-3">
    <div class="card" >
        <a href="/post">
        <img src="http://img3.tbcdn.cn/img.alicdn.com/tfscom/TB2HtwAfmBjpuFjSsplXXa5MVXa_!!2020108766.jpg" class="cardimg" alt="..."/>
        </a>
        
    <div class="card-body">
    <div class="row">
    
        <div class="col-md-6">
        <label class="card-title txt7">10000 сом</label>
        </div>
        <div class="col-md-6"></div>
    <div class="col-md-4"></div>
        </div>
        <p class="card-text text4">Часы</p>
        <img src='images/user.png' className='carduserimg'></img>
        <label class="text-secondary">10.02.2022 <i class="fas fa-sms ml-5 sms2"></i> <i class=" fa-solid fa-heart ml-2 text-secondary heart"></i></label>
    </div>
    </div>  
    </div>

    <div class="col-6 col-md-3 mt-3">
    <div class="card" >
        <a href="/post">
        <img src="http://img3.tbcdn.cn/img.alicdn.com/tfscom/TB2HtwAfmBjpuFjSsplXXa5MVXa_!!2020108766.jpg" class="cardimg" alt="..."/>
        </a>
        
    <div class="card-body">
    <div class="row">
    
        <div class="col-md-6">
        <label class="card-title txt7">10000 сом</label>
        </div>
        <div class="col-md-6"></div>
    <div class="col-md-4"></div>
        </div>
        <p class="card-text text4">Часы</p>
        <img src='images/user.png' className='carduserimg'></img>
        <label class="text-secondary">10.02.2022 <i class="fas fa-sms ml-5 sms2"></i> <i class=" fa-solid fa-heart ml-2 text-secondary heart"></i></label>
    </div>
    </div>  
    </div>

    <div class="col-6 col-md-3 mt-3">
    <div class="card" >
        <a href="/post">
        <img src="http://img3.tbcdn.cn/img.alicdn.com/tfscom/TB2HtwAfmBjpuFjSsplXXa5MVXa_!!2020108766.jpg" class="cardimg" alt="..."/>
        </a>
        
    <div class="card-body">
    <div class="row">
    
        <div class="col-md-6">
        <label class="card-title txt7">10000 сом</label>
        </div>
        <div class="col-md-6"></div>
    <div class="col-md-4"></div>
        </div>
        <p class="card-text text4">Часы</p>
        <img src='images/user.png' className='carduserimg'></img>
        <label class="text-secondary">10.02.2022 <i class="fas fa-sms ml-5 sms2"></i> <i class=" fa-solid fa-heart ml-2 text-secondary heart"></i></label>
    </div>
    </div>  
    </div>

    <div class="col-6 col-md-3 mt-3">
    <div class="card" >
        <a href="/post">
        <img src="http://img3.tbcdn.cn/img.alicdn.com/tfscom/TB2HtwAfmBjpuFjSsplXXa5MVXa_!!2020108766.jpg" class="cardimg" alt="..."/>
        </a>
        
    <div class="card-body">
    <div class="row">
    
        <div class="col-md-6">
        <label class="card-title txt7">10000 сом</label>
        </div>
        <div class="col-md-6"></div>
    <div class="col-md-4"></div>
        </div>
        <p class="card-text text4">Часы</p>
        <img src='images/user.png' className='carduserimg'></img>
        <label class="text-secondary">10.02.2022 <i class="fas fa-sms ml-5 sms2"></i> <i class=" fa-solid fa-heart ml-2 text-secondary heart"></i></label>
    </div>
    </div>  
    </div>

    <div class="col-6 col-md-3 mt-3">
    <div class="card" >
        <a href="/post">
        <img src="http://img3.tbcdn.cn/img.alicdn.com/tfscom/TB2HtwAfmBjpuFjSsplXXa5MVXa_!!2020108766.jpg" class="cardimg" alt="..."/>
        </a>
        
    <div class="card-body">
    <div class="row">
    
        <div class="col-md-6">
        <label class="card-title txt7">10000 сом</label>
        </div>
        <div class="col-md-6"></div>
    <div class="col-md-4"></div>
        </div>
        <p class="card-text text4">Часы</p>
        <img src='images/user.png' className='carduserimg'></img>
        <label class="text-secondary">10.02.2022 <i class="fas fa-sms ml-5 sms2"></i> <i class=" fa-solid fa-heart ml-2 text-secondary heart"></i></label>
    </div>
    </div>  
    </div>



    </div>
    </div>
    </>
    );
    }
    
    
    export default Mainpost;
