import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Post2 from './post2';
const Nedvij =()=>{
  
  return(
      <>
      

<div  className='container '>
<div class="row bgmainposts">
<div class="col-md-12 ">
  <h2 class="txt5 ">Хиты продажи</h2>
</div>

  </div>
  </div>
  </>
  );
  }
  
  
  export default Nedvij;