import Footer from './footer';
import Nav from './navbar';
import Post2 from './post2';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_URL } from '../config/config';
import Footer_menu from './footer_menu';
import { useDispatch, useSelector } from 'react-redux';
import Post_izbr from './post_izbr';
import { setProducts2 } from '../redux/actions/products2_actions';
import { setLikes } from '../redux/actions/like_actions';
import React from 'react';
import { Button } from 'antd';
import { setProducts } from '../redux/actions/products_actions ';
import Post2_like from './post2_like';
const Korzina =()=>{
    // // const user_id = localStorage.getItem('token');
    // // const [products,setProducts] = useState(null);
    // const { products2 } = useSelector((state) => state.products2);
    // const dispatch = useDispatch();
    // // const { likes } = useSelector((state) => state.likes);
    // const [favorited, setFavorited] = useState(null);
    // const [products, setProducts] = useState(null);
    // const [usertoken, setUsertoken] = useState(null);
    // const useridtoken = async () => {
    //     const post = {
    //         id: localStorage.getItem('token'),
    //     };
    //     const data = await axios({
    //         method: 'get',
    //         params: post,
    //         url: API_URL + '/users5'
    //     });
    //     
    //     if (data.data.status == 200) {
    //         
    //         setUsertoken(data.data.users5[0].id);
    //             // fetchProducts2(data.data.users5[0].id);
    //             setTimeout(() => {
    //                 fetchProducts(data.data.users5[0].id); 
    //             }, 500); 
    //     } else {
    //         
    //     }
    // }
    // const fetchProducts = async (usertoken) => {
    //     const post = {
    //         user_id: usertoken,
    //     };
    //     const data = await axios({
    //         method: 'get',
    //         params: post,
    //         url: API_URL+'/get_favorites'
    //     });
    //     if (data.data.status == 200) {
    //         dispatch(setProducts2(data.data.favorites));
    //         
    //     } else {
    //         
    //         
    //     }
    //   }
    // //   const fetchProducts2 = async (usertoken) => {
    // //     if(usertoken != null){
    // //         const post = {
    // //             user_id: usertoken,
    // //         };
    // //         const data = await axios({
    // //             method: 'get',
    // //             params: post,
    // //             url: API_URL+'/get_favorites'
    // //         });
    // //         if (data.data.status == 200) {
    // //             dispatch(setLikes(data.data.favorites));
    // //             
    // //         } else {
    // //             
    // //             
    // //         }
    // //     }else{
    // //         
    // //         alert(usertoken);
    // //     }
       
    // //   }
    //   const delfavorites = async (ad_id)=>{
    //     const params = {
    //         ad_id:ad_id,
    //         user_id:usertoken,
    //     };
    //     
    //     const data = await axios({
    //         method:'delete',
    //         params:params,
    //         url:API_URL+'/delete_favorites'
    //     });
    //     
    //     if(data != null){
    //         if(data.status  == 200){
    //         
         
    //         }else{
    //             alert('Error')
    //         }
    //     }
    // }
    
    // useEffect(()=>{
    //     useridtoken();
    // },[])
    const { products } = useSelector((state) => state.products);
    
    // 
    const dispatch = useDispatch();
    // const [products2, setProducts2] = useState(null);
    // const [products1,setProducts1] = useState(null);
    // const user_id = localStorage.getItem('token');
    const [limit, setLimit] = useState(16);
    const [usertoken, setUsertoken] = useState(null);
    const useridtoken = async () => {
        if(localStorage.getItem('token') != null){
            const post = {
                id: localStorage.getItem('token'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            
            if (data.data.status == 200) {
                
                setUsertoken(data.data.users5[0].id);
                fetchProducts2(data.data.users5[0].id);
                setTimeout(() => {
                    fetchProducts(data.data.users5[0].id);
                }, 500);
                // setTimeout(fetchProducts(data.data.users5[0].id),3000);
            }if(localStorage.getItem('g') != null){
                const post = {
                    id: localStorage.getItem('g'),
                };
                const data = await axios({
                    method: 'get',
                    params: post,
                    url: API_URL + '/users5'
                });
                
                if (data.data.status == 200) {
                    
                    setUsertoken(data.data.users5[0].id);
                    fetchProducts2(data.data.users5[0].id);
                    setTimeout(() => {
                        fetchProducts(data.data.users5[0].id);
                    }, 500);
                    // setTimeout(fetchProducts(data.data.users5[0].id),3000);
                }
        }
            } 
      }
        // const fetchProducts = async (usertoken) => {
    //     const post = {
    //         user_id: usertoken,
    //     };
    //     const data = await axios({
    //         method: 'get',
    //         params: post,
    //         url: API_URL+'/get_favorites'
    //     });
    //     if (data.data.status == 200) {
    //         dispatch(setProducts2(data.data.favorites));
    //         
    //     } else {
    //         
    //         
    //     }
    //   }
    // const fetchProducts = async () => {
    //     const params={
    //          id : limit
    //      };
    //      const data = await axios({
    //          method:'get',
    //          params: params,
    //          url:API_URL+'/client1get'
    //      });
    //      if(data.data.status == 200){
    //          dispatch(setProducts(data.data.limit));
    //          setLimit( limit + 16 );
    //          localStorage.setItem('limit',limit);
    //          
    //      }else{
    //          
    //          
    //      }
    //  }
    const fetchProducts = async (usertoken) => {
       const params={
        user_id : usertoken
        };
        const data = await axios({
            method:'get',
            params: params,
            url:API_URL+'/get_favorites'
        });
        if(data.data.status == 200){
            
            dispatch(setProducts(data.data.favorites));
            // setLimit( limit + 16 );
        }else{
            
            
        }
    }
    const fetchProducts2 = async (usertoken) => {
        if(usertoken != null){
            const post = {
                user_id: usertoken,
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL+'/get_favorites'
            });
            if (data.data.status == 200) {
                dispatch(setLikes(data.data.favorites));
                
            } else {
                
                
            }
        }else{
            
            alert(usertoken);
        }
       
      }
      
    useEffect(()=>{
        useridtoken();
        
    },[])
    return(
<>
<Nav/>
<div class="container bg-light">

    <div class="row">
    <div class="col-md-12">
            <h1 class="kortxt2">Избранные</h1>
        </div>
   
        {products != null || products != undefined || products?.length > 0 ?
    <>
    {products.map((item)=>
    <div class="col-6 col-md-3 mt-3">
        <Post2_like product={item}/>
    </div>
    
    )
    }
    </>
    :<> Вы еще не добавляли в избранное</>
}

    </div>
</div>
<Footer/>
<Footer_menu/>
    </>
    );
    }
    
    
    export default Korzina;