import React,{ Component, useEffect } from 'react';
import{BrowserRouter as Router,Routes,Route, useParams} from 'react-router-dom';
import { Button, Modal, Radio, Select, Tabs } from 'antd';
import  {useState} from 'react'
import { AudioOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import axios from 'axios';
import { API_URL, uploads } from '../config/config';
import { Option } from 'antd/lib/mentions';



const Ozon_park =()=>{
  const [park_value, setPark_value] = useState(null);
  const [park, setPark] = useState(null);
 
  const [cars, setCars] = useState(null);
  const [cars_value, setCars_value] = useState(null);

  const [input_value, setInput_value] = useState();
  const [comment_value, setComment_value] = useState();
  const [comment, setComment] = useState(null);
  const [date, setDate] = useState(null);
  const [numbers_value, setNumbers_value] = useState(null);

  const [result, setResult] = useState(null);
  const [numbers, setNumbers] = useState(null);

  const fetchPark = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/park',
    })
    
    if (data.data.status == 200) {
      setPark(data.data.park);
      setPark_value(data.data.park[14].src);
    } else {
    }
  }
  const fetchNumber = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/car_numbers',
    })
    
    if (data.data.status == 200) {
      setNumbers(data.data.numbers);
    } else {
    }
  }
  const fetchCars = async () => {
    setComment(null)
    const data = await axios({
      method: 'get',
      url: API_URL + '/cars',
    })
    
    if (data.data.status == 200) {
      setCars(data.data.cars);
    } else {
    }
  }
  const ozon_get = async () => {
    if (numbers_value != null) {
    const params = {
      'number' : numbers_value,
    }
    const data = await axios({
      method: 'get',
      params: params,
      url: API_URL + '/ozon_get',
    });
    setComment(null);
      
      if (data.data.status == 200) {
        setDate(data.data.ozon_get[0].data);
        setResult(data.data.ozon_get);
        setComment(data.data.ozon_get[0].comment);
        setPark_value(data.data.ozon_get[0].src);
      } else {

      }
  }}

  const ozon_get2 = async () => {
    if (numbers_value != null) {
    const params = {
      'number' : numbers_value,
    }
    const data = await axios({
      method: 'get',
      params: params,
      url: API_URL + '/ozon_get',
    });
      
      if (data.data.status == 200) {
      update();
      } else {
      insert();
      insert2();
      }
  }}

  const insert = async () => {  
    if (numbers_value && park_value) {
      const params = {
        'number' : numbers_value,
        'src' : park_value,
        'comment' : comment_value,
      }
      const data = await axios({
        method: 'post',
        params: params,
        url: API_URL + '/ozon_insert',
      }
      )
      if (data.data.status == 200) {
        // alert(data.data.status);
      } else {
        update();
        
      }
    }}
    const insert2 = async () => {  
      if (numbers_value && cars_value) {
        const params = {
          'numbers' : numbers_value,
          'model' : cars_value
        }
        const data = await axios({
          method: 'post',
          params: params,
          url: API_URL + '/ozon_insert2',
        }
        )
        if (data.data.status == 200) {
          alert(data.data.status);
          
        } else {
          alert(data.data.status);
          
        }
      }}
    const update = async () =>{
      const params = {
        'number' : numbers_value,
        'src' : park_value,
        'comment' : comment_value,
      }
      const data = await axios({
        method: 'post',
        params: params,
        url: API_URL + '/ozon_update',
      }
      )
      if(data.data.status == 200){
        alert(200);
      }else{
        
        alert(400);
      }
    }
  


  const handleChange = (value) => {
    setPark_value(value);
    
  };
  const handleChange2 = (value) => {
    setCars_value(value);
    
  };
  const handleChange3 = (value) => {
    setNumbers_value(value);
    // setInput_value();
    
  };
  const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}

  
  useEffect(() => {
    fetchNumber();
  fetchCars();
  fetchPark();
}, [])
const tab3 = <Button onClick={fetchCars} type="dark ml-3" >   <a style={{color:'black'}} >Добавить машину</a> </Button>;
const tab2 = <Button onClick={fetchNumber} type="dark ml-3" >   <a style={{color:'black'}} >Найти машину</a> </Button>;
const tab1 = <Button onClick={fetchNumber} type="dark ml-3" >   <a style={{color:'black'}} >Парковать</a> </Button>;

return(
  
    <>


  <div className='container'>
    <div className='row'>
      
    <Tabs  defaultActiveKey="1">
    <Tabs.TabPane tab={tab1} key="1">
     <div className='row'>
     <div className='col-md-12'>
      {park ?
            <>
            {/* <div style={{zIndex:'1',position:'absolute'}} className="col-md-2 col-4 mt-3">
                  <Select defaultValue='Выбрать место' onChange={handleChange} style={{ width: '100%' }}>
                    {park.map((products) =>
                      <Option defaultValue={park[0].src} value={products.src}>{products.name}</Option>
                    )
                    }
                  </Select>
              </div> */}
            <iframe className='d-none d-md-block' src={park_value} width="100%" height="400" frameborder="0"></iframe>
            <iframe className='d-block d-md-none' src={park_value} width="100%" height="400px" frameborder="0"></iframe>
            <footer className='row' style={{zIndex:'1',position:'fixed',width:'100%'}}>
            {/* <div className="col-md-2 col-4 mt-3">
                  <Select defaultValue={park[0].src} onChange={handleChange} style={{ width: '100%' }}>
                    {park.map((products) =>
                      <Option value={products.src}>{products.name}</Option>
                    )
                    }
                  </Select>
              </div> */}
               <div className="col-md-2 col-4 mt-3">
                  <Select defaultValue='Выбрать место' onChange={handleChange} style={{ width: '100%' }}>
                    {park.map((products) =>
                      <Option defaultValue={park[0].src} value={products.src}>{products.name}</Option>
                    )
                    }
                  </Select>
              </div>
              <div className='col-md-2 col-6 mt-3'>
                {cars ? <>
                  <Select defaultValue='Марка машины' onChange={handleChange2} style={{ width: '100%' }}>
                    {cars.map((products) =>
                      <Option defaultValue={cars[0].model} value={products.model}>{products.model}</Option>
                    )
                    }
                  </Select>
                </> : <></>}
              </div>
              {/* <div className='col-md-3 col-6 mt-3'>
                <Input onChange={(e) => { setInput_value(e.target.value) }} placeholder='А000АА000' maxLength={9} max={9} style={{textTransform:'uppercase'}} type='text'></Input>
              </div> */}
              {numbers ? <>
                <div className='col-md-6 col-12 mt-3'>
              <Select
              onChange={handleChange3}
    showSearch
    style={{
      width: 200,
    }}
    placeholder="Номер машины"
    optionFilterProp="children"
   
   
  > 
   
   {numbers.map((products) =>
                      <Option
                      filterOption={(input, products) => (products?.label ?? '').includes(input)}
                      filterSort={(productsA, productsB) =>
                        (productsA?.label ?? '').toLowerCase().localeCompare((productsB?.label ?? '').toLowerCase())
                      }
                       value={products.numbers}>{products.numbers}</Option>
                    )
                    }
  </Select>
              </div>
              </> : <></>}
             
              <div className='col-md-3 col-6'><Button  type='primary' className='mt-3' onClick={ozon_get2 }>Сохранить</Button></div>
              <div className='col-md-12 col-12 mt-3'>
                <Input style={{height:'50px'}} onChange={(e) => { setComment_value(e.target.value) }} placeholder='заметка не обязательно'  type='text'></Input>
              </div>
              <Button  type='primary' className='mt-3' onClick={ozon_get2 }>Сохранить</Button>
              
            </footer>
            
            </>:
            <></>  
            }
      </div>
     </div>
    </Tabs.TabPane>
    <Tabs.TabPane tab={tab2} key="2">
      {comment != null ? <>
      
        <div  className='col-12'>
        <label style={{backgroundColor:'aqua'}}>Заметка:</label>
            <textarea style={{width:'100%',zIndex:'1',position:'absolute',opacity:'0.7',margintop:'20px',position:'relative',backgroundColor:'aqua'}}>{comment != null ? comment : ''}</textarea>
        </div>
        {date != null ? <><div  className='col-12'>
        <label style={{backgroundColor:'aqua'}}>Дата:</label>
            <label style={{width:'100%',zIndex:'1',position:'absolute',opacity:'0.7',margintop:'20px',position:'relative',backgroundColor:'aqua'}}>{date != null ? date : ''}</label>
        </div></>:<></>}
        

      </> : <></>}
    <iframe className='d-none d-md-block' src={park_value} width="100%" height="400" frameborder="0"></iframe>
            <iframe  className='d-block d-md-none' src={park_value} width="100%" height='480px' frameborder="0"></iframe>
    <div className='col-md-12 col-12'>
      {park ?
            <>
            <div style={{zIndex:'1',position:'absolute'}} className="col-md-3 col-12 mt-3">
              <label style={{opacity:'0.9'}}>Номер машины</label>
              {numbers ? <>
                <div className='col-md-6 col-3 '>
              <Select
              onChange={handleChange3}
    showSearch
    style={{
      width: 200,
    }}
    placeholder="Номер машины"
    optionFilterProp="children"
   
   
  > 
  
   {numbers.map((products) =>
                      <Option
                      
                      filterOption={(input, products) => (products?.label ?? '').includes(input)}
                      filterSort={(productsA, productsB) =>
                        (productsA?.label ?? '').toLowerCase().localeCompare((productsB?.label ?? '').toLowerCase())
                      }
                       value={products.numbers}>{products.numbers}</Option>
                    )
                    }
  </Select>
              </div>
              </> : <></>}
              <div className='col-12 mt-2'>
              <Button onClick={ozon_get}>Показать</Button>
              </div>
            
              </div>
             
              
           
            <footer className='row' style={{zIndex:'1',position:'fixed',width:'100%'}}>

             
              
            </footer>
            
            </>:
            <></>  
            }
      </div>
    </Tabs.TabPane>
    <Tabs.TabPane tab={tab3} key="3">
      <div className='col-12 col-md-3'>
        <Input onChange={(e) => { setNumbers_value(e.target.value) }} placeholder='А000АА000'   maxLength={9} minLength={9} min={9} max={9} style={{textTransform:'uppercase'}} type='text'></Input>
      </div>
      <div className='col-md-3 col-12 '>
                {cars ? <>
                  <Select defaultValue='Марка машины' onChange={handleChange2} style={{ width: '100%' }}>
                    {cars.map((products) =>
                      <Option defaultValue={cars[0].model} value={products.model}>{products.model}</Option>
                    )
                    }
                  </Select>
                </> : <></>}
      </div>
      <Button  type='primary' className='mt-3' onClick={insert2 }>Сохранить</Button>
    </Tabs.TabPane>
  </Tabs>
      <div className='col-md-12'>
    </div>
      
    </div>
  </div>       
    </>
    
)
}


export default Ozon_park;