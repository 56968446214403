import React,{ Component } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { uploads } from '../config/config';

const Category2 =({products})=>{  
    return(
<>
{products != null || products != undefined || products?.length > 0 ?
    <>
   <div style={{marginLeft:'5px'}}  className='medcategory2 row '>
        <a href={'/' + products.link }>
            <img  style={{width:'60px',height:'60px',borderRadius:'50%',marginLeft:'0px',marginLeft:'15px'}} src={uploads+products.image}></img>
        </a>
        <p style={{color:'black',fontSize:'10px',fontFamily:'initial',textAlign:'center'}} >{products.name}</p>
    </div>
        </>
    :<>Loading</>
}
     
{/* href={"/podcategory/"+products.name+"/"+products.id} */}
</>
    );
    }
    export default Category2;