import axios from "axios";
import { useState } from "react";
import Footer from "./footer";
import Nav from "./navbar";
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Spin,
  Switch,
  TreeSelect,
  message,
} from 'antd';
import { LoadingOutlined, PlusOutlined, EyeInvisibleOutlined, EyeTwoTone  } from '@ant-design/icons';
import React from 'react';
import { auth } from "../firebase/firebase-config";
import { useEffect } from "react";
import firebase from "firebase";
import { API_URL } from '../config/config';
import Footer_menu from "./footer_menu";
const Registr2 = () => {

  const [messageApi, contextHolder] = message.useMessage();
  const [login, setLogin] = useState(null);
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [avatar, setAvatar] = useState();
  const [numbers, setNumbers] = useState();
  const [code, setCode] = useState();
  const [final, setFinal] = useState();
  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [city, setCity] = useState(null);
  const [style1, setStyle1] = useState(false);
  const [product, setProduct] = useState(null);
  const [image_name, setImage_name] = useState('no_image.jpg');
  const number = localStorage.getItem('number');
  const google_id = localStorage.getItem('g');
  const login_token = localStorage.getItem('login');
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [user_id, setUser_id] = useState(localStorage.getItem('token2'));
  const [uid, setUid] = useState(localStorage.getItem('uid'));

  
  const fetchProduct = async () => {
    if(user_id != null){
      const post = {
        id: user_id,
      };
      const data = await axios({
        method: 'get',
        params: post,
        url: API_URL + '/users2'
      });
      
      if (data.data.status == 200) {
        setProduct(data.data.users2);
        // localStorage.setItem('token2',product[0].id);
  
        
      } else {
        
  
      }
    }if(google_id != null){
      const post = {
        id: google_id,
      };
      const data = await axios({
        method: 'get',
        params: post,
        url: API_URL + '/users5'
      });
      
      if (data.data.status == 200) {
        setProduct(data.data.users5);
        // localStorage.setItem('token2',product[0].id);
  
        
      } else {
        
  
      }
    }
    

  }

  const fetchProduct2 = async () => {
    const post = {
      id: number,
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL + '/users2'
    });
    
    if (data.data.status == 200) {
      setProduct(data.data.users2);
      setLogin(data.data.users2[0].login);
      setPassword(data.data.users2[0].password);
      setAvatar(data.data.users2[0].avatar);
      setNumbers(data.data.users2[0].numbers);
      setName(data.data.users2[0].name);
      setSurname(data.data.users2[0].surname);
      setCity(data.data.users2[0].city);
      setImage_name(data.data.users2[0].avatar);
      localStorage.setItem('token',data.data.users2[0].uid);
    } else {
      

    }
  }

  const fetchProduct3 = async () => {
    const post = {
      id: localStorage.getItem('login'),
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL + '/users_login'
    });
    
    if (data.data.status == 200) {
      setUser_id(data.data.users_login[0].id);
      setUid(data.data.users_login[0].uid);
      setProduct(data.data.users_login);
      setLogin(data.data.users_login[0].login);
      setPassword(data.data.users_login[0].password);
      setAvatar(data.data.users_login[0].avatar);
      setNumbers(data.data.users_login[0].numbers);
      setName(data.data.users_login[0].name);
      setSurname(data.data.users_login[0].surname);
      setCity(data.data.users_login[0].city);
      setImage_name(data.data.users_login[0].avatar);
      
    } else {
      

    }
  }

  const [logged, setLogged] = useState(false);
  const reg = async () => {
    if (password == password2) {
      const params = {
        password: password,
        user_id: user_id,
        uid: uid
      }
      
      const data = await axios({
        method: 'post',
        params: params,
        url: API_URL + '/put_users2',
      });
      if (data != null) {
        if (data.data.status == 200) {
          success2();
          localStorage.setItem('token',uid);
          setLogged(true);
          window.location.href = '/userinfo/'+1;
        } else {
          
          warning2();
        }
      }
    }else {
    warning();
    }
  }
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Пароль сохранено !',
    });
  };
  const success2 = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
 
  const error = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
  };

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Потверждение пароля не совпадает!',
    });
}
const warning2 = () => {
    messageApi.open({
      type: 'warning',
      content: 'Придумайте новый пароль!',
    });
}



  useEffect(() => {
    fetchProduct3();
    fetchProduct2();
    fetchProduct();
  }, [])
  return (
    <>
    {contextHolder}
      <div className="container "><Nav />
        <div class="row ">
          
          <div className="col-md-4 ">

          </div>

          <div class="col-md-4 card mt-5">
            <div class="row ml-5">
              <div className='col-md-12 '>
                <h2 style={{ color: 'black' }}>Вы успешно зарегистрированы!</h2>
              </div>
              <div className="col-md-4 col-12 text-center">

              </div>
              <div className="col-md-12">



                <div className="row ">
                  <div className="col-md-2"></div>
                  <div className="col-md-8">
                    <div className="row">
{/* 
                      <div className="col-md-3 mt-2">
                        <img src="https://img.freepik.com/free-icon/google_318-278809.jpg" style={{ width: '40px', height: '40px' }}></img>
                      </div>
                      <div className="col-md-3 mt-2">
                        <img src="https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-facebook-social-media-icon-png-image_6315968.png" style={{ width: '40px', height: '40px' }}></img>
                      </div>
                      <div className="col-md-3 mt-2">
                        <img src="https://cdn-icons-png.flaticon.com/512/3938/3938028.png" style={{ width: '40px', height: '40px' }}></img>
                      </div>
                      <div className="col-md-3 mt-2">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/0/09/Ok_new_logo.png?20150213095340" style={{ width: '40px', height: '40px' }}></img>
                      </div> */}

                    </div>
                    {/* <Input onChange={(e) =>{setPassword(e.target.value)}} /> */}
                  </div>

                  <div className="col-md-12 text-center mt-3">
                    <label>
                      Создать пароль:
                    </label>
                  </div>
                  <div className="col-md-2">
                    {/* <Select 
                  onChange={''}
                  defaultValue="+996"
                  placeholder='220404797'
                  style={{
                    width: 80,
                    marginLeft:'17px'
                  }}
                  options={[
                    {
                        value: +996,
                        label: '+996',
                      }
                  ]}
                /> */}
                  </div>

                  <div className="col-md-1"></div>

                  <div className="col-md-7">
                    {/* <Input placeholder="Напишите E-mail или Номер телефона" onChange={(e) =>{setNumbers(e.target.value)}} /> */}
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-3"></div>

                  <div className="col-md-7 mt-2">
                  <Space  direction="vertical">
                    <Input.Password onChange={(e) => { setPassword(e.target.value) }} placeholder="Придумайте пароль" />
                  </Space>
                    {/* <Input type="password"  placeholder="Придумайте пароль"  /> */}
                    {/* <Input placeholder="Придумайте пароль" onChange={(e) =>{setPassword(e.target.value)}} /> */}
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-3"></div>
                  <div className="col-md-7 mt-2">
                  <Space direction="vertical">
                    <Input.Password onChange={(e) => { setPassword2(e.target.value) }} placeholder="Потвердите пароль" />
                  </Space>
                    {/* <Input type="password" placeholder="Потвердите пароль" onChange={(e) => { setPassword2(e.target.value) }} /> */}
                  </div>

                  <div className="col-md-4"></div>

                  <div className="col-md-4 mt-3">

                  </div>
                  <div className="col-md-12 text-center">
                    <label>
                      {/* Пароль:  */}
                    </label>
                  </div>

                  <div className="col-md-1"></div>



                  <div className="col-md-2"></div>

                </div>

              </div>


              <div className="col-md-12 mt-3">
                <Form.Item >
                  <Button type="primary" style={{ float: 'right' }} onClick={reg} >Сохранить</Button>
                </Form.Item>
              </div>
              <div className="col-md-6">

              </div>
            </div>
          </div>

          <div style={{ marginTop: '250px' }}>
            <Footer />
          </div>

        </div>
      </div>
            <div className='d-sm-block d-lg-none d-xl-none'>
                <Footer_menu />
            </div>
    </>
  )
}
export default Registr2;