

const Post3 =()=>{
    
    return(
<>


        <div class="card" >
            <div class="card-body">
                <div class="row">
                    <div className='col-md-6 card'>
                        <a href="/post">
                            <img src="https://klike.net/uploads/posts/2020-03/1583054463_5.jpg" class="cardimg3" alt="..."/>
                        </a>
                    </div>
                    
                    <div class="col-md-6">
                        <label class="card-text " style={{fontSize:'16px',fontFamily:'revert-layer'}}>Сдаю комнату</label><i style={{float:'right',fontSize:'10px'}} class="fa-solid fa-eye"> : 150</i>
                        <label style={{marginTop:'-10px'}} class="card-title txt7">10000 руб</label>
                        <p style={{fontSize:'14px',fontFamily:'revert-layer',color:'gray',marginTop:'-10px',overflow:'hidden',height:'70px'}}>семейный парага койка места берилет метрого 5минут</p>
                        <p style={{fontSize:'14px',fontFamily:'revert',marginTop:'-10px'}}><i class="fa-sharp fa-solid fa-map-location-dot"></i> | Москва/Сходненская</p>
                        <label class="text-secondary" style={{marginTop:'-15px'}}>
                        <img src='images/user.png' className='carduserimg'></img>
                        <label style={{fontSize:'12px'}} class="text-secondary">10.02.2022 </label> <i class="fa-solid fa-envelope ml-1 sms2"></i>  <i class=" fa-solid fa-heart ml-2  heart"></i></label>
                    </div>

                </div>
            </div>
        </div>  



</>
    );
    }
    export default Post3;