import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { Button, Modal, Radio, Spin, Tabs , Space } from 'antd';
import { message, Upload } from 'antd';
import { AudioOutlined, MediumCircleFilled } from '@ant-design/icons';
import { Image } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLikes } from '../redux/actions/like_actions';
import moment from 'moment-timezone';
import { API_URL, uploads } from '../config/config';

const Post_moder = ({ product }) => {

    
    const [messageApi, contextHolder] = message.useMessage();

    // const user_id = localStorage.getItem('token');
    // const { likes } = useSelector((state) => state.likes);
    // const [favorited, setFavorited] = useState(null);
    const [product3, setProduct3] = useState(null);
    const [product4, setProduct4] = useState(null);
    const [message1, setMessage1] = useState(null);
    
    const [logged, setLogged] = useState(false);
    const [ad_id, setAd_id] = useState(null);
    const local = localStorage.getItem('token');
    const [usertoken, setUsertoken] = useState(null);
    const useridtoken = async () => {
        const post = {
            id: localStorage.getItem('token'),
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users5'
        });
        
        if (data.data.status == 200) {
            
            setUsertoken(data.data.users5[0].id);
            Logged(data.data.users5[0].id);
        } else {
            
        }
    }
    const Logged = (local) => {
        if (local != null) {
            setLogged(true);
        }
    }
    const [products_metro, setProducts_metro] = useState();
const fetchProducts_metro2 = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/getmetro'
});
if (data.data.status == 200){
  setProducts_metro(data.data.getmetro);
  
} else {
  
}
}

    const fetchProduct3 = async () => {
        const data = await axios({
            method: 'get',
            url: API_URL +'/users_get'
        });
        if (data.data.status == 200) {
            setProduct3(data.data.users_get);
            
        } else {
            
        }
    }


    


    
    
const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Сообщение отправлено!',
    });
  };
  const success2 = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
 
  const error = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
  };

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'This is a warning message',
    });
}
// const [products_category, setProducts_category] = useState(null);
// const fetchProducts_category = async () => {
// const data = await axios({
//   method: 'get',
//   url: API_URL + '/cat_get'
// });
// if (data.data.status == 200){
//   setProducts_category(data.data.cat_get);
//   
// } else {
//   
// }
// }



    useEffect(() => {
        useridtoken();
        fetchProduct3();
        fetchProducts_metro2();
    }, []);

    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (id) => {
        setAd_id(id);
      setIsModalOpen(true);
      
    };
    
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );
   
   
      const [searchItem, setSearchItem] = useState('')
     

      const handleInputChange = (e) => { 
        const searchTerm = e.target.value;
        setSearchItem(searchTerm)
      }
      const iz_status3 = async (ad_id)=>{
        const params = {
            status:'3',
            ad_id:ad_id
        };
        const data = await axios({
            method:'post',
            params:params,
            url:API_URL+'/iz_status3'
        });
        
        if(data != null){
            if(data.status  == 200){
            
            }else{
                alert('Error')
            }
        }
    }
    const iz_status1 = async (ad_id)=>{
        const params = {
            status:'1',
            ad_id:ad_id
        };
        const data = await axios({
            method:'post',
            params:params,
            url: API_URL+'/iz_status2'
        });
        
        if(data != null){
            if(data.status  == 200){
            
            }else{
                alert('Error')
            }
        }
    }
     
    return (
        <>
 {contextHolder}
      <Modal  title="Отправить сообщение" open={isModalOpen} onOk={handleOk} cancelText="Отмена" onCancel={handleCancel}>
      <textarea onChange={(e) => { setMessage1(e.target.value) }} style={{height:'100px'}} className="form-control"></textarea>
      </Modal>
            {product != null || product != undefined || product ?.length > 0 ?
                <>
                                {/* {products_category != null ? <>
                                    {products_category.filter(category => category.id == product.categories).map(filteredCategory => (
                                <a >
                                    <label className=' medname'  style={{fontSize:'16px',fontFamily:'revert-layer',marginLeft:'10px'}}>{filteredCategory.name}</label>
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>} */}
                    <div style={{width:'100%',marginLeft:'0px',marginTop:'10px'}} class="card " >
                        <a href={'/post/'+product.id} className='postimage2'>
                            <div style={{pointerEvents:'none'}}>
                                <Image  className='postimage2' style={{width:'100%',height:'200px',pointerEvents:'none'}}  src={product.images != null ? (uploads + product.images) : (uploads + 'no_image.jpg')} />
                            </div>
                             </a>
                        <a href={'/post/'+product.id} className='postimage' >
                        <div style={{pointerEvents:'none'}}>
                            <Image className='postimage' style={{width: '100%',height:'160px',borderTopLeftRadius:'5px',borderTopRightRadius:'5px',pointerEvents:'none'}}  src={product.images != null ? (uploads + product.images) : (uploads + 'no_image.jpg')} /> 
                        </div>
                        </a>
                        {products_metro != null ? <>
                                    {products_metro.filter(metro => metro.id_sql == product.metro).map(filteredMetro => (
                                <a >
                                    {filteredMetro.color != 1 ? <><p style={{color:'gray',fontSize:'12px'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'12px'}} /></p></> :
                                     <>
                                    <p style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='30px' height='30px' style={{borderRadius:'50%'}}></img></p>
                                    </>}
                                </a>
                                
                                ))}
                                
                                </> : <>
                                
                                </>}
                                <div style={{padding:'0',marginTop:'-10px'}} className='col-md-12'><span class="card-title txt7">{product.price != 0 ? product.price : 'Договорная'}</span> <label class="card-title ml-2 txt7cur "><b>{product.currency}</b></label></div>
                                <div className='col-md-12'><p style={{fontSize:'12px'}} className="destext" >{product.descriptions}</p></div> 
                                <div class="col-12 col-md-12">
                                    <label style={{fontSize:'10px',float:'right',marginRight:'-15px'}} class="text-secondary">{moment.tz(product.date1, "Europe/Moscow").fromNow()}</label>
                                </div>
                                <div className='row '>
                                    <div className='col-6 '>
                                        <Button style={{width:'100%'}} danger onClick={()=>iz_status1(product.id)}><i class="fa-solid fa-ban"></i></Button>
                                    </div>
                                    <div className='col-6'>
                                    <Button style={{width:'100%'}}  ghost type='primary' href={"/update_ad/"+ product.id}><i class="fa-solid fa-pencil"></i></Button>
                                    </div>
                                </div>
                        <div style={{marginTop:'-17px'}} class="card-body medbody">
                            <div  class="row d-block d-md-none ">
                           
                                
                             
                               
                        
                               
                            </div>
                          
                                    
                               
                                </div>
                                    
                               
                            
                           
                        
                    </div>

                </>
                : <>Loading</>
            }





        </>
        
    );
}
export default Post_moder;