
const initialState = {
    products2: [],
}

export const products2Reducer = (state = initialState,{type, payload}) => {
    switch(type) {
        case 'SET_PRODUCTS2':
            return {...state,products2:payload};
            default:
                return state;
    }
}